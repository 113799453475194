import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@/Button';
import { Input } from '@/Input';
import { SideLabeledField } from '@/SideLabeledField';
import { CardTitle, PageDescription, PageTitle } from '@/Text';

import { usePageProps } from '../../../../Shared/hooks/usePageProps';
import { SettingsHeader } from '../SettingsHeader';
import SettingsLayout from '../SettingsLayout';

const AboutSettingsPage = () => {
  const { user } = usePageProps();

  const { register } = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
    },
  });

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <PageTitle>
            About
          </PageTitle>
          <PageDescription>
            Manage your settings
          </PageDescription>
        </SettingsHeader>

        <hr />

        <div className="space-y-8">
          <CardTitle>
            General
          </CardTitle>

          <div className="space-y-4">
            <div>
              <SideLabeledField
                label="Name"
                renderValue={(
                  <Input status="disabled" type="text" {...register('name')} disabled/>
                )}
              />
            </div><div>
              <SideLabeledField
                label="Email"
                renderValue={(
                  <Input status="disabled" type="text" {...register('email')} disabled/>
                )}
              />
            </div>
          </div>

          <div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Button disabled={true}>
              Update
            </Button>
          </div>
        </div>

        <hr />
      </div>
    </div>
  );
};
AboutSettingsPage.layout = (page: ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default AboutSettingsPage;

import { DividerHorizontalIcon } from '@radix-ui/react-icons';

import { Icon, LabelIcon, MeasurementFieldIcon } from '@/Icon';
import { MutedText } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { SpecIcon } from '~/Components/Spec/SpecIcon';
import { Field, FieldConstraint, Spec } from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';

interface RangeLabelProps {
  constraint: FieldConstraint;
  field: Field;
  spec?: Spec | null;
}

export const RangeLabel = ({ constraint, field, spec }: RangeLabelProps) => {
  const ranges = constraint.ranges ?? [];
  const unit = field.unit;

  const percentageType = field.type === 'percentage';

  let min = ranges[0]?.min ?? 0;
  let max = ranges[0]?.max ?? '∞';

  // Check if min and max are numbers; if so, parse them and multiply by 100
  if (percentageType) {
    try {
      if (typeof min === 'string') {
        min = parseFloat(min);
      }
      min = min * 100;
    } catch (e) {
    }

    try {
      if (typeof max === 'string') {
        max = parseFloat(max);
      }
      max = max * 100;
    } catch (e) {
    }
  }

  const hasUnit = unit !== null;
  const hasMax = ranges[0]?.max !== null;

  const specName = spec?.name;
  const { specColorMap } = useOrder();

  return (
    <div className="flex items-center">
      {spec && (
        <div className="mr-1">
          <Tooltipped label={specName}>
            <SpecIcon theme={specColorMap[spec.id]}/>
          </Tooltipped>
        </div>
      )}

      <LabelIcon icon={MeasurementFieldIcon}/>

      <MutedText className="flex items-center text-xs">
        {min}
        {hasUnit && (
          <>
            {' '}
            {unit}
          </>
        )}
        <Icon className="justify-center px-1">
          <DividerHorizontalIcon/>
        </Icon>
        {max}
        {''}
        {hasMax && (
          <>
            {' '}
            {unit}
          </>
        )}
      </MutedText>
    </div>
  );
};


import { FormatSummarizable } from '../../../../Shared/Components/Orders/FormatSummarizable';
import { Pallet } from '../../../../Shared/types/types';
import { Label } from '../../../../Shared/UI/Label';
import { Modal, ModalBody, ModalContent, ModalDescription, ModalHeader, ModalTitle } from '../../../../Shared/UI/Modal';
import { Helper, TextLabel } from '../../../../Shared/UI/Text';

interface InspectUnitModalProps {
  unit: Pallet;
  isOpen: boolean;
  onClose: () => void;
}
const InspectUnitModal = ({ unit, isOpen, onClose }: InspectUnitModalProps) => {
  const unitFieldSummaries = unit.unit_field_summaries ?? [];

  return (
    <Modal open={isOpen} onOpenChange={() => onClose()}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            Pallet
          </ModalTitle>
          <ModalDescription>
            Ref: {unit.public_ref}
          </ModalDescription>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col space-y-4">
            <div>
              <Helper>
                Information about pallet
              </Helper>
            </div>
            <div className="px-4 border divide-y rounded border-slate-200 divide-slate-200">
              {unitFieldSummaries.map((summary) => (
                <div className="flex items-center justify-between py-2 space-x-2" key={summary.id}>
                  <div>
                    <TextLabel>
                      {summary.field?.name}
                    </TextLabel>
                  </div>

                  <Label theme="red">
                    <FormatSummarizable fieldSummary={summary} />
                  </Label>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default InspectUnitModal;

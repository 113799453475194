import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import {
  CalendarDaysIcon,
} from '@heroicons/react/20/solid';
import { Link } from '@inertiajs/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { groupBy, keyBy } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Button } from '@/Button';
import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { PlaceholderBox } from '@/Fallback';
import { NumericIndicator } from '@/NumericIndicator';
import { Separator } from '@/Separator';
import { Tabby, Tabs } from '@/Tabbar';
import { CardSubtitle, CardTitle, Helper, MutedText, Strong, Text, TextLabel } from '@/Text';
import { AnomalyMarkers } from '~/Components/Analytics/Charts/AnomalyMarkers';
import { colorMap } from '~/Components/Analytics/colorMap';
import { OrderImage, OrderTitle } from '~/Components/Orders/OrderPreviewCard';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { TooltipProps } from '~/types/global-types';
import { Field, Order, OrderIssueSummary, Organization } from '~/types/types';

import { QualityIssuesPageProps, QualityIssuesParameters } from '../helpers';

type Payload = Order;
type TooltipBodyProps = TooltipProps<Payload>;

/**
 * QualityTimeSeries - Renders the time series card for quality issues.
 *
 * @preconditions - The component must be rendered inside QualityIssuesPage.
 */
export const QualityTimeSeries = ({
  suppliers,
  timeSeries,
  deepDive,
  categories,
}: QualityTimeSeriesProps) => {
  const { parse, format } = useDate();

  const { t } = useTranslation();
  const { report, parameters } = usePageProps<QualityIssuesPageProps>();

  const [activeSupplierIndex, setActiveSupplierIndex] = useState(() => {
    return suppliers.findIndex((supplier) => parameters.activeSupplierId ? supplier.id === parameters.activeSupplierId : 0) ?? 0;
  });

  const { reload, loading } = useAction<QualityIssuesParameters>('', {
    withParams: true,
  });

  const deepDiveFromDate = parse(parameters.timeSeriesDeepDive?.fromDate, DateFormat.ISO, true);
  const deepDiveToDate = parse(parameters.timeSeriesDeepDive?.toDate, DateFormat.ISO, true);

  /**
   * When we click on a supplier in the tab, we reload with the new "active supplier id".
   */
  const handleSupplierClick = (index: number) => {
    setActiveSupplierIndex(index);

    reload({
      activeSupplierId: suppliers[index].id,
    });
  };

  /**
   * When we click on a point in the chart, we should deep-dive.
   * @param startDate
   * @param endDate
   */
  const handleChartClick = (startDate?: string, endDate?: string) => {
    reload({
      timeSeriesDeepDive: {
        fromDate: startDate,
        toDate: endDate,
      },
    });
  };

  const deepDiveSummary = deepDive;

  const calculatePeriodDeepDiveDates = () => {
    if (deepDiveFromDate && deepDiveToDate) {
      return `${format(deepDiveFromDate, DateFormat.HumanMonthDay)} - ${format(deepDiveToDate, DateFormat.HumanMonthDay)}`;
    }

    return '';
  };

  const deepDiveState = {
    'hasNoDataFetched': loading || !(deepDiveSummary?.by_order?.length),
    'hasDataFetched': !loading && deepDiveSummary?.by_order?.length,
  };

  return (
    <Card>
      <CardHeaderContainer>
        <div className="flex justify-between py-2">
          {/* Left */}
          <div>
            <Strong>
              {t('quality_issues')}
            </Strong>
          </div>

          {/*  Right */}
          <div>
            {report.date_range_type !== 'CUSTOM' && (
              <RangeDatePicker defaultPeriod={report.date_range_type} disabled/>
            )}
          </div>
        </div>

        <hr/>

        <div>
          <Tabs inCardHeader>
            {suppliers.map((supplier) => (
              <Tabby
                key={supplier.id}
                tab={{
                  name: supplier.title,
                  href: '#',
                  current: suppliers?.[activeSupplierIndex]?.id === supplier.id,
                }}
                onClick={() => handleSupplierClick(suppliers.findIndex((s) => s.id === supplier.id))}
              />
            ))}
          </Tabs>
        </div>
      </CardHeaderContainer>

      <div>
        {/* Place time-series here */}
        <div>
          {timeSeries && timeSeries.length > 0 ? (
            <QualityAreaChart onClick={handleChartClick} orderIssueSummaries={timeSeries} categories={categories}/>
          ) : (
            <PlaceholderBox
              title={t('placeholders.no_data')}
              description={t('placeholders.quality_timeseries_data_placeholder')}
            />
          )}
        </div>

        <Separator/>

        {!!deepDiveState.hasDataFetched && (
          <div>
            <CardBody>
              <div className="space-y-4">
                <div className="space-y-2">
                  <div>
                    <CardTitle>
                      {t('reports.period_of', { period: calculatePeriodDeepDiveDates() })}
                    </CardTitle>
                    <CardSubtitle>
                      {t('reports.quality_issues_period_analysis_description')}
                    </CardSubtitle>
                  </div>

                  <div>
                    <Button onClick={() => handleChartClick(undefined, undefined)} variant="outline" size="xs">
                      <Cross2Icon className="w-3 h-3 mr-1"/>
                      {t('close_analysis')}
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  {deepDiveSummary.by_order?.map((order: any) => (
                    <PerOrderBreakdown key={order.order_id} order={order}/>
                  ))}
                </div>
              </div>
            </CardBody>
          </div>
        )}

        {deepDiveState.hasNoDataFetched && (
          <PlaceholderBox
            title={t('select_date')}
            description={t('select_date_description')}
            icon={<CalendarDaysIcon className="w-12 h-12 fill-gray-400"/>}
            isFlat
            loading={loading}
          />
        )}
      </div>
    </Card>
  );
};

const TooltipBody = ({ payload, label }: TooltipBodyProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeaderContainer>
        <TextLabel>{label}</TextLabel>
      </CardHeaderContainer>
      <CardBody>
        <div>
          <TextLabel>{t('issues')}</TextLabel>
        </div>

        <hr/>
        <div className="divide-y">
          {payload?.map((entry, index) => (
            <div
              key={`item-${index}`}
              className="flex items-center justify-between py-1 space-x-4"
              aria-label={`Issue for ${entry.dataKey.replace('_issues_count', '')} has ${entry.value} issues in ${label}`}
            >
              <div className="flex items-center">
                <div
                  className="w-3 h-3 mr-2 rounded-full"
                  style={{ backgroundColor: entry.fill }}
                />
                <Text>{t(`quality_report_labels.${entry.dataKey}`)}</Text>
              </div>
              <div className="flex items-center">
                <MutedText>{entry.value}</MutedText>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const QualityAreaChart = ({ orderIssueSummaries, categories, onClick }: QualityAreaChartProps) => {
  const categoryColorMap = keyBy(categories, 'category');

  // Only include keys which are in `categoryColorMap`

  const keys = Object.keys(orderIssueSummaries[0]).filter((key) => categoryColorMap[key]).map((key, index) => ({
    dataKey: key,
    stackId: '1',
    stroke: colorMap[index],
    fill: categoryColorMap[key].color,
  }));

  const data = orderIssueSummaries.map((summary) => {
    return {
      ...summary,
    };
  });

  return (
    <div className="h-[500px] w-full cursor-pointer">
      <ResponsiveContainer width="100%" height="100%" className="text-gray-400 cursor-pointer">
        <AreaChart
          data={data}
          syncId="anyId"
          onClick={(data) => {
            const payload = data.activePayload?.[0].payload;
            // Find object with key containing `data` in payload`

            const dataItemPayloadKey = payload && Object.keys(payload).find((key) => key.includes('data'));

            if (!dataItemPayloadKey) {
              return;
            }

            const itemPayload = payload[dataItemPayloadKey];
            const startDate = itemPayload?.start_date;
            const endDate = itemPayload?.end_date;

            if (startDate && endDate && onClick) {
              onClick(startDate, endDate);
            }
          }}
          margin={{
            top: 40,
            right: 30,
            left: 70,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>

          <XAxis
            stroke="#D1D5DB"
            dataKey="date"
            tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
            tickMargin={10}
          />
          <CartesianGrid
            className="cursor-pointer"
            strokeDasharray="4 4" stroke="#D1D5DB"
          />

          <YAxis
            stroke="#D1D5DB"
            domain={[0, 70]}
            tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
            tickFormatter={(value) => `${value} issues`}
            tickMargin={24}
          />

          <Tooltip content={(props) => <TooltipBody {...(props as any)} />}/>
          {keys.map((key) => (
            <Area
              key={key.dataKey}
              dot={(props: any) => <circle {...props} r={5}
                aria-label={`Dot with x=${props.payload.issue_week} and y=${props.value}`}/>}
              {...key}
            />
          ))}

          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
            </linearGradient>
          </defs>

          <AnomalyMarkers anomalies={[]}/>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

interface QualityAreaChartProps {
  orderIssueSummaries: OrderIssueSummary[];
  onClick?: (startDate: string, endDate: string) => void;
  categories: {
    category: string;
    color: string;
  }[];
}

interface QualityTimeSeriesProps {
  suppliers: Organization[];
  deepDive: any;
  timeSeries?: OrderIssueSummary[] | null;
  categories: {
    category: string;
    color: string;
  }[];
}

interface PerOrderBreakdownProps {
  order: {
    order: Order;
    order_id: string;
    order_ext_ref: string;
    fields: {
      field: Field;
      value: number;
      count: number;
      name: string;
    }[];
  };
}

const PerOrderBreakdown = ({ order }: PerOrderBreakdownProps) => {
  const { t } = useTranslation();
  const { organization } = usePageProps();
  const { refs } = useFloating({
    placement: 'right-start',
    middleware: [offset(40)],
    whileElementsMounted: autoUpdate,
  });

  const fields = order?.fields ?? [];
  const fieldGroups = groupBy(fields, 'field.meta_type_data.category');

  const fieldByGroup = Object.keys(fieldGroups).map((key) => {
    return {
      category: key,
      fields: fieldGroups[key],
    };
  });

  return (
    <div ref={refs.setReference}>
      <Card>
        <CardBody>
          <div className="grid grid-cols-[1fr_3fr]">
            <div className="bg-gray-50 border-r rounded-l -my-4 -ml-8 p-4 flex flex-col gap-4">
              {/* Top */}
              <div className="space-y-1">
                <div>
                  <div className="flex">
                    <div className="flex-grow-0 h-">
                      <OrderImage order={order.order}/>
                    </div>
                    <div>
                      <OrderTitle order={order.order}/>
                      <div className="mt-1">
                        <Button size="xs" variant="white">
                          <Link href={`/b/${organization.id}/orders/${order.order_id}`}>
                            {t('view_order')}
                          </Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="px-4 py-2 -mt-4 rounded-tr -mr-8 hidden sm:grid gap-3 sm:grid-cols-[1fr_100px] md:grid-cols-[1fr_100px] bg-gray-50">
                <div>
                  <Helper>{t('breakdown_issues_per_category')}</Helper>
                </div>
              </div>

              <div className="p-4">
                <div className="space-y-4">
                  {fieldByGroup.map((group) => (
                    <div key={group.category}>
                      <Strong>
                        {t(`quality_input_fields.${group.category}`)}
                      </Strong>
                      <div>
                        <MutedText>
                          {group.fields.map((field) => (
                            <div className="flex space-x-2" key={field.field.id}>
                              <div>
                                <Text>{field.field.name}</Text>
                              </div>
                              <MutedText>
                                <NumericIndicator size="xs" brand="red">
                                  {field.count}
                                </NumericIndicator>
                              </MutedText>
                            </div>
                          ))}
                        </MutedText>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

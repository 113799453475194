// noinspection JSUnusedGlobalSymbols

import { CaretRightIcon } from '@radix-ui/react-icons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/Button';
import { CardBody, NavCard } from '@/Card';
import { Container } from '@/Container';
import { CardTitle, PageDescription, PageTitle } from '@/Text';

import { AdminLayout } from '../AdminLayout';
import { CreateOrganizationModal } from './CreateOrganizationModal';
import { AdminPageProps } from './types';

export const AdminPage = ({ buyerOrganizations, sellerOrganizations }: AdminPageProps) => {
  const { t } = useTranslation();

  return (
    <div>

      <div className="z-10 py-6 bg-white shadow-sm">
        <Container>
          <div className="flex justify-between">
            <div>
              <PageTitle>
                Admin Dashboard
              </PageTitle>
              <PageDescription>
                Manage your organizations, users, and more.
              </PageDescription>
            </div>

            <div>
              <CreateOrganizationModal>
                <Button>
                  {t('create_workspace')}
                </Button>
              </CreateOrganizationModal>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className="py-6">
          <CardTitle>
            Your Buyer Organizations
          </CardTitle>
          <div className="grid md:grid-cols-3 gap-4 mt-4">
            {buyerOrganizations.map((organization) => (
              <div key={organization.id} id={`organization::${organization.id}`}
                aria-label={`Go to ${organization.title}`}>
                <NavCard href={`/b/${organization.id}`}>
                  <CardBody>
                    <div className="flex items-center justify-between">
                      {organization.title}
                      <div>
                        <IconButton label="More">
                          <CaretRightIcon className="w-5 h-5 text-gray-500"/>
                        </IconButton>
                      </div>
                    </div>
                  </CardBody>
                </NavCard>
              </div>
            ))}
          </div>
        </div>

        <div className="py-6">
          <CardTitle>
            Your Seller Organizations
          </CardTitle>
          <div className="grid md:grid-cols-3 gap-4 mt-4">
            {sellerOrganizations.map((organization) => (
              <NavCard href={`/s/${organization.id}`} key={organization.id}>
                <CardBody>
                  <div className="flex items-center justify-between">
                    {organization.title}
                    <div>
                      <IconButton label="More">
                        <CaretRightIcon className="w-5 h-5 text-gray-500"/>
                      </IconButton>
                    </div>
                  </div>
                </CardBody>
              </NavCard>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

AdminPage.layout = (page: ReactNode) => <AdminLayout>{page}</AdminLayout>;

export default AdminPage;

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { router } from '@inertiajs/react';
import React, { ReactNode } from 'react';

import { AdminOnly } from '@/AdminOnly';
import { Container } from '@/Container';
import { WithGenericFallback } from '@/Fallback';
import { GenericDetailsPane } from '~/Components/Panes/Details/GenericDetailsPane';
import { ViewSpecModal } from '~/Components/Spec/ViewSpecModal';
import { useAction } from '~/hooks/useAction';
import { PageProps, Spec, VerifySpecFileOutput } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';
import { HarvestSection } from './Harvest/HarvestSection';
import InspectUnitModal from './Inspections/InspectUnitModal';
import { OrderInspectionsSection } from './Inspections/OrderInspectionsSection';
import { OrderLogisticsSection } from './Logistics/OrderLogisticsSection';
import { OrderDetailsSection } from './OrderDetails/OrderDetailsSection';
import { OrderEventsLog } from './OrderEventsLog';
import { OrderHeader } from './OrderHeader';
import { OrderProvider } from './OrderProvider';
import { OrderPayload } from './types';

export interface OrderPageProps extends PageProps {
  data: OrderPayload;
  isAdmin: boolean;
  active: {
    spec: Spec;
  };
  all_issues: any;
  parameters: {
    activeUnitId?: string;
    activeFieldId?: string;
    activeStepId?: string;
  };
  flash: {
    spec: {
      verifyTempSpecResults: VerifySpecFileOutput;
    };
  };
}

const OrderPage = ({ data, active, all_issues }: OrderPageProps) => {
  const order = data.order;
  const activeUnit = data.active_unit;
  const comments = data.groupedComments;

  const { loading, resetCurrentPage: reloadIssues } = useAction('', {
    preserveScroll: true,
    preserveState: true,
    only: ['all_issues'],
  });

  const handleCloseActiveUnit = () => {
    router.reload({ data: { activeUnitId: null } });
  };

  const handleCloseActiveSpec = () => {
    router.reload({ data: { activeSpecId: null }, only: ['active'] });
  };

  const events = order.events ?? [];

  const allIssues = all_issues ?? [];

  const info = {
    issues: { allIssues: allIssues },
    issuesCount: allIssues.length,
  };

  return (
    <OrderProvider orderData={data}>
      {active.spec && (
        <ViewSpecModal
          spec={active.spec}
          isOpen={!!active.spec}
          onClose={handleCloseActiveSpec}
        />
      )}
      {activeUnit && (
        <InspectUnitModal
          unit={activeUnit}
          isOpen={!!activeUnit}
          onClose={handleCloseActiveUnit}
        />
      )}
      <div>
        <OrderHeader
          integration={data.integration}
          dataStreams={data.dataStreams}
          order={order}
          groupedComments={comments}
        />

        <div className="py-4">
          <Container>
            <div>
              <AdminOnly>
                <WithGenericFallback>
                  <GenericDetailsPane
                    object={info}
                    details={[
                      { title: 'Number issues', dtype: 'number', key: 'issuesCount' },
                      { title: 'issues', dtype: 'json', key: 'issues' },
                    ]}
                    container={document.body}
                    loading={loading}
                    fetchData={() => reloadIssues()}
                  >
                    <div className="fixed left-4 bottom-24">
                      <button
                        type="button"
                        className="flex items-center justify-center w-12 h-12 text-white bg-red-500 rounded-full focus:outline-none"
                      >
                        <ExclamationCircleIcon className="h-7 w-7"/>
                      </button>
                    </div>
                  </GenericDetailsPane>
                </WithGenericFallback>
              </AdminOnly>
              <div className="divide-y divide-gray-200">
                <div className="pb-8 mt-4">
                  <OrderDetailsSection
                    activeStep={data.step}
                    integration={data.integration}
                    order={order}
                  />
                </div>
                <div className="py-8">
                  <OrderInspectionsSection
                    attachments={data.attachments}
                    orderFieldSummaries={data.orderFieldSummaries}
                    activeSpec={data.active_spec}
                    activeOrderFieldSummary={data.activeOrderFieldSummary}
                  />
                </div>
                <OrderLogisticsSection dataStreams={data.dataStreams}/>
                <HarvestSection dataStreams={data.dataStreams}/>

                <OrderEventsLog events={events} order={order} buyer={order.buyer as any ?? undefined}
                  seller={order.seller as any ?? undefined}/>

              </div>
            </div>
          </Container>
        </div>
      </div>
    </OrderProvider>
  );
};

OrderPage.layout = (page: ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default OrderPage;

import { useFormat } from '~/hooks/useFormat';
import { QualityEntry } from '~/types/types';

interface QualityEntryUnitProps {
  qualityEntry: QualityEntry;
}

/**
 * The QualityEntryUnit renders a single textual output of the quality entry.
 *
 * - If measurement, we render the number and the unit of the field.
 * - If percentage, we render either the individual counts or the percentage.
 * - If boolean, we render either yes or no.
 *
 * @precondition This is used as a pure textual component.
 * @param qualityEntry
 * @constructor
 */
export const QualityEntryUnit = ({ qualityEntry }: QualityEntryUnitProps) => {
  const { formatFractionPercentage } = useFormat();
  // Render just the count
  if (qualityEntry.data_type === 'percentage' && qualityEntry.individual_counts !== null) {
    return <>{qualityEntry.individual_counts} units</>;
  }

  // Render the percentage if count not available
  if (qualityEntry.data_type === 'percentage' && !qualityEntry.individual_counts && qualityEntry.number !== null) {
    return <>{formatFractionPercentage(qualityEntry.number)}</>;
  }

  // Render the boolean value
  if (qualityEntry.data_type === 'boolean') {
    return <>{qualityEntry.boolean ? 'Yes' : 'No'}</>;
  }

  // Render the number and unit
  return <>{qualityEntry.number}</>;
};

import { Inertia } from '@inertiajs/inertia';
import { Link, usePage } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../Shared/UI/Button';
import { FormTemplate, Organization } from '../../../Shared/types/types';
import SettingsLayout from './SettingsLayout';

const SettingsOrderTemplatesPage = () => {
  const { t } = useTranslation();
  const { props: { organization, formTemplates } } = usePage<{ title: string, organization: Organization, formTemplates: FormTemplate[] }>();

  const handleDelete = (formTemplateId: number) => {
    Inertia.delete(`/b/${organization.id}/form_template/${formTemplateId}/delete`, { only: ['formTemplates'] });
  };

  return (
    <div>
      <div className='flex justify-between'>
        <span className='text-3xl text-token-heading'>{t('form_templates')}</span>
        <Button as={Link} variant="primary" href={`/b/${organization.id}/form_template/add`}>{t('add_form_template')}</Button>
      </div>
      <div className='flex flex-col gap-2 mt-6 rounded'>
        {formTemplates.map((formTemplate) => (
          <div key={formTemplate.id} className='flex items-center justify-between gap-2 p-4 bg-white border border-gray-200 rounded shadow-md'>
            <div className='flex flex-col gap-3'>
              <span className='text-xl font-bold text-token-text'>{formTemplate.name}</span>
              <span className='text-token-text'>{formTemplate.description}</span>
            </div>
            <div className='flex gap-2'>
              <Button as={Link} variant="outline" href={`/b/${organization.id}/form_template/${formTemplate.id}/edit`}>Edit</Button>
              <Button variant="outline" onClick={() => handleDelete(formTemplate.id)}>{t('delete')}</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SettingsOrderTemplatesPage.layout = (page: React.ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default SettingsOrderTemplatesPage;

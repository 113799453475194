/* eslint-disable @typescript-eslint/ban-ts-comment */
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { Button } from '@/Button';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { MultiRegionPicker } from '@/Pickers/MultiRegionPicker';
import TabFilter from '@/Tabs/TabFilter';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { useToast } from '~/hooks/useToast';
import { useTracking } from '~/hooks/useTracking';
import { DatePeriod } from '~/types/global-types';
import {
  Form,
  FormControl,
  FormField,
  FormGrid,
  FormItem,
  FormSeparator,
  Input,
  InputDescription,
  InputLabel,
} from '~/UI/Form';
import { RadioButtonCard, RadioGroup } from '~/UI/RadioButtonCard';
import { cn } from '~/utils/cn';

import { OrganizationFilterType, ReportsPageProps, ReportType } from '../types';
import {
  CreateReportFormProps,
  dateRangeTypes,
  ModeKey,
  organizationTypes,
  reportTypes,
} from './CreateReportForm.shared';

const schema = z
  .object({
    report_id: z.string().optional(),
    name: z.string().min(1).max(255),
    type: z.string().refine((val) => reportTypes.includes(val as ReportType)),
    organization_filter_type: z
      .string()
      .refine((val) =>
        organizationTypes.includes(val as OrganizationFilterType)
      ),
    organization_ids: z.array(z.string()).optional(),
    date_range_type: z
      .string()
      .refine((val) => dateRangeTypes.includes(val as DatePeriod)),
    region_names: z.array(z.string()),

    date_from: z.string().nullable().optional(),
    date_to: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      if (data.organization_filter_type === 'ORGANIZATIONS') {
        return !!data?.organization_ids && data.organization_ids.length > 0;
      }

      return true;
    },
    {
      path: ['organization_ids'],
      message: 'Please select at least one supplier',
    }
  )
  .refine(
    (data) => {
      if (data.organization_filter_type === 'REGIONS') {
        return !!data?.region_names && data.region_names.length > 0;
      }

      return true;
    },
    {
      path: ['region_names'],
      message: 'Please select a region',
    }
  );

type FormValues = z.infer<typeof schema>;

const useTabItems = (): { key: ModeKey; value: string }[] => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        key: 'organizations' as const,
        value: 'Report on own organization', // TODO: Add t('report_tab_item_organizations_seller')
      },
      {
        key: 'regions' as const,
        value: 'Report on region(s)', // Add t('tab_item_regions_seller')
      },
    ],
    [t]
  );
};

export const SellerCreateReportForm = ({
  onSubmit,
  data,
  isEditing,
}: CreateReportFormProps) => {
  const { track } = useTracking();
  const { organization, filterOptions } = usePageProps<ReportsPageProps>();
  const { t } = useTranslation();
  const { toast } = useToast();

  const tabItems = useTabItems();

  const { post: create } = useAction(`/s/${organization.id}/reports/create`, {
    onSuccess: () => {
      toast({
        title: 'A new report has been created',
        description: 'The report is now available in the reports page.',
      }),
      onSubmit();
    },
  });

  const { post: edit } = useAction(`/s/${organization.id}/reports/edit`, {
    onSuccess: () => {
      toast({
        title: 'Report has been edited',
        description: 'The edited report is now available in the reports page.',
      }),
      onSubmit();
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      report_id: data?.id,
      name: data?.name || '',
      type: data?.type || 'FORECAST_ACCURACY',
      organization_filter_type:
        data?.organization_filter_type || 'ORGANIZATIONS',
      organization_ids: data?.organizations?.map((org) => org.id) || [],
      date_range_type: data?.date_range_type || 'WEEKLY',
      // TODO: make plural
      region_names: data?.region_countries || [],
      date_from: data?.from_date || null,
      date_to: data?.to_date || null,
    },
  });

  const organizationFilterType = form.watch('organization_filter_type');
  const dateRangeType = form.watch('date_range_type');

  const submit = (data: FormValues) => {
    if (isEditing) {
      edit({
        ...data,
        region_names: data?.region_names,
      });
      track({
        event: 'edit_report',
        type: data.type as ReportType,
        id: data.report_id || 'no id found',
      });
    } else {
      create({
        ...data,
        region_names: data?.region_names,
      });
      track({
        event: 'create_report',
        type: data.type as ReportType,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <input type="hidden" {...form.register('report_id')} />
        <FormGrid>
          <FormItem>
            <InputLabel htmlFor="name">{t('report_name')}</InputLabel>
            <InputDescription>{t('report_name_description')}</InputDescription>
            <Input
              className={cn(
                `${!!form.formState.errors.name && 'border-red-500'}`
              )}
              placeholder={t('report_name_placeholder')}
              {...form.register('name')}
              id="name"
            />
          </FormItem>

          <FormSeparator/>

          <FormField
            name="type"
            render={({ field }) => {
              return (
                <FormItem>
                  <InputLabel>{t('report_type')}</InputLabel>
                  <InputDescription>
                    {t('report_type_description')}
                  </InputDescription>
                  <FormControl>
                    {/* This radio group  toggles between Forecast accuracy / Quality / Quality Costs */}
                    <RadioGroup
                      disabled={isEditing}
                      onValueChange={field.onChange}
                      value={field.value}
                      className="space-y-2"
                    >
                      <RadioButtonCard
                        id="FORECAST_ACCURACY"
                        value="FORECAST_ACCURACY"
                        label={t('reports.type_FORECAST_ACCURACY') as string}
                        subLabel={
                          t(
                            'reports.type_FORECAST_ACCURACY_description'
                          ) as string
                        }
                      />
                      <RadioButtonCard
                        id="QUALITY"
                        value="QUALITY"
                        label={t('reports.type_QUALITY') as string}
                        subLabel={t('quality_report_description') as string}
                      />
                      <RadioButtonCard
                        id="QUALITY COSTS"
                        value="QUALITY_COSTS"
                        label={t('reports.type_QUALITY_COSTS') as string}
                        subLabel={
                          t('reports.type_QUALITY_COSTS_description') as string
                        }
                        disabled
                      />
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              );
            }}
          />

          <FormSeparator/>

          <div>
            <InputLabel>{t('report_on')}</InputLabel>
            <InputDescription>{t('report_on_description')}</InputDescription>
          </div>

          <div className="relative">
            <TabFilter
              initialIndex={
                tabItems.findIndex(
                  (mode) =>
                    mode.key === organizationFilterType.toLocaleLowerCase()
                ) || 0
              }
              onChange={(index) => {
                form.setValue(
                  'organization_filter_type',
                  tabItems[index].key.toUpperCase() as OrganizationFilterType
                );
                form.trigger('region_names');
                form.trigger('organization_ids');
              }}
              items={tabItems.map((mode) => mode.value)}
            />
          </div>

          {organizationFilterType === 'REGIONS' && (
            <FormField
              name="region_names"
              render={({ field }) => (
                <FormItem>
                  <InputLabel>Select region</InputLabel>
                  <InputDescription>
                    Select the region that you want to include in the report.
                  </InputDescription>
                  <FormControl>
                    <div className="w-fit">
                      <MultiRegionPicker
                        {...field}
                        options={filterOptions.regions}
                        selected={field.value}
                        onChange={field.onChange}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <hr/>

          <FormItem>
            <InputLabel>Select date range</InputLabel>
            <InputDescription>
              Select the date range for the report. This can be either a fixed
              date, or an interval.
            </InputDescription>
            <RangeDatePicker
              defaultPeriod={dateRangeType as DatePeriod}
              onChange={(dates) => {
                if (dates.period) {
                  form.setValue('date_range_type', dates.period);
                }
                if (dates.from && dates.to) {
                  // @ts-ignore
                  form.setValue('date_from', dates.from);
                  // @ts-ignore
                  form.setValue('date_to', dates.to);
                }
              }}
              enablePeriod
            />
          </FormItem>
          <Button
            type="submit"
            // @ts-ignore */
            disabled={!form.formState.isValid}
          >
            {isEditing ? 'Save changes' : 'Create report'}
          </Button>
        </FormGrid>
      </form>
    </Form>
  );
};

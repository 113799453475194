import { ReactNode } from 'react';

interface SwappableProps {
  isEdit: boolean;
  displayComponent: ReactNode;
  editComponent: ReactNode;
}

export const Swappable = ({ isEdit, displayComponent, editComponent }: SwappableProps) => {
  return isEdit ? editComponent : displayComponent;
};

import { Link } from '@inertiajs/react';

interface NavLinkProps {
  href: string;
  isActive: boolean;
  children: React.ReactNode;
  onCloseMobileNav?: () => void;
}

export const NavLink = ({ href, children, isActive, onCloseMobileNav }: NavLinkProps) => {

  return (
    <Link
      onClick={() => onCloseMobileNav && onCloseMobileNav()}
      href={href}
      className={`px-3 py-2 text-sm font-medium text-white rounded-md ${isActive ? 'bg-main-700' : 'hover:bg-main-500 hover:bg-opacity-75'}`}
      aria-current="page"
    >
      {children}
    </Link>
  );
};

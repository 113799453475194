import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import { SectionTitle } from '@/Text';
import { LocationDataPayload, OrderDataStream, OrderDataStreamType, TemperaturePayload } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { MapDataStream } from '../MapDataStream';
import { getDataStreamIssues } from '../utils';
import { TemperatureSummarySection } from './TemperatureSummaryCard';

interface OrderLogisticsSection {
  dataStreams: OrderDataStream<LocationDataPayload | TemperaturePayload>[];
}

export const OrderLogisticsSection = ({ dataStreams }: OrderLogisticsSection) => {
  const { t } = useTranslation();

  const relevantDataStreams = dataStreams.filter((dataStream) => {
    return dataStream.type === OrderDataStreamType.LOCATION || dataStream.type === OrderDataStreamType.TRANSPORT_TEMPERATURE;
  });

  // Sum up issues of each data stream (using dataStream.issues.length for each data stream)

  if (relevantDataStreams.length === 0) {
    return null;
  }

  const issues = getDataStreamIssues(relevantDataStreams);

  // Group data-streams-by-type
  const dataStreamsByType = relevantDataStreams.reduce((acc, dataStream) => {
    if (!acc[dataStream.type]) {
      acc[dataStream.type] = [];
    }

    acc[dataStream.type].push(dataStream);

    return acc;
  }, {} as Record<string, OrderDataStream<LocationDataPayload | TemperaturePayload>[]>);

  return (
    <div className="py-8" id="logistics">
      <div className="flex items-center justify-between mb-2">
        <SectionTitle>
          {t('logistics')}
        </SectionTitle>

        <div className="mt-2">
          <QualityLabel issues={issues} label="Issues"/>
        </div>
      </div>

      <WithAwaitingFallback>
        <div className="space-y-4">
          {Object.entries(dataStreamsByType).map(([type, dataStreams]) => (
            <div key={type}>
              {type === OrderDataStreamType.TRANSPORT_TEMPERATURE && (
                <TemperatureSummarySection dataStreams={dataStreams}/>
              )}

              {type === OrderDataStreamType.LOCATION && (
                <MapDataStream dataStream={dataStreams[0]}/>
              )}

              {type === OrderDataStreamType.WEATHER && (
                <MapDataStream dataStream={dataStreams[0]}/>
              )}
            </div>
          ))}
        </div>
      </WithAwaitingFallback>
    </div>
  );
};

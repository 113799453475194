import { FileIcon } from '@radix-ui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalIcon,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';

import { useAction } from '../../hooks/useAction';
import { useFormat } from '../../hooks/useFormat';
import { Order, Organization } from '../../types/types';
import { Button } from '../../UI/Button';
import { Card, CardBody } from '../../UI/Card';
import { FileUpload } from '../../UI/FileUpload';
import { FormGrid } from '../../UI/Form';
import { MutedText, TextLabel } from '../../UI/Text';

interface UploadAttachmentsModalProps {
  children: React.ReactNode;
  order: Order;
  organization: Organization;
}

export const UploadAttachmentsModal = ({
  children,
  organization,
  order,
}: UploadAttachmentsModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
    }
  }, [isOpen]);

  const { post: upload } = useAction(
    `/b/${organization.id}/orders/${order.id}/attachments/add`,
    {
      onSuccess: () => {
        setIsOpen(false);
      },
    }
  );

  const { t } = useTranslation();
  const filePreviews = useMemo(() => {
    return files.map((file) => URL.createObjectURL(file));
  }, [files]);

  const handleUpload = async () => {
    await upload({
      files,
    });
  };

  const { formatMb } = useFormat();

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent className="flex flex-col md:min-w-[800px] md:max-w-5xl">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<FileIcon className="h-7 w-7" />} />
            <div>
              <ModalTitle>{t('upload_attachments')}</ModalTitle>
              <ModalDescription className="pr-6 md:px-0">
                {t('upload_attachments_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className='overflow-auto sm:h-[min(100%,500px)]'>
          <FormGrid>
            <FileUpload
              id="attachments"
              accept="image/*"
              multiple
              onFilesChange={(files) => {
                setFiles(files);
              }}
            />
            <div className='grid overflow-hidden p-1 sm:overflow-auto sm:max-h-[200px] sm:grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-2'>
              {filePreviews.map((url, i) => {
                const filename = files[i].name;
                return (
                  <Card key={i}>
                    <CardBody size="sm">
                      <div className="flex justify-between">
                        <div className="flex space-x-4">
                          <div className="object-cover w-20 h-16 overflow-hidden rounded">
                            <img
                              src={url}
                              key={i}
                              alt={filename}
                              className="object-cover w-full h-full"
                            />
                          </div>
                          <div>
                            <TextLabel>{filename}</TextLabel>
                          </div>
                        </div>

                        <div>
                          <MutedText>{formatMb(files[i].size)}</MutedText>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                );
              })}
            </div>

          </FormGrid>
        </ModalBody>
        <ModalFooter>
          <Button
            className="flex justify-center"
            variant="white"
            onClick={() => setIsOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            className="flex justify-center"
            variant="primary"
            onClick={() => handleUpload()}
          >
            {t('upload')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

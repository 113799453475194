/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { Button } from '@/Button';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { SellerIcon } from '@/Icon';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { RegionPicker } from '@/Pickers/RegionPicker';
import TabFilter from '@/Tabs/TabFilter';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { useToast } from '~/hooks/useToast';
import { useTracking } from '~/hooks/useTracking';
import { DatePeriod } from '~/types/global-types';
import { AppMode, Organization, SavedReport } from '~/types/types';
import {
  Form,
  FormControl,
  FormField,
  FormGrid,
  FormItem,
  FormSeparator,
  Input,
  InputDescription,
  InputLabel,
} from '~/UI/Form';
import { RadioButtonCard, RadioGroup } from '~/UI/RadioButtonCard';
import { cn } from '~/utils/cn';
import { dateValidation } from '~/utils/validation';

import { OrganizationFilterType, ReportsPageProps, ReportType } from '../types';
import {
  organizationTypes,
  reportTypes,
} from './CreateReportForm.shared';

interface CreateReportFormProps {
  onSubmit: () => void;
  data?: SavedReport;
  isEditing?: boolean;
  sellers?: Organization[];
  regions?: any[];
}

const modeKeys = ['organizations', 'regions'];

const schema = z
  .object({
    report_id: z.string().optional(),
    name: z.string().min(1).max(255),
    type: z.string().refine((val) => reportTypes.includes(val as ReportType)),
    organization_filter_type: z
      .string()
      .refine((val) =>
        organizationTypes.includes(val as OrganizationFilterType)
      ),
    organization_ids: z.array(z.string()).optional(),
    region_names: z.string().nullable().optional(),

    /**
     * Date range: date-picker
     */
    date_range: dateValidation,
  })
  .refine(
    (data) => {
      if (data.organization_filter_type === 'ORGANIZATIONS') {
        return !!data?.organization_ids && data.organization_ids.length > 0;
      }

      return true;
    },
    {
      path: ['organization_ids'],
      message: 'Please select at least one supplier',
    }
  )
  .refine(
    (data) => {
      if (data.organization_filter_type === 'REGIONS') {
        return !!data?.region_names;
      }

      return true;
    },
    {
      path: ['region_names'],
      message: 'Please select a region',
    }
  );

type FormValues = z.infer<typeof schema>;

const useTabItems = (appMode: AppMode) => {
  return useMemo(() => {
    const isSeller = appMode === 'seller';
    if (isSeller) return ['Report on own organization', 'Report on region(s)'];
    return ['Report on selected sellers', 'Report on a region'];
  }, [appMode]);
};

export const CreateReportForm = ({
  onSubmit,
  data,
  isEditing,
  sellers,
  regions,
}: CreateReportFormProps) => {
  const { track } = useTracking();
  const {
    organization,
    app: { appMode },
  } = usePageProps<ReportsPageProps>();
  const { t } = useTranslation();
  const { toast } = useToast();

  const tabItems = useTabItems(appMode);

  const { post: create } = useAction(`/b/${organization.id}/reports/create`, {
    onSuccess: () => {
      toast({
        title: 'A new report has been created',
        description: 'The report is now available in the reports page.',
      });
      onSubmit();
    },
  });

  const { post: edit } = useAction(`/b/${organization.id}/reports/edit`, {
    onSuccess: () => {
      toast({
        title: 'Report has been edited',
        description: 'The edited report is now available in the reports page.',
      });
      onSubmit();
    },
    onError: (error) => {
      if (!error) return;

      toast({
        title: 'Failed to edit report',
        description: error.message,
      });
    },
  });

  const supplierOptions = (sellers || []).map((supplier) => ({
    label: supplier.title,
    value: supplier.id,
  }));

  const form = useForm<FormValues>({
    defaultValues: {
      report_id: data?.id,
      date_range: {
        type: data?.date_range_type || 'CUSTOM',
        from: data?.from_date || null,
        to: data?.to_date || null,
      },
      name: data?.name || '',
      type: data?.type || 'FORECAST_ACCURACY',
      organization_filter_type:
        data?.organization_filter_type || 'ORGANIZATIONS',
      organization_ids: data?.organizations?.map((org) => org.id) || [],
      region_names: data?.region_countries?.[0] || null,
    },
  });

  useEffect(() => {
  }, [form.register]);

  const organizationFilterType = form.watch('organization_filter_type');
  const dateRangeType = form.watch('date_range.type');

  const submit = async (data: FormValues) => {
    if (isEditing) {
      await edit({
        ...data,
        region_names: [data?.region_names],
      });
      track({
        event: 'edit_report',
        type: data.type as ReportType,
        id: data.report_id || 'no id found',
      });
    } else {
      await create({
        ...data,
        region_names: [data?.region_names],
      });

      track({
        event: 'create_report',
        type: data.type as ReportType,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <input type="hidden" {...form.register('report_id')} />
        <FormGrid>
          <FormItem>
            <InputLabel htmlFor="name">{t('report_name')}</InputLabel>
            <InputDescription>{t('report_name_description')}</InputDescription>
            <Input
              className={cn(
                `${!!form.formState.errors.name && 'border-red-500'}`
              )}
              placeholder={t('report_name_placeholder')}
              {...form.register('name')}
              id="name"
            />
          </FormItem>

          <FormSeparator/>

          <FormField
            name="type"
            render={({ field }) => {
              return (
                <FormItem>
                  <InputLabel>{t('report_type')}</InputLabel>
                  <InputDescription>
                    {t('report_type_description')}
                  </InputDescription>
                  <FormControl>
                    {/* This radio group  toggles between Forecast accuracy / Quality / Quality Costs */}
                    <RadioGroup
                      disabled={isEditing}
                      onValueChange={field.onChange}
                      value={field.value}
                      className="space-y-2"
                    >
                      <RadioButtonCard
                        id="FORECAST_ACCURACY"
                        value="FORECAST_ACCURACY"
                        label={t('reports.type_FORECAST_ACCURACY') as string}
                        subLabel={
                          t(
                            'reports.type_FORECAST_ACCURACY_description'
                          ) as string
                        }
                      />
                      <RadioButtonCard
                        id="QUALITY"
                        value="QUALITY"
                        label={t('reports.type_QUALITY') as string}
                        subLabel={t('quality_report_description') as string}
                      />
                      <RadioButtonCard
                        id="QUALITY COSTS"
                        value="QUALITY_COSTS"
                        label={t('reports.type_QUALITY_COSTS') as string}
                        subLabel={
                          t('reports.type_QUALITY_COSTS_description') as string
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              );
            }}
          />

          <FormSeparator/>

          <div>
            <InputLabel>{t('report_on')}</InputLabel>
            <InputDescription>{t('report_on_description')}</InputDescription>
          </div>

          <div className="relative">
            <TabFilter
              initialIndex={
                modeKeys.findIndex(
                  (key) => key === organizationFilterType.toLocaleLowerCase()
                ) || 0
              }
              onChange={async (index) => {
                form.setValue(
                  'organization_filter_type',
                  modeKeys[index].toUpperCase() as OrganizationFilterType
                );
                await form.trigger('region_names');
                await form.trigger('organization_ids');
              }}
              items={tabItems}
            />
          </div>

          {appMode === 'buyer' &&
            organizationFilterType === 'ORGANIZATIONS' && (
            <FormField
              name="organization_ids"
              render={({ field }) => (
                <FormItem>
                  <InputLabel>Select suppliers</InputLabel>
                  <InputDescription>
                      Select the suppliers that you want to include in the
                      report.
                  </InputDescription>
                  <FormControl>
                    <div className="w-fit">
                      <GenericPicker
                        {...field}
                        key="supplier_picker"
                        icon={SellerIcon}
                        options={supplierOptions}
                        emptyMessage="No suppliers found"
                        isMulti
                        selected={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        placeholder="Select suppliers"
                        title="Select sellers"
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          {organizationFilterType === 'REGIONS' && (
            <FormField
              name="region_names"
              render={({ field }) => (
                <FormItem>
                  <InputLabel>Select region</InputLabel>
                  <InputDescription>
                    Select the region that you want to include in the report.
                  </InputDescription>
                  <FormControl>
                    <div className="w-fit">
                      <RegionPicker
                        {...field}
                        regions={regions}
                        selectedRegion={field.value}
                        onChange={field.onChange}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <hr/>

          <FormField
            name="date_range"
            render={({ field }) => (
              <FormItem>
                <InputLabel>Select date range</InputLabel>
                <InputDescription>
                  Select the date range for the report. This can be either a fixed
                  date, or an interval.
                </InputDescription>
                <FormControl>
                  <RangeDatePicker
                    defaultPeriod={dateRangeType as DatePeriod}
                    onChange={(data) => field.onChange(data)}
                    enablePeriod
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            type="submit"
            // @ts-ignore */
            disabled={!form.formState.isValid}
          >
            {isEditing ? 'Save changes' : 'Create report'}
          </Button>
        </FormGrid>
      </form>
    </Form>
  );
};

export const useFormat = () => {

  return {
    formatCurrency: (value: number, currency = 'GBP') => {
      return `£ ${value.toFixed(2)}`;
    },
    formatFractionPercentage: (fraction: number, decimals = 2) => {
      return `${(fraction * 100).toFixed(decimals)}%`;
    },
    formatPercentage: (value: number, decimals = 2) => {
      return `${value.toFixed(decimals)}%`;
    },
    formatMb: (kb: number) => {
      return `${(kb / 1024).toFixed(2)} MB`;
    },
    formatProduceName: (produceName?: string) => {
      if (produceName) {
        return produceName.charAt(0).toUpperCase() + produceName.toLowerCase().slice(1);
      }

      return produceName;
    },
  };
};

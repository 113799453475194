import { Card } from "./Card";

interface FloatingIconToolbarProps {
  children: React.ReactNode;
}

/**
 * FloatingIconToolbar Component
 *
 * Renders a floating toolbar for icon buttons.
 *
 * Usage:
 *    \<FloatingIconToolbar>
 *      Icon buttons go here ...
 *    \</FloatingIconToolbar>
 *
 * Props:
 *    - children: ReactNode - The icon buttons to be displayed in the toolbar.
 *
 * Notable Tailwind CSS Classes:
 *    - hidden: Hides the toolbar by default.
 *    - group-hover:flex: Displays the toolbar when its parent element is hovered. this means that you need to provide a parent element with the group class.
 *
 */
export const FloatingIconToolbar = ({ children }: FloatingIconToolbarProps) => {
  return (
    <div className="hidden group-hover:flex absolute top-2 right-2">
      <Card className="p-[2px] rounded-sm flex justify-center items-center">
        {children}
      </Card>
    </div>
  );
};

import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import { DebugJSONModal } from '@/JSON';
import { Nav } from '@/Nav/Nav';
import { FAB } from '~/Components/FAB.js';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider.js';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking';
import { Toaster } from '~/hooks/useToast';
import { OrganizationStatus } from '~/types/types';
import { logDebug, logInfo } from '~/utils/logger';

import { GeneratingModal } from '../../Dashboard/GeneratingModal.tsx';

// import { OnboardingModal } from './OnboardingModal';

interface SellerLayoutProps {
  children: React.ReactNode;
}

export const SellerLayout = ({ children }: SellerLayoutProps) => {
  const posthog = usePostHog();
  const props = usePageProps();
  const { user, organization, show_onboard_modal, app: { appMode, path } } = props;

  usePageTracking(path);
  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  logInfo(`AppMode: ${appMode}`);
  logDebug('App Props', props);

  return (
    <div className="min-h-screen flex flex-col bg-slate-50 [&>*]:w-full">
      <ServerErrorProvider>
        <DebugJSONModal json={props} />
        <Nav />
        <Toaster />
        <FAB />
        {/* <OnboardingModal user={user} organization={organization} show={show_onboard_modal ?? false} /> */}
        {organization.status === OrganizationStatus.GENERATING && (
          <GeneratingModal />
        )}

        {organization.status === OrganizationStatus.GENERATING ? (
          <ErrorBoundary onError={() => {
            console.log('ignore');
          }} fallback={(
            <div>
            Generating new data
            </div>
          )}>
            {children}
          </ErrorBoundary>
        ) : (
          <>
            {children}
          </>
        )}
      </ServerErrorProvider>
    </div>
  );
};

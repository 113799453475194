import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { MutedText, Strong } from '@/Text';
import { getIssueBg } from '~/Components/Orders/FormatIssue';
import { IssueActions } from '~/Components/Orders/IssueActions';
import { IssueEvents } from '~/Components/Orders/IssueEvents';
import { OrderIssue } from '~/Components/Orders/OrderIssue';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { Issue, IssueStatus, TemperatureIssue } from '~/types/types';
import { cn } from '~/utils/cn';

import { QualityLabel } from '../../QualityLabel';
import { useOrder } from '../OrderProvider';

export interface TemperatureIssuesProps {
  refs: any;
  floatingStyles: any;
  issues: TemperatureIssue[];
}

interface FormatTempIssueProps {
  issue: Issue;
}

export const FormatTempIssue = ({ issue }: FormatTempIssueProps) => {
  const { t } = useTranslation();
  const { parseOrFormat } = useDate();

  const { startDate, endDate, anomalyCount } = issue.issueable.data;
  const formattedStartDate = parseOrFormat(startDate, DateFormat.HumanDateTime);
  const formattedEndDate = parseOrFormat(endDate, DateFormat.HumanDateTime);

  return (
    <div>
      {t('temperature_issue_description', {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        anomalyCount,
      })}
    </div>
  );
};

const TemperatureIssueItem = ({ issue }: { issue: Issue }) => {
  const { t } = useTranslation();
  const routes = useRoutes<BuyerRoutes>();
  const { order } = useOrder();
  const { user, isBuyer } = usePageProps();

  const { post: resolve, loading } = useAction(routes.resolveIssue(issue.id));

  const handleResolve = async (data: any) => {
    const { status, comment, informSeller } = data;

    let type: string;

    if (status === 'escalated') {
      type = 'mark_as_escalated';
    } else if (status === 'accepted') {
      type = 'mark_as_accepted';
    } else {
      type = 'mark_as_read';
    }

    await resolve({
      type,
      userId: user.id,
      issueId: issue.id,
      buyerId: order.buyer_id,
      comment,
      informSeller,
    });
  };

  return (
    <div key={issue.id} className={cn(
      '-my-2 py-4 px-3 -mx-3 rounded-b',
      getIssueBg(issue.status),
    )}>
      <div>
        <div>
          <QualityLabel issues={[issue]}/>
        </div>
        <div className="mb-2">
          <MutedText>
            <Trans
              i18nKey="temperature_issue_described"
              values={{
                fieldName: t(issue.summarizable?.field.translation_key_ref, {
                  defaultValue: issue.summarizable?.field?.name,
                }),
                target: issue.issueable?.target,
                unit: issue.summarizable?.field?.unit,
              }}
            >
              <Strong className="text-xs"/>
              <FormatTempIssue issue={issue}/>
            </Trans>
          </MutedText>
        </div>
        {isBuyer && (
          <div>
            <IssueActions
              loading={loading}
              onClick={() => handleResolve({ status: 'read', comment: '', informSeller: false })}
              issue={issue}
              onChange={handleResolve}
            />
          </div>
        )}
      </div>

      <IssueEvents issue={issue}/>
    </div>
  );
};

const TemperatureIssuesBody = ({ issues }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const nonReadIssues = issues.filter(issue => issue.status !== IssueStatus.Read);
  const readIssues = issues.filter(issue => issue.status === IssueStatus.Read);

  const onlyOpenIssues = issues.length > 0 && nonReadIssues.length === 0;

  console.log('isCollapsed', isCollapsed);

  return (
    <div className={cn('divide-y', onlyOpenIssues ? 'divide-red-100' : 'divide-gray-50')}>
      {!onlyOpenIssues && nonReadIssues.map((issue) => (
        <TemperatureIssueItem issue={issue} key={issue.id}/>
      ))}

      {onlyOpenIssues && (
        <div>
          <MutedText>
            All issues marked as read
          </MutedText>
        </div>
      )}

      {readIssues.length > 1 && (
        <Collapsible.Root>
          <Collapsible.Trigger asChild>
            <div className="py-4 -mb-2">
              <Button type="button" size="xs" variant="white">
                Toggle read ({readIssues.length})
              </Button>
            </div>
          </Collapsible.Trigger>

          <Collapsible.Content className="CollapsibleContent">
            <div className="px-2">
              {readIssues.map((issue) => (
                <TemperatureIssueItem issue={issue} key={issue.id}/>
              ))}
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      )}
    </div>
  );
};

export default TemperatureIssuesBody;

/**
 * Temperature issues interprets issues from the temperature summary and displays them in a card.
 *
 */
export const TemperatureIssues = ({ refs, floatingStyles, issues }: TemperatureIssuesProps) => {
  const { t } = useTranslation();

  return (
    <OrderIssue
      renderBody={<TemperatureIssuesBody issues={issues}/>}
      issues={issues}
      title={t('issue_encountered')}
      floatingStyles={floatingStyles}
      refs={refs}
    />
  );
};

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          add_produce: 'Add produce',
          edit_fields: 'Edit fields',
          add_field: 'Add field',
          add_first_field: 'Add first field',
          no_fields: 'No fields',
          no_fields_description:
            'Add fields to this spec to define the quality constraints.',
          add_spec: 'Add spec',
          field_form: {
            title: 'Field name',
            title_description: 'THe ',
          },
          issue_actioned: {
            'mark_as_read': '{{ name }} marked as read',
            'mark_as_accepted': '{{ name }} accepted the issue',
            'mark_as_escalated': '{{ name }} escalated the issue',
          },
          issue_status: {
            'all': 'All',
            'minor_major_defects': 'Minor & Major defects',
            'out_of_measurement_spec': 'Measurement out of spec',
            'transport_delay': 'Logistics delay',
            'transport_temp_threshold': 'Temperature threshold',
            'transport_light_threshold': 'Light threshold',
          },
          manage_produce_settings: 'Manage your produce settings',
          produce_types: {
            red_apple: 'Red Apple',
            apple: 'Apple',
            cherry: 'Cherry',
            gala: 'Gala',
            golden_delicious: 'Golden Delicious',
            granny_smith: 'Granny Smith',
            jazz_apple: 'Jazz Apple',
            pink_lady: 'Pink Lady',
            citrus: 'Citrus',
            clemengold: 'Clemengold',
            lemongold: 'Lemongold',
            lemon: 'Lemon',
            lime: 'Lime',
            mandarin: 'Mandarin',
            red_grapefruit: 'Red Grapefruit',
            oranges: 'Oranges',
            soft_citrus: 'Soft Citrus',
            berry: 'Berry',
            blackberry: 'Blackberry',
            blueberry: 'Blueberry',
            raspberry: 'Raspberry',
            strawberry: 'Strawberry',
            melon: 'Melon',
            cantaloupe: 'Cantaloupe',
            honey_dew: 'Honey Dew',
            water_melon: 'Water Melon',
            stone_fruit: 'Stone Fruit',
            cherry_tomato: 'Cherry Tomato',
            plum_tomato: 'Plum Tomato',
            nectarine: 'Nectarine',
            peach: 'Peach',
            plum: 'Plum',
            pineapple: 'Pineapple',
            kiwi_fruit: 'Kiwi Fruit',
            fig: 'Fig',
            pear: 'Pear',
            conference: 'Conference',
            grapes: 'Grapes',
            grapes_green: 'Grapes Green',
            rhubarb: 'Rhubarb',
            avocado: 'Avocado',
            bananas: 'Bananas',
            generic: 'Generic',
            fruit: 'Fruit',
          },
          issue_breakdowns: {
            light_issues_one: '{{count}} light issue',
            light_issues_other: '{{count}} light issues',
            temperature_issues_one: '{{count}} temperature issue',
            temperature_issues_other: '{{count}} temperature issues',
            measurement_issues_one: '{{count}} measurement issue',
            measurement_issues_other: '{{count}} measurement issues',
            minor_major_issues_one: '{{count}} minor/major issue',
            minor_major_issues_other: '{{count}} minor/major issues',
          },
          escalating: 'Escalating',
          accepted: 'Accepted',
          escalated: 'Escalated',
          read: 'Read',
          resolve_issue: 'Resolve issue',
          temperature_issue_description: 'The temperature/light of the produce was out-of-bounds between {{ startDate }} and {{ endDate }} for {{ anomalyCount }} consecutive readings.',
          nr_entries_one: '{{count}} entry',
          nr_entries_other: '{{count}} entries',
          'share_with_seller': 'Inform seller',
          'share_with_seller_description': ': they will receive a notification about this issue along with a comment.',
          'bestBeforeDate': 'Best before date',
          'bestBeforeDate_description': 'The date by which the product should be consumed for optimal quality.',
          'packhouseName': 'Packhouse name',
          'packhouseName_description': 'The name of the facility where the produce was packed.',
          'packhouseCode': 'Packhouse code',
          'packhouseCode_description': 'A unique code identifying the packhouse where the produce was packed.',
          'traceabilityCode': 'Traceability code',
          'traceabilityCode_description': 'A code used to trace the produce back to its origin.',
          'harvestDate': 'Harvest date',
          'harvestDate_description': 'The date on which the produce was harvested.',
          'GGN': 'Global Gap Number',
          'GGN_description': 'A unique identifier assigned to producers who comply with Global GAP standards.',
          'latest_update': 'Latest update',
          'shipping_date': 'Shipping date',
          'arrival_date': 'Arrival date',
          'save_settings': 'Save settings',
          'save_settings_description': 'for future use',
          close: 'Close',
          filter: 'Filter',
          apply: 'Apply',
          clear_filters: 'Clear filters',
          no_units_reported: 'Unit count N/A',
          percentage_defect: 'Percentage defect',
          percentage_defect_description: 'What percentage of the produce had this defect?',
          count_defects: 'Count defects',
          count_defects_description: 'How many of the inspected units (berries, cases) had this defect?',
          total_inspected: 'Total inspected',
          total_inspected_description: 'How many units (berries, cases) were inspected?',
          average_measurement: 'Average measurement',
          average_measurement_description: 'What was the average measurement for this test? (E.g. 10mm or 100kg)',
          total_samples_inspected: 'Total samples inspected',
          total_samples_inspected_description: 'How many units (berries, cases) were inspected in total?',
          report_by_percentage_tooltip: 'Enable to report your quality intake by the percentage of the produce that contained this defect.',
          report_by_percentage: 'Report by percentage',
          defects: 'Defects',
          pallet: 'Pallet',
          breakdown_issues_per_category: 'Breakdown of issues per category',
          view_order: 'View order',
          select_date: 'Select date',
          select_date_description: 'Click on the chart to explore detailed data for a specific date.',
          field_breakdown: 'Field breakdown',
          quality_issues: 'Quality issues',
          trend_detected: 'Trend detected',
          trend_detected_description:
            'One or more trends have been detected in the data. To understand the trend, click on the detected "warning" labels below. You will see in the underlying breakdown how the trend is affecting the data.',
          measurement_comparison: 'Measurement comparison',
          measurement_comparison_description: 'This section compares the input fields between buyer and seller to evaluate the accuracy of the seller\'s data.',
          buyer_statistics: 'Buyer statistics',
          seller_statistics: 'Seller statistics',
          overview: 'Overview',
          edit_report: 'Edit report',
          sellers: 'Sellers',
          produces_in_report: 'Produces',
          by_orders: 'By orders',
          filters: 'Filters',
          close_analysis: 'Close analysis',
          breakdown: 'Breakdown',
          create: 'Create',
          none: 'None',
          apply_filters: 'Apply filters',
          sellers_in_report: 'Sellers',
          select_all: 'Select all',
          ascending: 'Ascending',
          descending: 'Descending',
          click_chart_for_details: 'Click on the chart for more details',
          seller: 'Seller',
          reason: 'Reason',
          sort_by: 'Sort by',
          qa_input_missing: 'QA Input missing',
          back_to: 'Back to',
          by_last_updated: 'Sorted by last updated',
          minor_defects: 'Minor defects',
          n_ignored_one: '{{count}} ignored',
          n_ignored_other: '{{count}} ignored',
          n_resolved_one: '{{count}} resolved',
          n_resolved_other: '{{count}} resolved',
          n_escalated_one: '{{count}} escalated',
          n_escalated_other: '{{count}} escalated',
          n_accepted_one: '{{count}} accepted',
          n_accepted_other: '{{count}} accepted',
          green: 'Green',
          n_claims_one: '{{count}} claim',
          n_claims_other: '{{count}} claims',
          red: 'Red',
          major_defects: 'Major defects',
          minor: 'Minor',
          major: 'Major',
          quality_report_card: 'Inload summary',
          quality_report_card_description: 'See the performance of your produce, pallets, live at a glance',
          last_updated: 'Last updated',
          n_issues_unresolved_one: '{{count}} open issue',
          n_issues_unresolved_other: '{{count}} open issues',
          n_issues_ignored_one: '{{count}} issue ignored',
          n_issues_ignored_other: '{{count}} issues ignored',
          n_issues_resolved_one: '{{count}} issue resolved',
          n_issues_resolved_other: '{{count}} issues resolved',
          last_updated_description:
            'View the orders in the order they were last updated, from recent to old.',
          by_arrival_date: 'Sorted by arrival date',
          by_shipping_date: 'Sorted by shipping date',
          shipping_date_description:
            'View the orders from the day they will ship, from today onwards.',
          arrival_date_description:
            'View the orders from the day they will arrive, from today onwards.',
          input: 'Input',
          add_another_field: 'Add another field',
          add_a_note: 'Add a note',
          quality_input_fields: {
            logistics: 'Logistics',
            chill_damage: 'Chill damage',
            shrivel: 'Shrivel',
            brix: 'Brix',
            quality: 'Quality',
            condition: 'Condition',
            characteristics: 'Characteristics',
            unknown: 'General',
            undefined: 'General',
            fruit_size: 'Fruit size',
            box_weight: 'Box weight',
          },
          sort_by_describers: {
            last_updated: 'Last updated',
            arrival_date: 'To arrive',
            shipping_date: 'To ship',
          },
          yes: 'Yes',
          yesses: 'Yesses',

          no: 'No',
          cases: 'Cases',
          details: 'Details',
          produces: 'Produces',
          fields: 'Fields',
          general: 'General',
          today: 'Today',
          tomorrow: 'Tomorrow',
          yesterday: 'Yesterday',
          transport_temp_threshold: 'Temperature threshold',
          no_order_associated: 'No order associated',
          no_order_associated_description:
            'This issue has been generated without any associated POs. The PO might still be imported.',
          this_week: 'This week',
          next_week: 'Next week',
          next_month: 'Next month',
          activity: 'Activity',
          activity_description: '(Admin-only) View the activity log of the order.',
          last_week: 'Last week',
          this_month: 'This month',
          last_month: 'Last month',
          choose_dataset: 'Choose dataset',
          variety: 'Variety',
          varieties: 'Varieties',
          edit_varieties: 'Edit varieties',
          has_commented_on: 'has commented on',
          generate_demo_data: 'Generate demo data',
          generate_demo_data_description:
            'Choose the dataset you want to use to generate in your organization.',
          choose_dataset_description:
            'Choose the dataset you want to use to generate in your organization.',
          waitrose_dataset: 'WeightRoast dataset',
          waitrose_dataset_description:
            'Use the WeightRoast dataset to generate data in your organization. This generates a dataset worth of 3 months that is similar to the WeightRoast dataset.\n\n Note: this will take approximately ~10 minutes.',
          mass_dataset: 'Mass dataset',
          mass_dataset_description:
            'Use the Mass dataset to generate data in your organization. This generates a dataset that is similar to the Mass dataset.\n\n Note: this will take approximately ~5 minutes.',
          org_maintenance: 'Organization maintenance',
          org_maintenance_description:
            'Your workspace is currently undergoing maintenance.',
          org_maintenance_body:
            'Our team is currently implementing routine updates to your workspace. These enhancements are intended to optimize your experience and workflow efficiency. We appreciate your patience during this brief period of unavailability. We will have your workspace up and running shortly.',
          older: 'Older',
          spec_accuracy_score: 'Spec accuracy score',
          forecast_accuracy_score: 'Forecast accuracy score',
          all_issues_handled_one: '{{count}} issue handled',
          all_issues_handled_other: '{{count}} issues handled',
          resolve: 'Resolve',
          resolved: 'Resolved',
          ignore: 'Ignore',
          ignored: 'Ignored',
          ignore_once: 'Ignore once',
          ignore_for: 'Ignore <0> {{fieldName}} </0> for...',
          ignore_for_n_days: 'Ignore <0> {{fieldName}} </0> for <0>{{days}}</0> days',
          ignore_for_organization: 'Ignore <0> {{fieldName}} </0> for <0>{{organization}}</0>...',
          ignore_for_organization_n_days: 'Ignore <0> {{fieldName}} </0> for {{organization}} for <0>{{days}}</0> days',
          measurement_issue_described:
            '<0>{{fieldName}}</0> had a measurement of <1></1> which is outside the tolerance of {{target}} {{unit}}.',
          associated_spec_description:
            'This is the specification that is currently associated with the produce.',
          add_quality_input_description:
            'Fill in the form below to add quality inputs for a produce.',
          edit_quality_input_description:
            'Adjust the data below to edit the quality input for this produce.',
          spec: 'Spec',
          quality_fields: 'Quality fields',
          quality_fields_description:
            'These are the fields that are used to determine the quality of your produce. Select the fields that are relevant to your produce.',
          pallet_ref_description: 'Please locate and provide a Pallet ID.',
          events: {
            order_arrived: 'Order arrived',
            load_plan_added: 'Load plan',
            updated: 'Updated',
            quality_added: 'QA data added',
            quality_updated: 'QA data updated',
            order_status_changed: 'Status changed',
            order_created: 'Order created',
            order_attachment_added: 'Attachments added',
            generic: 'Order updated',
          },
          order_generating: 'Calculating new summaries...',
          order_last_updated: 'Updated at',
          order_po: 'Order PO',
          n_comments_one: '{{count}} comment',
          n_comments_other: '{{count}} comments',
          no_spec: 'No spec',
          see_more: 'See more',
          n_new_orders_one:
            '<0>{{count}} new order</0> <1>has been added to your workspace</1>',
          n_new_orders_other:
            '<0>{{count}} new orders</0> <1>have been added to your workspace</1>',
          last_update: 'Last update',
          history: 'History',
          created_at: 'Created at',
          manage_order: 'Order Specs Management',
          manage_order_description:
            'Facilitate the organization and handling of your order specifications in this section.',
          temp_spec_step_confirm: 'Step 4: Confirm',
          temp_spec_step_confirm_description:
            'Please confirm these changes are appropriate.',
          temp_spec: 'Temporary Spec',
          temp_spec_description:
            'A temporary spec is a specification that is applied temporarily on the relevant produce.',
          upload_temp_spec_title: 'Temporary Spec File Upload',
          upload_temp_spec_description:
            'Submit your files here for subsequent validation and performance testing.',
          validate_spec: 'Validate spec',
          specifications: 'Specifications',
          submit: 'Submit',
          suppliers: 'Suppliers',
          awaiting_data: 'Awaiting data',
          count_selected: '{{count}} selected',
          awaiting_data_description:
            'Awaiting data from your ERP system. This may take a few minutes.',
          transport_route: 'Transport route',
          transport_route_description:
            'Track the intricate transit path of your produce, with an emphasis on the strategic location and role of the transport company.',
          harvest: 'Harvest',
          origin_weather: 'Harvest weather',
          origin_weather_description:
            'Capture key weather patterns including temperature, rainfall, and humidity from the original harvest area.',
          temperature: 'Temperature',
          anomaly_detected: 'Anomaly detected',
          notifications: 'Notifications',
          empty_states: {
            no_comments: 'No comments available yet',
            no_notifications: 'No notifications available yet',
            no_results_found: 'No results found',
          },
          comments: 'Comments',
          select_field: 'Select field',
          select_section: 'Select section',
          search_field: 'Search field',
          search_sections: 'Search sections',
          section: 'Section',
          sections: 'Sections',
          select_region: 'Select region',
          all: 'All',
          all_description: 'Show all orders',
          issues_only: 'With issues',
          issues_only_description: 'Only show orders which have an issue',
          input_required: 'Input required',
          regions: 'Regions',
          all_regions: 'All regions',
          input_required_description:
            'Only show orders which require your input',
          orders: 'Orders',
          view_orders: 'View ongoing orders',
          create_and_manage_orders: 'Create and manage orders',
          step: 'Step',
          steps: 'Steps',
          search_steps: 'Search steps',
          submit_inspection: 'Submit inspection',
          no_users_found: 'No users found',
          no_user_groups_found: 'No user groups found',
          not_enough_supplier_data_found:
            'Not enough supplier data available to calculate performance scores',
          no_steps_found: 'No steps found',
          no_sections_found: 'No sections found',
          no_suppliers_at_risk: 'No suppliers at risk',
          no_top_performing_suppliers: 'No top performing suppliers',
          add: 'Add',
          save_spec: 'Save spec',
          discard_changes: 'Discard changes',
          nil: 'Nil',
          comment: 'Comment',
          add_comment: 'Add comment',
          add_manual_input: 'Add manual input',
          waiting_for_supplier_input: 'Waiting for supplier input',
          add_quality_input: 'Add quality input',
          edit_quality_input: 'Edit quality input',
          add_data_field: 'Add data field',
          field: 'Field',
          inspections: 'Inspections',
          add_form_template: 'Add form template',
          admin_dashboard: 'Admin dashboard',
          attachments: 'Attachments',
          attachments_description:
            'Upload attachments to your orders to keep track of important documents, such as photos taken.',
          live_update: 'Live update',
          logistics: 'Logistics',
          issue_encountered: 'Issue encountered',
          awaiting_barcode_upload: 'Awaiting Barcode upload',
          awaiting_climate_control_upload: 'Awaiting Climate Control upload',
          awaiting_temperature_upload: 'Awaiting Temperature upload',
          awaiting_transportation_upload: 'Awaiting Transportation upload',
          back: 'Back',
          barcode: 'Barcode',
          create_report: 'Create report',
          barcode_data: 'Barcode data',
          boolean: 'Correct checks',
          issues_encountered_one: '{{count}} issue encountered',
          issues_encountered_other: '{{count}} issues encountered',
          cases_one: '{{count}} case',
          cases_other: '{{count}} cases',
          climate: 'Climate',
          climate_control_data: 'Climate control data',
          climate_controls: 'Climate controls',
          closed: 'Closed',
          containers: {
            pallet: 'Pallet',
          },
          create_report_description:
            'Generate custom analytics reports tailored to your specific needs, offering key insights on demand.',
          create_report_title: 'Create report',
          edit_report_description:
            'Edit custom analytics reports tailored to your specific needs, offering key insights on demand.',
          edit_report_title: 'Edit report',
          expected_arrival: 'Expected arrival',
          create_workspace: 'Create workspace',
          create_workspace_description:
            'A workspace represents the quality, orders and insights of a particular organization.',
          current_statuses: 'Current statuses',
          data_fields: 'Data fields',
          delete: 'Delete',
          delivery_terms: 'Delivery terms',
          delivery_destination: 'Delivery destination',
          description: 'Description',
          do_later: 'Do it later',
          edit: 'Edit',
          email_address: 'Email address',
          forecast_accuracy: 'Forecast accuracy',
          FORECAST_ACCURACY: 'Forecast accuracy',
          forecast_accuracy_description:
            'This report analyses discrepancies between the Suppliers Quality Control check, the buyer\'s Quality Assurance Intake check, and the quality specification document.',
          form_template: 'Form template',
          form_templates: 'Form templates',
          go_to_settings: 'Let\'s setup',
          grower_id: 'ID (GGN / GLN)',
          in_transit: 'In transit',
          inspection: 'Inspection',
          issues: 'Issues',
          issues_in_q: 'Issues in {{ quarter }}',
          issues_one: 'Issue',
          issues_other: 'Issues',
          label: 'Label',
          latest_data_sync: 'Latest sync',
          log_out: 'Log out',
          login: 'Login',
          harvest_date: 'Harvest date',
          harvest_date_description: 'The date the produce was harvested.',
          login_description:
            'To access the platform, please login with your email.',
          manual_upload: 'Manual upload',
          measurement: 'Measurement',
          measurement_consistency: 'Measurement consistency',
          measurement_consistency_description:
            'The measurements of the pallets are consistent with each other.',
          measurement_consistency_distances:
            'The measurements of <0>{{ measurements_count }}</0> differ on average ~ <0>{{ distance }} {{ unit }}.',
          measurement_summary_description: {
            summary_no_issue_no_range:
              'Based on <0>{{ unit_count }} {{ unit }}</0>, the measurements lie between <0>{{ min }}</0> and <0>{{ max }}</0> with an average of <0>{{ avg }}</0>.',
            summary_no_issue_with_range:
              'Based on <0>{{ unit_count }} {{ unit }}</0>, the measurements lie between <0>{{ min }}</0> and <0>{{ max }}</0> with an average of <0>{{ avg }}</0>. The satisfied the range of <0>{{ range_min }}</0> - <0>{{ range_max }}</0>.',
            summary_with_issue_with_range:
              'Based on <0>{{ unit_count }} {{ unit }}</0>, the measurements lie between <0>{{ min }}</0> and <0>{{ max }}</0> with an average of <0>{{ avg }}</0>. A count of <0>{{ fail_count }}</0> pallets did not satisfy the range of <0>{{ range_min }}</0> - <0>{{ range_max }}</0>.',
          },
          n_issues_one: '{{count}} issue',
          n_issues_other: '{{count}} issues',
          n_issues_found_in_workspace_one:
            '<0>{{count}} issue</0> <1>has been found in your workspace between</1>',
          n_issues_found_in_workspace_other:
            '<0>{{count}} issues</0> <1>have been found in your workspace between</1>',
          report_on: 'Select a reporting subject',
          report_on_description:
            'Reports can be created on a variety of subjects, such as individual organizations or regions.',
          name: 'Name',
          next: 'Next',
          cancel: 'Cancel',
          no_issues: 'No issues',
          options: 'Options',
          order_details: 'Order details',
          order: 'Order',
          pending: 'Pending',
          pending_issues: 'Pending issues',
          upload: 'Upload',
          upload_attachments: 'Upload attachments',
          upload_attachments_description:
            'Upload attachments to your orders to keep track of important documents, such as photos taken.',
          performance: 'Performance',
          performance_score: 'Performance score',
          supplier: 'Supplier',
          buyer: 'Buyer',
          produce: 'Produce',
          your_produces_in_use: 'Your produces in use',
          region: 'Region',
          resync_demo_data: 'Generate demo data',
          generating_data_title: 'Generating organization data',
          generating_data_description:
            'We are generating data for your organization. This may take a few minutes.',
          generating_data_body:
            'Please hold on for a moment. Your data is being generated and will be available within a maximum of 30 seconds. We appreciate your patience.',
          grower_id_field_description:
            'The grower\'s ID, such as "GGN" or "GLN".',
          grower_id_field: 'Grower ID',
          orders_page_search: 'Search by PO, produce, region',
          seller_orders_page_search: 'Search by PO number, produce or step  (e.g. "123", "Blueberries", "Arrived")',
          reports: {
            costs_correlations: 'Costs Correlations',
            costs_correlations_description:
              'This report analyses the correlation between costs and quality issues. By studying the relationship between issues and costs, you can start understanding ' +
              'the financial impact of quality issues.',
            total_costs: 'Total costs',
            period_of: 'Period of {{ period }}',
            quality_costs_period_analysis_description: 'Here is a detailed analysis of costs for the selected period, categorized by supplier, reason, and order. Click on any order to access related quality data.',
            forecast_accuracy_analysis_description: 'This detailed analysis provides an overview of the various measurements differences between buyer and seller, as well as the associated orders. Click on any order to access related quality data.',
            quality_costs_over_time: 'Quality Costs Over Time',
            quality_costs_over_time_description:
              'This report analyses the costs associated with quality issues.',
            reports: 'Reports',
            type_FORECAST_ACCURACY: 'Forecast Accuracy Report',
            type_FORECAST_ACCURACY_description:
              'This report analyses discrepancies between the Suppliers Quality Control check, the buyer\'s Quality Assurance Intake check, and the quality specification document.',
            type_QUALITY: 'Quality Issues Report',
            type_QUALITY_COSTS: 'Quality Costs Report',
            type_QUALITY_COSTS_description:
              'This report analyses the costs associated with quality issues.',
            quality_issues_comparison_title:
              'Quarterly Supplier Quality Comparison',
            quality_issues_comparison_description:
              'Compare and contrast the occurrence of quality issues across different quarters and suppliers. This comparative data aids in evaluating supplier credibility and making strategic improvements.',
            quality_over_time_series_title:
              'Quality issues per supplier over time',
            quality_over_time_series_description:
              'Analyze the frequency of quality issues associated with each supplier in a time-bound manner. This data enables evaluation of supplier performance and aids in strategic decision-making.',

            quality_issues_period_analysis_description: 'Analyze the breakdown of your quality issues of the selected seller over your orders. Each order has a small breakdown of the issues.',
          },
          order_with_inaccuracies: 'Orders with inaccuracies',
          upload_quality_input: 'Upload quality input',
          upload_files: 'Upload files',
          upload_files_description:
            'Upload files by dragging and dropping them into the box below, or pressing it.',
          toasts: {
            quality_input_error_title: 'Unable to save quality data',
            quality_input_error_message:
              'Your quality data is still waiting to be uploaded, but it is necessary to fill in all the required fields. Please look at the fields marked in red, and then try again. If the problem persists, please contact support.',
            success: 'Success',
            error: 'Oh no!',
            error_message: 'Something went wrong',
            quality_input_added: 'Quality input added',
            quality_input_edited: 'Quality input updated',
          },
          placeholders: {
            no_orders_available: 'No orders available',
            no_orders_available_description: 'Based on the current filters, no orders are visible. Try adjusting the filters or clear the filters to see more orders.',
            pallet_id: 'Pallet ID',
            enter_email: 'Email address',
            no_orders_buyers_description:
              'As orders begin to process, relevant data will populate automatically in this section.',
            write_a_reply: 'Write a reply...',
            no_orders: 'No orders created',
            no_orders_description:
              'Orders are created by your buyers and are available once they have been created.',
            no_fields: 'No fields',
            no_fields_description:
              'Field data becomes available once respective field inspections are completed within our QA and QC processes in your ERP system. Alternatively, you can upload field data manually.',
            no_attachments: 'Upload attachments',
            attachments_data_placeholder:
              'Upload attachments to your orders to keep track of important documents, such as photos taken.',
            no_reports: 'No reports created',
            no_reports_description:
              'Create your first custom report to get started.',
            forecast_accuracy_data_placeholder:
              'Forecast data becomes available once respective field inspections are completed within our QA and QC processes.',
            no_data: 'No data available',
            no_data_for_period_description: 'No data available for the selected period.',
            no_data_description: 'Data will be available once it has been generated.',
            quality_costs_placeholder: 'This report is pending financial cost data. Once data becomes available we pull in your quality claim information and will show it.',
            quality_timeseries_data_placeholder:
              'Quality data becomes available once issues have been identified in various orders over time.',
          },
          period_date_picker: {
            CUSTOM: 'Custom',
            DAILY: 'Today',
            MONTHLY: 'This month',
            QUARTERLY: 'This quarter',
            WEEKLY: 'This week',
            YEARLY: 'This year',
          },
          profile: 'Profile',
          qa_intake: 'QA Intake',
          qa_input_required: 'QA Input required',
          qa_input_single_required: 'Order requires your input',

          quality: 'Quality',
          quality_checks_one: '{{count}} quality check',
          quality_checks_other: '{{count}} quality checks',
          quality_report: 'Quality Issues Report',
          quality_report_description:
            'This report analyses the reasons correlated to quality issues. The report focuses on Issues flagged by the AgStacked platform. ',
          remember_me: 'Remember me',
          report_name: 'Report name',
          report_name_description:
            'Give your report a name that will help you identify it later.',
          report_name_placeholder: 'Weekly Blueberries in Peru',
          report_type: 'Report type',
          report_type_description:
            'Select the category of reporting you would like to see.',
          request_fields: 'Request fields',
          save: 'Save',
          saved_reports: 'Saved reports',
          saved_reports_description:
            'Saved reports are reports that are saved for later use. You can create a saved report by clicking on the save button in the report view.',
          settings: 'Settings',
          size: 'Size',
          spec_consistency: 'Specification Consistency',
          status: 'Status',
          select_step: 'Select step',
          step_alias: {
            qc: 'Supplier QC',
            in_transit: 'Loaded: In transit',
            NA: 'NA',
            qa_intake: 'Received: QA Intake',
            qc_review: 'Decision',
            revision: 'Received: Revision',
            finalized: 'Finalized',
          },
          step_alias_short: {
            qc: 'Supplier QC',
            finalized: 'F',
            in_transit: 'IT',
            NA: 'NA',
            qa_intake: 'Received: QA Intake',
            // TODO: Change because to QCR
            qc_review: 'Decision',
            revision: 'R',
          },
          sublabel: 'Sublabel',
          summary: 'Summary',
          supplier_accuracy_tooltip:
            'Supplier accuracy is based on the average difference between the supplier\'s forecast and your measurements on average.',
          supplier_quality_input: 'Quality input',
          spec_tolerance: 'Spec tolerance',
          temperature_data: 'Temperature data',
          transport: 'Transport',
          temperature_summaries: {
            transport_temperature: 'Transit information',
            transport_temperature_description:
              'Monitor the maintained sensor data inside the transport vehicle, such as temperature, light.',
            ranges:
              'Between {{ startDate }} and {{ endDate }}, the temperature ranged between {{ minTemp }} and {{ maxTemp }} with an average of {{ avgTemp }}.',
            data_points:
              'Out of {{ count }} data points, {{ issueCount }} were identified as a potential issue.',
            last_synced_at:
              'Last synced at {{ lastSyncedAt }} using {{ source }}.',

            temperature_issue:
              'We have identified {{ issueCount }} potential issues with the temperature.',
          },
          data_streams: {
            id_TRANSPORT_TEMPERATURE: 'TRANSPORT_TEMPERATURE',
            id_LOCATION: 'LOCATION',
            id_WEATHER: 'WEATHER',
            type_TRANSPORT_TEMPERATURE: 'Transit information',
            type_LOCATION: 'Transport route',
            type_WEATHER: 'Harvest weather',
          },
          transports_summaries: {
            current_eta: 'The shipment is expected to arrive on {{ eta }}.',
            current_eta_with_location:
              'The shipment is expected to arrive on {{ eta }} at {{ destination }}.',
            no_eta_with_location:
              'The shipment is expected to arrive at {{ destination }}, but estimate time is unknown.',
            no_eta:
              'The shipment has no estimated time of arrival or location.',
            light_description: '',

            delay_in_days:
              'The shipment has been delayed by {{ delayInDays }} days.',
            delay_in_hours:
              'The shipment has been delayed by {{ delayInHours }} hours.',
            delay_in_days_with_location:
              'The shipment has been delayed by {{ delayInDays }} days at {{ location }}.',
            delay_in_hours_with_location:
              'The shipment has been delayed by {{ delayInHours }} hours at {{ location }}.',
            last_synced_at:
              'Last synced at {{ lastSyncedAt }} using {{ source }}.',

            arrived_at:
              'The shipment arrived at {{ location }} on {{ arrivatedAt }}.',

            delay_issue:
              'A delay has been detected in the route at {{ delayDate }}. This may impact the estimated time of arrival.',
            delay_issue_with_time_delay:
              'A delay of {{ delayInDays }} days has been detected in the route at {{ delayDate }}. This may impact the estimated time of arrival.',
          },
          quality_report_labels: {
            quality: 'Quality out of spec',
            condition: 'Defects',
            quality_out_of_spec: 'Out of spec',
            packaging: 'Packaging out of spec',
            cleanliness: 'Bad cleanliness',
            disease: 'Rots and diseases',
            characteristics: 'Measurements out of spec',
            logistics: 'Logistics issues',
            measurement_issues_count: 'Out of spec',
            forecast_accuracy_issues_count: 'Forecast accuracy',
            temperature_issues_count: 'Transport temperature',
            transport_delay_issues_count: 'Logistics delay',
            light: 'Light issues',
            temp: 'Temperature out-of-bounds',
            temperature: 'Temperature fluctuations',
          },
          transportation_data: 'Transportation data',
          pin_code: 'Pin code',
          type: 'Type',
          upload_photos: 'Upload photos',
          upload_temp_spec: 'Upload temp spec',
          verify_login: 'Verify login',
          verify_login_description:
            'We have sent an email to <0>{{ email }}</0> with a pin code.\n Please enter the pin code below to verify your login.',
          view_reports: 'View reports',
          volume: 'Volume',
          WEEKLY: 'Weekly',
          MONTHLY: 'Monthly',
          QUARTERLY: 'Quarterly',
          qa_input_prompt: 'Orders require your input',
          qa_input_prompt_single: 'Order requires your input',
          qa_input_prompt_single_description:
            'This order has identified that it requires you to upload your quality input.',
          qa_input_required_description:
            'Our system has identified {{ count }} new orders which require you to input quality data.',
          filter_orders: 'Filter orders',
          YEARLY: 'Yearly',
          estimated_location: 'Estimated location',
          view_all_comments: 'View all comments',
          welcome_to_agstacked: 'Welcome to AgStacked',
          welcome_to_agstacked_body:
            'We are excited to have you on board. In order to get you setup with the product, we need to know a little bit more about your organization, such as your name, email address and the name of your organization. \n\nWe have setup a number of fields for you which can you ccheck now, or you can do it later.',
          welcome_to_agstacked_description:
            'Let us you get setup with the product.',
          welcome_to_agstacked_title: 'Welcome to AgStacked',
          mark_as_read: 'Mark as read',
          mark_as_unread: 'Mark as unread',
          mark_all_as_read: 'Mark all as read',
          mark_all_as_unread: 'Mark all as unread',
          delete_all_notifications: 'Delete all notifications',
          delete_all_unread_notifications: 'Delete all unread notifications',
          have_been_found_in_your_orders_between:
            'have been found in your orders between',
          more_actions: 'More actions',
          within_allowed_range: 'Within allowed range',
          outside_allowed_range: 'Outside allowed range',
          quality_inspection: 'Quality inspection',
          quality_inspection_description:
            'Gather meticulous data from quality inspections, offering crucial insights into the state of your produce and adherence to the agreed-upon standards.',
        },
      },
      es: {
        translation: {
          report_by_percentage_tooltip: 'Habilitar para reportar su ingreso de calidad por el porcentaje de la producción que contenía este defecto.',
          report_by_percentage: 'Reportar por porcentaje',
          percentage_defect: 'Porcentaje de defecto',
          percentage_defect_description: '¿Qué porcentaje de la producción tenía este defecto?',
          count_defects: 'Contar defectos',
          count_defects_description: '¿Cuántas de las unidades inspeccionadas (bayas, cajas) tenían este defecto?',
          total_inspected: 'Total inspeccionado',
          total_inspected_description: '¿Cuántas unidades (bayas, cajas) fueron inspeccionadas?',
          average_measurement: 'Medición promedio',
          average_measurement_description: '¿Cuál fue la medición promedio para esta prueba? (Ej. 10mm o 100kg)',
          total_samples_inspected: 'Total de muestras inspeccionadas',
          total_samples_inspected_description: '¿Cuántas unidades (bayas, cajas) fueron inspeccionadas en total?',
          pallet_ref_description:
            'Por favor, localice y proporcione un ID de paleta.',
          all: 'Todo',
          all_description: 'Mostrar todos los pedidos',
          issues_only: 'Con problemas',
          issues_only_description:
            'Mostrar solo los pedidos que tienen un problema',
          input_required: 'Entrada requerida',
          input_required_description:
            'Mostrar solo los pedidos que requieren tu entrada',
          qa_input_prompt: 'Los pedidos requieren su entrada',
          qa_input_required_description:
            'Nuestro sistema ha identificado {{ count }} nuevos pedidos que requieren que introduzca datos de calidad.',
          filter_orders: 'Filtrar pedidos',
          upload_files: 'Cargar archivos',
          upload_files_description:
            'Cargar archivos arrastrándolos y soltándolos en la casilla de abajo, o pulsándola.',
          upload_attachments: 'Cargar adjuntos',
          upload_attachments_description:
            'Cargue archivos adjuntos a sus pedidos para hacer un seguimiento de los documentos importantes, como las fotos tomadas.',
          ascending: 'Ascendente',
          descending: 'Descendente',
          sort_by: 'Ordenar por',
          back_to: 'Volver a',
          events: {
            order_arrived: 'Pedido llegado',
            load_plan_added: 'Plan de carga',
            quality_added: 'Datos QA añadidos',
            quality_updated: 'Actualización de los datos de QA',
            order_status_changed: 'Estado cambiado',
            order_created: 'Pedido creado',
            order_attachment_added: 'Archivos adjuntos añadidos',
            generic: 'Pedido actualizado',
          },
          last_update: 'Última actualización',
          status: 'Estado',
          clear_filters: 'Borrar filtros',
          log_out: 'Cierre de sesión',
          orders: 'Pedidos',
          variety: 'Variedad',
          view_orders: 'Ver los pedidos en curso',
          upload: 'Cargar',
          notifications: 'Notificaciones',
          mark_as_read: 'Marcar como leído',
          mark_as_unread: 'Marcar como no leído',
          mark_all_as_read: 'Marcar todo como leído',
          mark_all_as_unread: 'Marcar todo como no leído',
          delete_all_notifications: 'Borrar todas las notificaciones',
          delete_all_unread_notifications:
            'Borrar todas las notificaciones no leídas',
          have_been_found_in_your_orders_between:
            'se han encontrado en sus pedidos entre',
          more_actions: 'Más acciones',
          empty_states: {
            no_comments: 'Todavía no hay comentarios',
            no_notifications: 'Todavía no hay notificaciones',
            no_results_found: 'No se han encontrado resultados',
          },
          regions: 'Regiones',
          all_regions: 'Todas las regiones',
          history: 'Historia',
          input: 'Entrada',
          created_at: 'Creado en',
          comment: 'Comentario',
          comments: 'Comentarios',
          yes: 'Si',
          no: 'No',
          placeholders: {
            pallet_id: 'ID de paleta',
            enter_email: 'Escriba su correo electrónico',
            write_a_reply: 'Escribir una respuesta...',
            no_fields: 'Sin campos de entrada',
            no_fields_description:
              'Los datos de campos de entrada estarán disponibles una vez que se hayan completado las respectivas inspecciones de campo dentro de nuestros procesos de QA y QC en su sistema ERP. También puede cargar los datos de campo manualmente.',
            no_attachments: 'Cargar adjuntos',
            attachments_data_placeholder:
              'Cargue archivos adjuntos a sus pedidos para hacer un seguimiento de los documentos importantes, como las fotos tomadas.',
          },
          measurement_issue_described:
            '{{fieldName}} tenía una medida de <0></0> que está fuera de la tolerancia de {{target}} {{unit}}.',
          issues_encountered_one: '{{count}} problema detectado',
          issues_encountered_other: '{{count}} problemas detectados',
          no_issues: 'Sin problemas',
          pending: 'Pendiente',
          pending_issues: 'Problemas pendientes',
          n_issues_unresolved_one: '{{count}} problema sin resolver',
          n_issues_unresolved_other: '{{count}} problemas sin resolver',
          n_issues_one: '{{count}} problema',
          n_issues_other: '{{count}} problemas',
          n_issues_found_in_workspace_one:
            '<0>{{count}} problema</0> <1>se ha encontrado en su espacio de trabajo entre</1>',
          n_issues_found_in_workspace_other:
            '<0>{{count}} problemas</0> <1>se han encontrado en su espacio de trabajo entre</1>',
          add_manual_input: 'Añadir entrada manual',
          add_quality_input: 'Añadir entrada de calidad',
          add_quality_input_description:
            'Rellene el siguiente formulario para añadir insumos de calidad para un producto.',
          cancel: 'Cancelar',
          cases: 'Casos',
          create: 'Crear',
          delete: 'Borrar',
          edit_quality_input: 'Editar entrada de calidad',
          edit_quality_input_description:
            'Ajuste los datos siguientes para editar la entrada de calidad de este producto.',
          expected_arrival: 'Llegada prevista',
          order: 'Pedido',
          order_po: 'Pedido de compra',
          n_new_orders_one:
            '<0>{{count}} nuevo pedido</0> <1>se ha añadido a su espacio de trabajo<1/>',
          n_new_orders_other:
            '<0>{{count}} nuevos pedidos</0> <1>se han añadido a su espacio de trabajo</1>',
          save: 'Actualizar',
          add_another_field: 'Añadir otro campo',
          add_a_note: 'Añadir una nota',
          details: 'Detalles',
          delivery_terms: 'Condiciones de entrega',
          delivery_destination: 'Destino de la entrega',
          fields: 'Campos de entrada',
          field: 'Campo',
          produce: 'Producto',
          your_produces_in_use: 'Sus productos en uso',
          produces: 'Productos',
          produce_types: {
            blueberries: 'Arándanos',
            raspberries: 'Frambuesas',
            strawberries: 'Fresas',
            figs: 'Higos',
            avocados: 'Aguacates',
            grapes: 'Uvas',
          },
          spec: 'Especificación',
          spec_tolerance: 'Especificación tolerancia',
          select_field: 'Seleccionar campo',
          search_field: 'Buscar campo',
          supplier: 'Proveedor',
          buyer: 'Comprador',
          quality_fields: 'Campos de calidad',
          count_selected: '{{count}} seleccionado',
          yesses: 'si',
          quality_fields_description:
            'Estos son los campos que se utilizan para determinar la calidad de sus productos. Seleccione los campos que sean relevantes para su producto.',
          quality_input_fields: {
            fruit_size: 'Tamaño de la fruta',
            box_weight: 'Peso de la caja',
            live_pest_infestation: 'Infestación de plagas vivas',
            abnormal_taints_or_odours: 'Manchas u olores anormales',
            foreign_matter: 'Materias extrañas',
            washed_fruit: 'Fruta lavada',
            weight: 'Peso',
            collapse: 'Colapso',
            anthracnose: 'Antracnosis',
            pest_damage_nest: 'Plagas daños nido',
            chill_damage: 'Daños por frío',
            rots_mould: 'Podredumbre/Moho',
            splits: 'Divides',
            button_mould: 'Molde de botones',
            immaturity: 'Inmadurez',
            shrivel: 'Arrugarse',
            soiling: 'Suciedad',
            diameter: 'Diámetro',
            hail_damage: 'Daños por granizo',
            pressure: 'Presión',
            debris: 'Escombros',
            skin_marks: 'Marcas en la piel',
            misshapes: 'Formas irregulares',
            green_or_white_tips: 'Puntas verdes o blancas',
            white_shoulder_or_white_area: 'Hombro blanco o área blanca',
            size: 'Tamaño',
            berry_count_per_punnet: 'Recuento de bayas por envase',
            pack_weight: 'Peso del paquete',
            min_average_brix: 'Brix promedio mínimo',
            color: 'Color',
            volume: 'Volumen',
            bruising: 'Magulladuras',
          },
          login: 'Iniciar sesión',
          remember_me: 'Recuérdame',
          name: 'Nombre',
          general: 'General',
          has_commented_on: 'ha comentado',
          upload_temp_spec: 'Cargar especificación temporal',
          step: 'Fase',
          view_all_comments: 'Ver todos los comentarios',
          settings: 'Ajustes',
          inspection: 'Inspección',
          inspections: 'Inspecciones',
          attachments: 'Adjuntos',
          attachments_description:
            'Cargue archivos adjuntos a sus pedidos para hacer un seguimiento de los documentos importantes, como las fotos tomadas.',
          data_streams: {
            type_TRANSPORT_TEMPERATURE: 'Temperatura de tránsito',
            type_LOCATION: 'Ruta de transporte',
            type_WEATHER: 'Tiempo de cosecha',
          },
          logistics: 'Logística',
          email_address: 'Correo electrónico',
          welcome_to_agstacked: 'Bienvenido a AgStacked',
          login_description:
            'Para acceder a la plataforma, inicie sesión con su correo electrónico.',
          verify_login: 'Verificar el inicio de sesión',
          verify_login_description:
            'Hemos enviado un correo electrónico a <0>{{ email }}</0> con un código pin.\n Introduzca el código pin a continuación para verificar su inicio de sesión.',
          quality_inspection: 'Inspección de calidad',
          quality_inspection_description:
            'Recopile datos meticulosos de las inspecciones de calidad, que ofrecen información crucial sobre el estado de sus productos y el cumplimiento de las normas acordadas.',
          qa_input_required: 'Entrada de QA requerida',
        },
      },
      nl: {
        translation: {
          login: 'Inloggen',
          remember_me: 'Onthoud mij',
        },
      },
    },
  }).then(() => {
    return;
  });

// noinspection JSUnusedGlobalSymbols
export default i18n;

/**
 * Assign 10 tailwind color hexcodes
 */
export const colors = [
  '#fbbf24',
  '#f59e0b',
  '#ef4444',
  '#dc2626',
  '#c53030',
  '#9b2c2c',
  '#7b341e',
  '#6d4b1f',
  '#4d7c0f',
  '#059669',
  '#047857',
  '#065f46',
  '#02804a',
  '#016d40',
  '#047857',
  '#065f46',
];

export const themes = [
  'blue',
  'emerald',
  'yellow',
  'slate',
  'gray',
  'indigo',
  'purple',
  'orange',
  'red',
  'pink',
  'cyan',
  'teal',
  'lime',
  'amber',
  'green',
  'fuchsia',
];

export type DefaultTheme = typeof themes[number];

import { FlagIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import { t } from 'i18next';
import { useState } from 'react';

import { Text } from '@/Text';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { CountryName } from '~/Components/Locations/CountryName';

import { useAction } from '../../hooks/useAction';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '../Command';
import { Label } from '../Label';
import { Separator } from '../Separator';
import { SelectAllCommandItem } from './Components/SelectAllCommandItem';
import { PickerChevron, PickerIcon } from './PickerIcon';
import { ResponsivePicker } from './ResponsivePicker';

type MultiRegionPickerProps =
  | {
      className?: string;
      options: string[];
      selected: string[];
      onChange: (regions: string[]) => void;
      title?: string;
    }
  | {
      className?: string;
      options: string[];
      selected: string[];
      param: string;
      title?: string;
      regionParam?: string;
    };

/**
 * Pick multiple regions
 *
 * There are two ways to use this component:
 * - `onChange` callback (controlled by parent component)
 * - `param` prop which sets a param search query (controlled by router)
 */
export function MultiRegionPicker({
  options,
  selected = [],
  title = 'Compare regions',
  className,
  ...props
}: MultiRegionPickerProps) {
  const { reload } = useAction('', { withParams: true });
  const [open, setOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleChange = (regions: string[]) => {
    if ('param' in props) {
      const param = props.param || 'region_names';
      reload({
        [param]: regions,
      });
    } else {
      props.onChange(regions);
    }
  };

  const removeRegion = (regionValue: string) => {
    const selectedRegions = selected.filter((region) => region !== regionValue);
    handleChange(selectedRegions);
  };

  const addRegion = (regionValue: string) => {
    handleChange([...selected, regionValue]);
  };

  const onSelect = (optionId: string) => {
    const isSelected = selected.includes(optionId);
    if (isSelected || (optionId === 'all' && isAllSelected)) {
      removeRegion(optionId);
    } else {
      addRegion(optionId);
    }
  };

  const reset = () => {
    setIsAllSelected(false);
    handleChange([]);
  };

  return (
    <ResponsivePicker
      title={t('regions')}
      open={open}
      onOpenChange={setOpen}
      Trigger={
        <Button
          variant="white"
          size="md"
          className={cn(className, 'flex items-center h-full')}
        >
          <PickerIcon as={FlagIcon} />

          {title}

          <PickerChevron className="ml-auto" />

          {selected?.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2" />
              <Label className="px-1 font-normal rounded-sm lg:hidden">
                {selected.length}
              </Label>
              <div className="hidden space-x-1 lg:flex">
                {selected.length > 2 ? (
                  <Label className="px-1 font-normal rounded-sm">
                    {t('count_selected', { count: selected.length })}
                  </Label>
                ) : (
                  options
                    .filter((option) => selected.includes(option))
                    .map((option) => (
                      <Label
                        key={option}
                        className="px-1 font-normal rounded-sm"
                      >
                        <Step
                          option={{
                            icon: <CountryFlag countryCode={option} />,
                            labelKey: option,
                          }}
                        />
                      </Label>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      }
    >
      <Command>
        <CommandInput placeholder={title} />
        <CommandList>
          <CommandEmpty>{t('no_results_found')}</CommandEmpty>
          <SelectAllCommandItem
            isAllSelected={isAllSelected}
            onSetIsAllSelected={setIsAllSelected}
            onSelect={onSelect}
          />
          <CommandSeparator />
          <CommandGroup heading={t('all_regions')}>
            {options.map((option) => {
              const isSelected = selected.includes(option);
              return (
                <CommandItem key={option} onSelect={() => onSelect(option)}>
                  <div
                    className={cn(
                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                      isSelected
                        ? 'bg-primary text-primary-foreground'
                        : 'opacity-50 [&_svg]:invisible'
                    )}
                  >
                    <CheckIcon className={cn('h-4 w-4')} />
                  </div>
                  <Step
                    option={{
                      icon: <CountryFlag countryCode={option} />,
                      labelKey: option,
                    }}
                  />
                </CommandItem>
              );
            })}
          </CommandGroup>

          {
            <>
              <CommandSeparator />
              <CommandGroup>
                <CommandItem
                  onSelect={() => reset()}
                  className="justify-center text-center"
                >
                  {t('clear_filters')}
                </CommandItem>
              </CommandGroup>
            </>
          }
        </CommandList>
      </Command>
    </ResponsivePicker>
  );
}

// TODO: Turn this into re-usable component
const Step = ({
  option,
}: {
  option: { icon: React.ReactNode; labelKey: string };
}) => {
  return (
    <span className="flex items-center">
      <span className="mx-2">{option.icon}</span>
      <Text>
        <CountryName countryCode={option.labelKey.toUpperCase()} />
      </Text>
    </span>
  );
};

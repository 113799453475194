import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { BoxIcon, Cross2Icon } from '@radix-ui/react-icons';
import { LineChart, ScatterChart } from '@tremor/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import TabFilter from '@/Tabs/TabFilter';
import { CardSubtitle, CardTitle, Helper, MutedText, Strong, Text } from '@/Text';
import { OrderPreviewCard } from '~/Components/Orders/OrderPreviewCard';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { useFormat } from '~/hooks/useFormat';
import { usePageProps } from '~/hooks/usePageProps';
import { ClaimPeriodSummary, Organization, PageProps, Produce, SavedReport } from '~/types/types';
import { themes } from '~/utils/colors';

import { ReportsLayout } from '../ReportsLayout';
import { QualityCostsReportLayout } from './QualityCostsReportLayout';

export interface QualityCostsReportPageProps extends PageProps {
  data: {
    claimSummary: {
      startDate: string;
      endDate: string;
      granularity: string;
      summaryPerPeriod: ClaimPeriodSummary[];
    }
    issuesClaimsCorrelations: {
      costs: number;
      issues_count: number;
      count: number;
    }[];
    overallSummary: ClaimPeriodSummary;
    activePeriodSummary: ClaimPeriodSummary;
  }
  report: SavedReport;
  options: {
    produces: Produce[];
    sellers: Organization[];
    reportSellers: Organization[];
  }
  parameters: {
    sellers?: string[];
    produces?: string[];
    activeSupplierId?: string;
  }

}

const CustomTooltip = (props: any) => {
  const { payload, active, label } = props;
  const { formatCurrency } = useFormat();
  const { t } = useTranslation();
  if (!active || !payload) return null;

  const topGroups = payload[0]?.payload.topGroups;

  return (
    <div>
      <Card className="rounded text-default shadow-dropdown min-w-48">
        <CardHeaderContainer size="sm" className="!p-2">
          <div className="flex items-center justify-between gap-2">
            <Text className="!text-tremor-content">
              {label}
            </Text>

            <Strong>
              {formatCurrency(payload[0]?.value)}
            </Strong>
          </div>
        </CardHeaderContainer>
        <CardBody size="sm" className="!p-2">
          {topGroups.length === 0 ? (
            <Helper>
              {t('placeholders.no_data')}
            </Helper>
          ) : (
            <div>
              <div className="flex flex-1 space-x-2.5">
                {/* Byline: make a component out of this */}
                <div
                  className={`flex w-1.5 flex-col bg-${payload[0]?.color}-500 rounded`}
                />
                <div className="w-full">
                  {topGroups.map((group: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center justify-between space-x-6"
                    >
                      <span className="text-tremor-content">{group.name}</span>
                      <span className="font-medium tabular-nums text-tremor-content-emphasis">
                        {formatCurrency(group.total)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter size="sm">
          <MutedText>
            {t('click_chart_for_details')}
          </MutedText>
        </CardFooter>
      </Card>
    </div>
  );
};

const ActiveGroup = ({ group }: any) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();
  return (
    <Card>
      <CardBody>
        <div className="grid grid-cols-[1fr_3fr]">
          <div className="flex flex-col gap-4 p-4 -my-4 -ml-8 border-r rounded-l bg-gray-50">
            {/* Top */}
            <div className="space-y-1">
              <div>
                <Strong>{group.name}</Strong>
                <div>
                  <MutedText>
                    {formatCurrency(group.total)}
                  </MutedText>
                </div>
                <div>
                  <MutedText>
                    {t('n_claims', { count: group.countClaims })}
                  </MutedText>
                </div>
              </div>
            </div>

            {/* bottom  */}
            <div className="w-full mt-1 flex-grow-1">
            </div>
          </div>
          <div>
            <div
              className="px-4 py-2 -mt-4 rounded-tr -mr-8 hidden sm:grid gap-3 sm:grid-cols-[1fr_100px] md:grid-cols-[1fr_100px] bg-gray-50">
              <div>
                <Helper>{t('claim')}</Helper>
              </div>
              <div className="hidden sm:block">
                <Helper>{t('total')}</Helper>
              </div>
            </div>
            <div className="px-4 py-2">
              <div className="w-full space-y-12">
                {group.subGroups.map((subGroup: any, index: number) => (
                  <div className="grid sm:grid-cols-[1fr_100px] md:grid-cols-[1fr_100px] gap-2 w-full" key={index}>
                    {/* Left side: Show the reason and all related orders */}
                    <div>
                      <div className="flex gap-2">
                        <div
                          className={`flex w-1.5 flex-col bg-${themes[index]}-500 rounded`}
                        />
                        <div className="w-full">
                          <div>
                            <Strong>
                              {subGroup.name}
                            </Strong>
                          </div>
                          <div className="mt-4">
                            <Helper>
                              {t('by_orders')}
                            </Helper>
                          </div>
                          <div className="w-full space-y-2">
                            {subGroup.orders.map((order: any, index: number) => (
                              <OrderPreviewCard compact order={order} key={index}/>
                            ))}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div>
                      <Text>
                        {formatCurrency(subGroup.total)}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const QualityCostsReportPage = () => {
  const { t } = useTranslation();
  const {
    data,
  } = usePageProps<QualityCostsReportPageProps>();
  const { getFormat, parseOrFormat } = useDate();
  const { formatCurrency } = useFormat();
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(null);
  const tabItems = [t('seller'), t('reason')];

  const { reload, loading } = useAction('');

  const handleActiveFilterIndexChange = (newIndex: any) => {
    setActiveFilterIndex(newIndex);

    const tabItem = tabItems[newIndex].toLowerCase();

    reload({
      groupBy: tabItem,
    });
  };

  const handleActiveStepChange = (value: any) => {
    setActiveStep(value);

    const startDate = value?.startDate ?? null;
    const endDate = value?.endDate ?? null;

    reload({
      activeStartDate: startDate,
      activeEndDate: endDate,
    });
  };

  const granularity = data.claimSummary?.granularity;

  const format = getFormat(granularity);

  const costsPerPeriod = (data.claimSummary?.summaryPerPeriod || []).map((period) => ({
    startDate: period.startDate,
    endDate: period.endDate,
    date: parseOrFormat(period.startDate, format),
    Cost: period.total,
    topGroups: period.topGroups,
  }));

  if (data.overallSummary.total === 0) {
    return (
      <PlaceholderBox title={t('placeholders.no_data')} description={t('placeholders.quality_costs_placeholder')}/>
    );
  }

  return (
    <div className="space-y-4">
      <div className="space-y-12">
        <div>
          <div className="mb-4">
            <CardTitle>
              {t('reports.quality_costs_over_time')}
            </CardTitle>
            <CardSubtitle>
              {t('reports.quality_costs_over_time_description')}
            </CardSubtitle>
          </div>

          <div className="relative">
            <Card>
              <CardBody>
                <div className="flex justify-between gap-4">
                  <div>
                    <div>
                      <Helper>
                        {t('reports.total_costs')}
                      </Helper>
                    </div>
                    <Strong>
                      {formatCurrency(data.overallSummary.total)}
                    </Strong>
                    <div>
                      <MutedText>
                        based on {data.overallSummary.countClaims} claims
                        between {parseOrFormat(data.overallSummary.startDate, format)} and {parseOrFormat(data.overallSummary.endDate, format)}
                      </MutedText>
                    </div>
                  </div>

                  <div>
                    <TabFilter
                      initialIndex={
                        activeFilterIndex
                      }
                      onChange={(index) => {
                        handleActiveFilterIndexChange(index);
                      }}
                      items={tabItems.map((mode) => mode)}
                    />
                  </div>
                </div>

                <LineChart
                  className="w-full h-72"
                  data={costsPerPeriod}
                  tickGap={0}
                  yAxisWidth={100}
                  categories={['Cost']}
                  index="date"
                  valueFormatter={(value) => formatCurrency(value)}
                  onValueChange={(value) => handleActiveStepChange(value)}
                  customTooltip={CustomTooltip}
                />
              </CardBody>
              {(!loading && (activeStep && data.activePeriodSummary)) && (
                <div>
                  <div className={'border-t border-dashed border-gray-300 h-1'}/>

                  <CardBody>
                    <div className="mb-4">
                      <CardTitle>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/*@ts-ignore*/}
                        {t('reports.period_of', { period: `${parseOrFormat(activeStep.startDate, format)} - ${parseOrFormat(activeStep.endDate, format)}` })}

                      </CardTitle>
                      <CardSubtitle>
                        {t('reports.quality_costs_period_analysis_description')}
                      </CardSubtitle>

                      <div className="mt-2">
                        <Button onClick={() => handleActiveStepChange(null)} variant="outline" size="xs">
                          <Cross2Icon className="w-3 h-3 mr-1"/>
                          {t('close_analysis')}
                        </Button>
                      </div>
                    </div>

                    {data.activePeriodSummary?.topGroups?.length > 0 && (
                      <div className="space-y-2">
                        {data.activePeriodSummary.topGroups.map((group: any, index: number) => (
                          <ActiveGroup key={index} group={group}/>
                        ))}
                      </div>
                    )}

                    {data.activePeriodSummary?.topGroups?.length === 0 && (
                      <PlaceholderBox title={t('placeholders.no_data')}
                        description={t('placeholders.no_data_for_period_description')}
                        icon={<BoxIcon className="w-12 h-12 stroke-gray-400"/>}
                      />
                    )}
                  </CardBody>
                </div>
              )}

              {(loading || !(activeStep && data.activePeriodSummary)) && (
                <div>
                  <div className="h-1 border-t border-gray-300 border-dashed"/>

                  <PlaceholderBox
                    title="Select a date"
                    description="Click on the chart to explore detailed data for a specific date."
                    icon={<CalendarDaysIcon className="w-12 h-12 fill-gray-400"/>}
                    isFlat
                    loading={loading}
                  />
                </div>
              )}
            </Card>
          </div>

          <div className="my-4">
            <CardTitle>
              {t('reports.costs_correlations')}
            </CardTitle>
            <CardSubtitle>
              {t('reports.costs_correlations_description')}
            </CardSubtitle>
          </div>

          <div className="relative">
            <Card>
              <CardBody>
                <ScatterChart
                  category="order_id"
                  data={data.issuesClaimsCorrelations}
                  x="issues_count"
                  maxXValue={20}
                  valueFormatter={{
                    x: (value) => `${value} issues`,
                    y: (value) => formatCurrency(value),
                  }}
                  tickGap={0}
                  y="costs"
                  size="count"
                  sizeRange={[100, 200]}
                  showLegend={false}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

QualityCostsReportPage.layout = (page: React.ReactNode) => <QualityCostsReportLayout>{page}</QualityCostsReportLayout>;

export default QualityCostsReportPage;

/**
 * Instructions for adding a new route:
 * 1. Add a type entry in the relevant route (BuyerRoutes, SellerRoutes or SharedRoutes)
 * 2. Add a function to the relevant route maker (makeBuyerRoutes, makeSellerRoutes or makeSharedRoutes)
 */
import { AppMode, Organization } from '~/types/types';

import { usePageProps } from './usePageProps';

export type WorkspaceType = 'b' | 's';

export const generateWorkspaceRoute = (
  type: WorkspaceType,
  organizationId: string,
  path?: string
) => `/${type}/${organizationId}${path}`;
export const generateBuyersRoute = (organizationId: string, path?: string) =>
  generateWorkspaceRoute('b', organizationId, path);
export const generateSellersRoute = (organizationId: string, path?: string) =>
  generateWorkspaceRoute('s', organizationId, path);
export const generateAdminRoute = (type: WorkspaceType) => `/${type}/admin`;

/**
 * TYPE DEFINITIONS
 */
export interface SharedRoutes {
  login: () => string;
  logout: () => string;
  order: (orderId: string) => string;
  orders: () => string;
  settings: () => string;
  reports: () => string;
  default: () => string;
  refreshDemoData: () => string;
  editQualityInput: (orderId: string) => string;
  deleteQualityInput: (orderId: string) => string;
  editReportName: (reportId: string) => string;
  deleteAttachment: (orderId: string) => string;
  markAsRead: () => string;
  markAsUnread: () => string;
  deleteNotifications: () => string;
}

export interface BuyerRoutes extends SharedRoutes {
  addQualityInput: (orderId: string) => string;
  workspaceSettings: () => string;
  userSettings: () => string;
  createOrganization: () => string;
  verifyTempSpec: (orderId: string) => string;
  addTempSpec: (orderId: string) => string;
  supplier: (supplierId: string) => string;
  issues: () => string;
  resolveIssue: (issueId: string) => string;
  produceSettings: () => string;
  generalOrganizationSettings: () => string;
  usersOrganizationSettings: () => string;
  editField: (fieldId: string) => string;
  default: () => string;
  suppliers: () => string;
  admin: () => string;
  saveSpec: () => string;
  spec: (specId: string) => string;
  saveProduce: () => string;
}

export interface SellerRoutes extends SharedRoutes {
  addQualityInput: (orderId: string) => string;
}

/**
 * Buyer Route Maker
 */
export const makeBuyerRoutes = (organization: Organization): BuyerRoutes => ({
  // Auth
  login: () => '/login',
  logout: () => '/b/logout',

  editReportName: (reportId: string) =>
    `/b/${organization.id}/reports/${reportId}/edit_name`,
  addQualityInput: (orderId: string) =>
    `/b/${organization.id}/orders/${orderId}/quality_input/add`,

  // Temp spec routes
  verifyTempSpec: (orderId: string) =>
    `/b/${organization.id}/orders/${orderId}/temp_specs/verify`,
  addTempSpec: (orderId: string) =>
    `/b/${organization.id}/orders/${orderId}/temp_specs/add`,

  settings: () => generateBuyersRoute(organization.id, '/settings'),
  workspaceSettings: () => `/b/${organization.id}/settings/workspaces`,
  userSettings: () => `/b/${organization.id}/settings/about`,
  refreshDemoData: () => `/b/${organization.id}/admin/refresh_demo_data`,
  supplier: (supplierId: string) =>
    `/b/${organization.id}/suppliers/${supplierId}`,
  produceSettings: () => `/b/${organization.id}/settings/organization/produce`,
  generalOrganizationSettings: () =>
    `/b/${organization.id}/settings/organization/about`,
  usersOrganizationSettings: () =>
    `/b/${organization.id}/settings/organization/users`,
  order: (orderId: string) => `/b/${organization.id}/orders/${orderId}`,
  orders: () => generateBuyersRoute(organization.id, '/orders'),
  issues: () => `/b/${organization.id}/issues`,
  suppliers: () => generateBuyersRoute(organization.id, '/suppliers'),
  reports: () => generateBuyersRoute(organization.id, '/reports'),
  default: () => generateBuyersRoute(organization.id, '/orders'),
  editQualityInput: (orderId: string) =>
    `/b/${organization.id}/orders/${orderId}/quality_input/edit`,
  resolveIssue: (issueId: string) => `/b/${organization.id}/issues/${issueId}/resolve`,

  editField: (fieldId: string) => `/b/${organization.id}/settings/organization/produce/fields/${fieldId}/edit`,

  // Admin
  createOrganization: () => '/b/admin/organizations/create',
  admin: () => generateAdminRoute('b'),
  deleteQualityInput: (orderId: string) =>
    `/s/${organization.id}/orders/${orderId}/quality_input/delete`,
  deleteAttachment: (orderId: string) =>
    `/b/${organization.id}/orders/${orderId}/attachment/delete`,
  markAsRead: () => `/b/${organization.id}/notifications/mark_as_read`,
  markAsUnread: () => `/b/${organization.id}/notifications/mark_as_unread`,
  deleteNotifications: () => `/b/${organization.id}/notifications/delete`,
  spec: (specId: string) => `/b/${organization.id}/settings/organization/specs/${specId}`,
  saveSpec: () => `/b/${organization.id}/settings/organization/specs/save`,
  saveProduce: () => `/b/${organization.id}/settings/organization/produces/save`,
});

/**
 * Seller Route Maker
 */
export const makeSellerRoutes = (organization: Organization): SellerRoutes => ({
  login: () => '/login',
  logout: () => '/s/logout',
  addQualityInput: (orderId: string) =>
    `/s/${organization.id}/orders/${orderId}/quality_input/add`,
  order: (orderId: string) => `/s/${organization.id}/orders/${orderId}`,
  orders: () => generateSellersRoute(organization.id, '/orders'),
  settings: () => generateSellersRoute(organization.id, '/settings'),
  reports: () => generateSellersRoute(organization.id, '/reports'),
  default: () => generateSellersRoute(organization.id),
  refreshDemoData: () => `/b/${organization.id}/admin/refresh_demo_data`,
  editQualityInput: (orderId: string) =>
    `/s/${organization.id}/orders/${orderId}/quality_input/edit`,
  deleteQualityInput: (orderId: string) =>
    `/s/${organization.id}/orders/${orderId}/quality_input/delete`,
  editReportName: (reportId: string) =>
    `/s/${organization.id}/reports/${reportId}/edit_name`,
  deleteAttachment: (orderId: string) =>
    `/s/${organization.id}/orders/${orderId}/attachment/delete`,
  markAsRead: () => `/s/${organization.id}/notifications/mark_as_read`,
  markAsUnread: () => `/s/${organization.id}/notifications/mark_as_unread`,
  deleteNotifications: () => `/s/${organization.id}/notifications/delete`,
});

export type RouteTypes = BuyerRoutes | SellerRoutes;

type NoRouteProvided<Route, Workspace> = Route extends undefined
  ? Workspace extends undefined
    ? true
    : false
  : false;
type ValidRoute<Route, Workspace> = Route extends RouteTypes
  ? Route
  : Workspace extends 'buyer'
    ? BuyerRoutes
    : SellerRoutes;
type InferredRouteType<Route, Workspace> =
  NoRouteProvided<Route, Workspace> extends true
    ? RouteTypes
    : ValidRoute<Route, Workspace>;

export function useRoutes<
  T extends RouteTypes | undefined = undefined,
  K extends AppMode | undefined = undefined,
>(type?: K): InferredRouteType<T, K> {
  const {
    organization,
    app: { appMode },
  } = usePageProps();

  const sharedRoutes = {
    login: () => '/login',
    logout: () => '/logout',
  };

  if (type) {
    if (type === 'buyer') {
      return {
        ...sharedRoutes,
        ...makeBuyerRoutes(organization),
      } as InferredRouteType<T, K>;
    }

    return {
      ...sharedRoutes,
      ...makeSellerRoutes(organization),
    } as InferredRouteType<T, K>;
  }

  if (appMode === 'buyer') {
    return {
      ...sharedRoutes,
      ...makeBuyerRoutes(organization),
    } as InferredRouteType<T, K>;
  }

  return {
    ...sharedRoutes,
    ...makeSellerRoutes(organization),
  } as InferredRouteType<T, K>;
}

import { router } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/Button';
import { Card, CardBody, CardHeader } from '@/Card';
import { AddIcon, ButtonIcon } from '@/Icon';
import { PageDescription, PageTitle, Strong } from '@/Text';
import { FormatProduceName } from '~/Components/Produce/FormatProduce';
import { ProduceImage } from '~/Components/Produce/ProduceImage';
import { SpecLabel } from '~/Components/Spec/SpecLabel';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { ProducesPageProps } from '~/types/page-props';
import { Produce } from '~/types/types';

import { SettingsHeader } from '../../SettingsHeader';
import SettingsLayout from '../../SettingsLayout';
import SpecInput = App.Domain.Specs.SpecInput;
import { AddProduceModal } from './AddProduceModal';

const ProducesPage = () => {
  const { produces } = usePageProps<ProducesPageProps>();
  const { t } = useTranslation();

  const routes = useRoutes<BuyerRoutes>();

  const { loading, post: saveSpec } = useAction<SpecInput>(routes.saveSpec());

  const handleCreateSpec = async (produce: Produce) => {
    await saveSpec({
      baseInput: {
        name: `${produce.name} Spec`,
        produceName: produce.name,
        produceType: produce.meta_type,
        produceId: produce.id,
      },
    });
  };

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <div className="flex justify-between items-center">
            <div>
              <PageTitle>
                {t('produces')}
              </PageTitle>
              <PageDescription>
                {t('manage_produce_settings')}
              </PageDescription>
            </div>

            <div>
              <AddProduceModal>
                <Button variant="primary" size="sm">
                  <ButtonIcon icon={AddIcon}/>
                  {t('add_produce')}
                </Button>
              </AddProduceModal>

            </div>

          </div>
        </SettingsHeader>
      </div>

      <div className="mt-4">
        <Card isLoading={loading}>
          <CardHeader>
            Produces
          </CardHeader>
          <CardBody>
            <div className="divide-y divide-gray-100">
              {produces.map((produce: Produce) => (
                <div key={produce.id} className="py-4 flex justify-between">
                  {/* Left-hand side */}
                  <div className="flex gap-2 items-center">
                    <div>
                      <ProduceImage produce={produce} className="w-4 h-4"/>
                    </div>
                    <div>
                      <Strong>
                        <FormatProduceName produce={produce}/>
                      </Strong>
                    </div>
                  </div>

                  <div>
                    {produce.spec && (
                      <Button variant="white" size="xs" type="button"
                        onClick={() => router.visit(routes.spec(produce?.spec?.id))}>
                        <SpecLabel spec={produce.spec}/>
                      </Button>
                    )}

                    {!produce.spec && (
                      <Button onClick={() => handleCreateSpec(produce)} variant="ghost">
                        <ButtonIcon icon={AddIcon}/>
                        {t('add_spec')}
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

ProducesPage.layout = (page: React.ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default ProducesPage;

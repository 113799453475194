import { UserGroupIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardFooter, CardHeaderContainer } from '@/Card';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { EmptyTableContainer, TableFooter } from '@/Table';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import { MutedText, Text } from '@/Text';
import { useAction } from '~/hooks/useAction';
import {
  Organization,
  PaginatedItems,
  User,
  UserGroup,
} from '~/types/types';

interface UsersTableProps {
  organization: Organization;
  usersConnection: PaginatedItems<User>;
  availableGroups: UserGroup[];
  readonly?: boolean;
}

export const UserRow = ({
  organization,
  user,
  availableGroups,
  readonly,
}: {
  organization: Organization;
  user: User;
  availableGroups: UserGroup[];
  readonly?: boolean;
}) => {
  const { post: edit } = useAction(
    `/b/${organization.id}/users/${user.id}/edit`
  );
  return (
    <div className="grid grid-cols-[1fr_1fr_1fr] gap-8 items-center justify-between py-4">
      <div>
        <Text>{user.name}</Text>
      </div>

      <div>
        <Text>{user.email}</Text>
      </div>

      <div>
        <GenericPicker
          disabled={readonly}
          autoFocus
          icon={UserGroupIcon}
          title="Group"
          options={availableGroups.map((group) => ({
            label: group.name,
            value: group.id,
          }))}
          placeholder="Select your group"
          emptyMessage="No group selected"
          isMulti={false}
          selected={user.user_groups?.[0]?.id ?? null}
          onChange={(groupId) => {
            edit({ userGroupId: groupId }).then(() => {
            });
          }}
          showClear={true}
        />
      </div>
    </div>
  );
};

export const UsersTable = ({
  organization,
  usersConnection,
  availableGroups,
  readonly,
}: UsersTableProps) => {
  const { t } = useTranslation();
  return (
    <Card>
      <ReponsiveTableContainer>
        <CardHeaderContainer>
          <div className="grid grid-cols-[1fr_1fr_1fr]  gap-8">
            <div>
              <MutedText>Name</MutedText>
            </div>
            <div>
              <MutedText>Email</MutedText>
            </div>
            <div>
              <MutedText>Group</MutedText>
            </div>
          </div>
        </CardHeaderContainer>

        <div>
          <div className="divide-y divide-gray-200">
            <CardContent>
              {usersConnection.data.length > 0 ? usersConnection.data.map((user) => (
                <UserRow
                  readonly={readonly}
                  organization={organization}
                  user={user}
                  key={user.id}
                  availableGroups={availableGroups}
                />
              )) : (
                <EmptyTableContainer placeholder={t('no_users_found')}/>
              )}
            </CardContent>

          </div>
        </div>
      </ReponsiveTableContainer>
      <CardFooter>
        <TableFooter paginatedItems={usersConnection}/>
      </CardFooter>
    </Card>
  );
};

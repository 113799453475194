import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";

import { Button } from "@/Button";
import { Card, CardBody, CardHeader } from "@/Card";
import { AttachmentsIcon, Icon } from "@/Icon";
import { NumericIndicator } from "@/NumericIndicator";
import { CardSubtitle, CardTitle } from "@/Text";
import { Tooltipped } from "@/Tooltip";
import { UploadAttachmentsModal } from "~/Components/Attachments/UploadAttachmentsModal";
import { CommentsPane } from "~/Components/Panes/CommentsPane";
import { usePageProps } from "~/hooks/usePageProps";
import { Attachment, Order } from "~/types/types";
import { useAction } from "~/hooks/useAction";
import { useRoutes } from "~/hooks/useRoutes";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface AttachmentsCardProps {
  attachments: Attachment[];
}

export const AttachmentsCard = ({ attachments }: AttachmentsCardProps) => {
  const { t } = useTranslation();
  const { organization, order } = usePageProps();
  const { deleteAttachment: deleteAttachmentRoute } = useRoutes();

  const { post: deleteAttachment, loading } = useAction(
    deleteAttachmentRoute(order?.id as string)
  );

  return (
    <div id="attachments">
      <Card>
        <CardHeader
          title={t("attachments")}
          description={t("attachments_description")}
          renderIcon={<AttachmentsIcon className="w-6 h-6" />}
        >
          {attachments.length > 0 && (
            <div className="mt-4 space-x-2">
              <UploadAttachmentsModal
                organization={organization}
                order={order as Order}
              >
                <Button className="ml-0 sm:ml-4" variant="white">
                  {t("upload")}
                </Button>
              </UploadAttachmentsModal>
            </div>
          )}
        </CardHeader>

        {attachments.length === 0 && (
          <CardBody className="!py-12">
            <div className="space-y-2">
              <div className="flex justify-center">
                <div className="text-center">
                  <CardTitle>{t("placeholders.no_attachments")}</CardTitle>
                  <CardSubtitle className="max-w-[400px]">
                    {t("placeholders.attachments_data_placeholder")}
                  </CardSubtitle>
                </div>
              </div>
              <div className="flex justify-center">
                <UploadAttachmentsModal
                  organization={organization}
                  order={order as Order}
                >
                  <Button className="ml-4" variant="white" size="sm">
                    {t("upload")}
                  </Button>
                </UploadAttachmentsModal>
              </div>
            </div>
          </CardBody>
        )}

        {attachments.length > 0 && (
          <PhotoProvider>
            <CardBody>
              <ul
                role="list"
                className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 sm:gap-x-6 xl:gap-x-8"
              >
                {attachments.map((attachment) => (
                  <Card key={attachment.id}>
                    {attachment.file && (
                      <li className="relative">
                        <CardBody size="sm">
                          <div className="relative">
                            <div className="group">
                              <PhotoView
                                key={attachment.file.url}
                                src={attachment.file.url}
                              >
                                <div
                                  className={
                                    "block w-full overflow-hidden bg-gray-100 rounded-lg cursor-pointer group aspect-square focus-within:ring-emerald-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                  }
                                >
                                  <img
                                    src={attachment.file.url}
                                    alt=""
                                    className="object-cover w-full h-full pointer-events-none bottom object- group-hover:opacity-75"
                                  />

                                  <button
                                    type="button"
                                    className="absolute inset-0 focus:outline-none"
                                  >
                                    <span className="sr-only">
                                      View details for{" "}
                                      {attachment.file.filename}
                                    </span>
                                  </button>
                                </div>
                              </PhotoView>
                              <button
                                aria-label="delete-attachment-button"
                                type="button"
                                onClick={() => {
                                  deleteAttachment({
                                    attachmentId: attachment.id,
                                  });
                                }}
                                className="absolute hidden group-hover:flex justify-center items-center top-1 right-1 rounded-full bg-white p-0.5 "
                              >
                                {loading ? (
                                  <Icon className="cursor-pointer">
                                    <ArrowPathIcon
                                      className={`${loading && "animate-spin"}`}
                                    />
                                  </Icon>
                                ) : (
                                  <Icon>
                                    <XMarkIcon />
                                  </Icon>
                                )}
                              </button>
                            </div>
                          </div>
                        </CardBody>
                        <CardBody className="bg-gray-50" size="sm">
                          <div className="flex items-center justify-between">
                            <Tooltipped
                              label={
                                attachment.file.filename.split("/")[
                                  attachment.file.filename.split("/").length - 1
                                ]
                              }
                            >
                              <p className="block text-sm font-medium text-gray-900 truncate cursor-default">
                                {attachment.file.filename}
                              </p>
                            </Tooltipped>

                            <div>
                              <CommentsPane
                                Trigger={
                                  <Button
                                    size="sm"
                                    variant="white"
                                    className="flex items-center"
                                  >
                                    <span>Comment</span>
                                    {attachment.comments &&
                                      attachment.comments.length > 0 && (
                                        <span className="ml-1">
                                          <NumericIndicator
                                            brand="red"
                                            size="xs"
                                          >
                                            {attachment.comments?.length}
                                          </NumericIndicator>
                                        </span>
                                      )}
                                  </Button>
                                }
                                comments={attachment.comments || []}
                                commentable={{
                                  id: attachment.id,
                                  type: "Attachment",
                                }}
                                path={`/b/${organization.id}/comments/add`}
                                order={order}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </li>
                    )}
                  </Card>
                ))}
              </ul>
            </CardBody>
          </PhotoProvider>
        )}
      </Card>
    </div>
  );
};

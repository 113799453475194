export const neutralAxisColor = '#E5E7EB';
export const axisClass = 'fill-gray-500';

export const getChartNames = (chartData: any[] = [], ignoreKey: string[]) => {
  const chartNames: string[] = [];

  chartData.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (!ignoreKey.includes(key)) {
        chartNames.push(key);
      }
    });
  });

  // Filter out duplicates
  return chartNames.filter((value, index, self) => self.indexOf(value) === index);
};

export const calcCeiling = (data: any[], ignoreKey = 'name', paddingTopFraction = 0.2) => {
  // Find the max for each month
  const yMax = data.reduce((_, month) => {
    const monthMax = Object.keys(month).filter(key => key !== ignoreKey).reduce((acc, key) => {
      return Math.max(acc, month[key]);
    }, 0);

    return Math.max(0, monthMax);
  });

  return yMax + yMax * paddingTopFraction;
};

interface TimeSeriesDataProps extends Record<string, any> {
  date: string;
  value: any;
  start_date?: string;
  end_date?: string;
}

/**
 * Fuses the time series data into a Recharts parseable format. Fuse on `date` field, and
 * add the `title` as the key for the value. Also adds the `title` as a key for the data.
 *
 * Example:
 * Input: {
 *   timeSeries: [
 *   {
 *     id: '1',
 *     title: 'Title 1',
 *     time_series: [{
 *        date: '2021-01-01',
 *        start_date: '2021-01-01',
 *        end_date: '2021-01-01',
 *        value: 100,
 *        otherValue: 'abc',
 *     }],
 *   },
 *   {
 *     id: '2',
 *     title: 'Title 2',
 *     time_series: [{
 *        date: '2021-01-01',
 *        value: 200,
 *        otherValue: 'def',
 *     }],
 *   },
 *   ]
 * }
 *
 * Output: [
 *  {
 *    timeSeries: [{
 *      'date': '2021-01-01',
 *      'start_date': '2021-01-01',
 *      'end_date': '2021-01-01',
 *      'Title 1': 100,
 *      'Title 2': 200,
 *      'Title 1 data': {
 *        id: '1',
 *        title: 'Title 1',
 *        'otherValue': 'abc'
 *      },
 *      'Title 2 data': {
 *        id: '2',
 *        title: 'Title 2',
 *        'otherValue': 'def'
 *        }
 *    }]
 *  date: '2021-01-01',
 */
export const fuseTimeSeries = (timeSeries: any[]) => {
  const fusedData = timeSeries.reduce((acc, item) => {
    if (!item.time_series) {
      return acc;
    }
    item.time_series.forEach((data: TimeSeriesDataProps) => {
      const date = data.date;
      if (!acc[date]) {
        acc[date] = {};
      }

      acc[date][item.title] = data.value;
      // Exclude from the `data` field the item.time_series fields
      acc[date][`${item.title} data`] = data;

      // Add the rest of the fields if they exist
      if (data['start_date'] && data['end_date']) {
        acc[date]['start_date'] = data['start_date'];
        acc[date]['end_date'] = data['end_date'];
        acc[date]['anomaly'] = data['anomaly'];
      }
    });

    return acc;
  }, {} as any);

  return Object.keys(fusedData).map((key) => ({
    date: key,
    ...fusedData[key],
  }));
};

import { PencilIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@radix-ui/react-icons';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { ChangeEvent, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { w } from 'windstitch';

import { DeleteIcon, Icon, MiniLabelIcon, TrashIcon } from './Icon';

export const Label = w.div(`
  inline-flex items-center text-xs rounded-md ring-1 ring-inset
 `, {
  variants: {
    theme: {
      emerald: 'text-emerald-700 bg-emerald-50 ring-emerald-600/10',
      red: 'text-red-700 bg-red-50 ring-red-600/10',
      gray: 'text-gray-600 bg-gray-50 ring-gray-500/10',
      orange: 'text-orange-600 bg-orange-50 ring-orange-500/10',
      blue: 'text-blue-600 bg-blue-50 ring-blue-500/10',
    },
    size: {
      xs: 'px-2 py-0.5',
      sm: 'px-3 py-1',
      md: 'px-4 py-2',
      lg: 'px-5 py-3',
    },
  },
  defaultVariants: {
    size: 'sm',
    theme: 'gray',
  },
});

type Theme = 'emerald' | 'red' | 'gray' | 'orange' | 'blue';

export const MultiLabel = ({ theme, children, label }: { theme: Theme, children: ReactNode, label: string }) => {
  let classNames = '!rounded-r-none !bg-red-500 text-xs !text-white';

  // Note: Because tailwind doesn't compile the styles dynamically, I need to declare these separately
  if (theme === 'emerald') {
    classNames = '!rounded-r-none !bg-emerald-500 text-xs !text-white';
  }

  if (theme === 'gray') {
    classNames = '!rounded-r-none !bg-gray-500 text-xs !text-white';
  }

  if (theme === 'orange') {
    classNames = '!rounded-r-none !bg-orange-500 text-xs !text-white';
  }

  if (theme === 'red') {
    classNames = '!rounded-r-none !bg-red-500 text-xs !text-white';
  }

  if (theme === 'blue') {
    classNames = '!rounded-r-none !bg-blue-500 text-xs !text-white';
  }

  return (
    <span>
      <Label theme={theme} className={classNames}>
        {label}
      </Label>
      <Label className="rounded-l-none" theme={theme}>
        {children}
      </Label>
    </span>
  );
};

interface MiniLabelProps {
  children?: ReactNode;
  variant?: 'default' | 'add';
  value?: string;
  onChange?: (newValue: string) => void;
  enableEdit?: boolean;
  isEdit?: boolean;
  onSave?: (newContent: string) => void;
  onRemove?: () => void;
  className?: string;
}

const fadeInOutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const miniLabelVariants = cva(
  'inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-normal text-gray-700 ring-1 ring-inset ring-gray-200',
  {
    variants: {
      variant: {
        default: '',
        add: 'border border-dashed border-gray-300 hover:bg-gray-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
export const MiniLabel = ({
  children,
  value,
  onChange,
  onSave,
  enableEdit,
  variant,
  className,
  onRemove,
}: MiniLabelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(value || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value !== undefined) {
      setContent(value);
    } else if (typeof children === 'string') {
      setContent(children);
    }
  }, [value, children]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setContent(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    if (onSave) {
      onSave(content);
    }
  };

  const handleRemove = (e: any) => {
    e.preventDefault();
    if (onRemove) {
      onRemove();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <span
      className={miniLabelVariants({ variant, className })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            type="text"
            value={content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="p-0 max-h-3 block border-0 bg-transparent outline-none text-xs"
          />
          <button type="button" onClick={handleSave} className="ml-1">
            <CheckIcon className="w-3 h-3 text-gray-500"/>
          </button>

          {onRemove && (
            <button type="button" onClick={handleRemove} className="ml-1">
              <MiniLabelIcon icon={TrashIcon}/>
            </button>
          )}
        </>
      ) : (
        <>
          {content || children}
          <AnimatePresence>
            {enableEdit && isHovering && !isEditing && (
              <motion.button
                type="button"
                onClick={handleEdit}
                className="ml-1"
                variants={fadeInOutVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.2 }}
              >
                <PencilIcon className="w-3 h-3 text-gray-400 hover:text-gray-600"/>
              </motion.button>
            )}
          </AnimatePresence>
        </>
      )}
    </span>
  );
};

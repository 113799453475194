import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { router } from '@inertiajs/react';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { CardBody, CardFooter, CardHeaderContainer, NavCard } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { CloseIcon, EditIcon, ForecastAccuracyIcon, Icon, IconBox, QualityCostsIcon, QualityIcon } from '@/Icon';
import { Label } from '@/Label';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { DesktopOnly } from '@/Responsive';
import { SideLabeledField } from '@/SideLabeledField';
import { CardSubtitle, CardTitle, PageTitle, Strong } from '@/Text';
import { CountryName } from '~/Components/Locations/CountryName';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { toast } from '~/hooks/useToast';
import { useTracking } from '~/hooks/useTracking';

import { DashboardLayout } from '../../DashboardLayout';
import CreateReportModal from './CreateReportModal';
import { EditReportModal } from './EditReportModal';
import { ReportsPageProps } from './types';

const ReportsPage = ({ data, organization, filterOptions }: ReportsPageProps) => {
  const { user } = usePageProps();
  const { track } = useTracking();
  const { t } = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState<{
    isOpen: boolean;
    id: string | null;
  }>({ isOpen: false, id: null });
  const { post: deleteReport } = useAction(
    `/b/${organization.id}/reports/delete`,
    {
      onSuccess: () => {
        toast({
          title: 'Report has been successfully removed',
        });
      },
    }
  );

  const handleDeleteReport = async (reportId: string) => {
    await deleteReport({ report_id: reportId });

    track({
      event: 'delete_report',
      id: reportId,
    });
  };

  return (
    <div>
      <div className="flex flex-col justify-between gap-2 mb-4 md:flex-row md:items-center">
        <div>
          <CardTitle>{t('saved_reports')}</CardTitle>
          <CardSubtitle className="max-w-[70ch]">
            {t('saved_reports_description')}
          </CardSubtitle>
        </div>

        {data.reports.length > 0 && (
          <DesktopOnly className="shrink-0">
            {/* Date picker on modal is not mobile friendly so hide ability on smaller devices */}
            <CreateReportModal filterOptions={filterOptions}>
              <Button>{t('create_report')}</Button>
            </CreateReportModal>
          </DesktopOnly>
        )}
      </div>

      {data.reports.length > 0 ? (
        <>
          <div className="py-2 mb-4">
            <hr/>
          </div>

          <div className="grid grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-4">
            {data.reports.map((report) => (
              <div
                key={report.id}
                className="relative"
                id={`report::${report.id}`}
                aria-label={`Report by the name ${report.name}`}
              >
                <NavCard href={`/b/${organization.id}/reports/${report.id}`}>
                  <CardHeaderContainer className="!border-gray-900/5 bg-gray-50 p-6">
                    <div className="flex items-center gap-x-4">
                      <IconBox>
                        {report.type === 'FORECAST_ACCURACY' && (
                          <ForecastAccuracyIcon className="w-6 h-6 text-gray-500"/>
                        )}

                        {report.type === 'QUALITY' && (
                          <QualityIcon className="w-6 h-6 text-gray-500"/>
                        )}

                        {report.type === 'QUALITY_COSTS' && (
                          <QualityCostsIcon className="w-6 h-6 text-gray-500"/>
                        )}
                      </IconBox>

                      <div>
                        <Strong>{report.name}</Strong>
                      </div>
                    </div>

                  </CardHeaderContainer>
                  <CardBody className="!py-2">
                    <div className="divide-y divide-gray-100">
                      <div className="py-2">
                        <SideLabeledField
                          label="Type"
                          value={t(`reports.type_${report.type}`) as string}
                        />
                      </div>
                      <div className="py-2">
                        <SideLabeledField
                          label="Date"
                          value={t(report.date_range_type) as string}
                        />
                      </div>
                      <div className="py-2">
                        <SideLabeledField
                          label="Suppliers"
                          renderValue={
                            <Label>
                              {!!report.region_countries &&
                                report.region_countries.length > 0 && (
                                <span className="flex flex-wrap space-1">
                                  {report.region_countries?.map((option: string) => (
                                    <CountryName
                                      key={option}
                                      countryCode={option.toUpperCase()}
                                    />
                                  ))}
                                </span>
                              )}

                              {report.organizations &&
                                report.organizations.length > 0 && (
                                <span>
                                  {report.organizations.length} Suppliers
                                </span>
                              )}
                            </Label>
                          }
                        />
                      </div>
                    </div>
                  </CardBody>

                  <div>
                    {!!report.insights && report.insights > 0 && (
                      <CardFooter>
                        <Label theme="red">
                          <Icon>
                            <ExclamationCircleIcon className="w-4 h-4 text-red-500"/>
                          </Icon>
                          {report.insights} new trend detected
                        </Label>
                      </CardFooter>
                    )}
                  </div>
                </NavCard>
                {report.user_id === user.id && (
                  <div className="absolute hidden gap-2 p-2 top-3 right-3 md:flex">
                    <EditReportModal
                      report={report}
                      isOpen={isEditModalOpen.isOpen}
                      setIsOpen={setIsEditModalOpen}
                      sellers={filterOptions.suppliers}
                      regions={filterOptions.regions}
                    >
                      <button
                        onClick={() => {
                          router.get(
                            `/b/${organization.id}/reports?edit_report_id=${report.id}`,
                            undefined,
                            {
                              preserveState: true,
                              onSuccess: () => {
                                setIsEditModalOpen({
                                  isOpen: true,
                                  id: report.id,
                                });
                              },
                            }
                          );
                        }}
                      >
                        <EditIcon className="w-4 h-4 text-gray-400"/>
                      </button>
                    </EditReportModal>

                    <button onClick={() => handleDeleteReport(report.id)}>
                      <CloseIcon className="w-6 h-6 text-gray-400"/>
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <PlaceholderBox title={t('placeholders.no_reports')} description={t('placeholders.no_reports_description')}>
          <CreateReportModal filterOptions={filterOptions}>
            <Button>{t('create_report')}</Button>
          </CreateReportModal>
        </PlaceholderBox>
      )}
    </div>
  );
};

export const Header = () => {
  return (
    <LayoutHeader isSticky>
      <PageTitle>
        {t('reports.reports')}
      </PageTitle>
    </LayoutHeader>
  );
};

// export const Dashboard;

ReportsPage.layout = (page: React.ReactNode) => (
  <DashboardLayout>
    <Header/>
    <LayoutBody>
      {page}
    </LayoutBody>
  </DashboardLayout>
);
export default ReportsPage;

import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import { SectionTitle } from '@/Text';
import { InspectionsCard } from '~/Components/Orders/InspectionsCard';
import { Attachment, OrderFieldSummary, Spec } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { AttachmentsCard } from '../Attachments/AttachmentsCard';
import { useOrder } from '../OrderProvider';

interface OrderInspectionsSectionProps {
  attachments: Attachment[];
  orderFieldSummaries: OrderFieldSummary[];
  activeSpec?: Spec;
  activeOrderFieldSummary?: OrderFieldSummary;
  isSeller?: boolean;
}

export const OrderInspectionsSection = ({
  attachments,
  orderFieldSummaries = [],
  activeSpec,
  activeOrderFieldSummary,
  isSeller,
}: OrderInspectionsSectionProps) => {
  const { t } = useTranslation();
  const { orderProduces, order, inspectionIssues } = useOrder();

  if (!orderProduces.length) {
    return null;
  }

  const orderProduce = orderProduces[0];

  return (
    <div className="space-y-4" id="inspections">
      <div className="flex justify-between">
        <SectionTitle>
          {t('inspections')}
        </SectionTitle>

        <div className="mt-2">
          <QualityLabel issues={inspectionIssues} label="Inspections"/>
        </div>
      </div>

      <WithAwaitingFallback>
        <InspectionsCard
          order={order}
          orderProduce={orderProduce}
          orderFieldSummaries={orderFieldSummaries}
          activeOrderFieldSummary={activeOrderFieldSummary}
          activeSpec={activeSpec}
          isSeller={isSeller}
        />
        <AttachmentsCard attachments={attachments}/>
      </WithAwaitingFallback>
    </div>
  );
};

import { Link, usePage } from '@inertiajs/react';
import { Bell } from 'lucide-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/Avatar';
import { Button } from '@/Button';
import { ColorDot } from '@/ColorDot';
import { Container } from '@/Container';
import { Icon } from '@/Icon';
import { Label } from '@/Label';
import { LabeledField } from '@/LabeledField';
import { NotificationsPane } from '~/Components/Panes/NotificationsPane';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { generateAdminRoute, useRoutes } from '~/hooks/useRoutes';
import { PageProps } from '~/types/types';

import { NavLink } from './NavLink';
import { UserMenu, UserMenuAvatar } from './UserMenu';

type Optional<T> = T | null | undefined;

export type OptionalNavRoute = Optional<string>;

type NavRoutes = {
  defaultRoute: string;
  ordersRoute: string;
  suppliersRoute?: OptionalNavRoute;
  reportsRoute?: OptionalNavRoute;
  issuesRoute?: string;
  adminRoute: string;
  settingsRoute?: OptionalNavRoute;
  logoutRoute: string;
  refreshDemoDataRoute?: OptionalNavRoute;
};

export const Nav = () => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const {
    app: { appMode },
  } = usePageProps();

  return appMode === 'buyer' ? (
    <BuyerNav
      mobileNavIsOpen={mobileNavIsOpen}
      setMobileNavIsOpen={setMobileNavIsOpen}
      closeMobileNav={() => setMobileNavIsOpen(false)}
    />
  ) : (
    <SellerNav
      mobileNavIsOpen={mobileNavIsOpen}
      setMobileNavIsOpen={setMobileNavIsOpen}
      closeMobileNav={() => setMobileNavIsOpen(false)}
    />
  );
};

interface ChildNavProps {
  mobileNavIsOpen: boolean;
  setMobileNavIsOpen: Dispatch<SetStateAction<boolean>>;
  closeMobileNav: () => void;
}

const BuyerNav = ({
  closeMobileNav,
  mobileNavIsOpen,
  setMobileNavIsOpen,
}: ChildNavProps) => {
  const routes = useRoutes('buyer');

  const ordersRoute = routes.orders();
  const suppliersRoute = routes.suppliers();
  const reportsRoute = routes.reports();
  const adminRoute = routes.admin();
  const settingsRoute = routes.settings();
  const logoutRoute = routes.logout();
  const refreshDemoDataRoute = routes.refreshDemoData();

  return (
    <>
      {renderNavContent({
        routes: {
          defaultRoute: ordersRoute,
          ordersRoute,
          issuesRoute: routes.issues(),
          suppliersRoute,
          reportsRoute,
          adminRoute,
          settingsRoute,
          logoutRoute,
          refreshDemoDataRoute,
        },
        mobileNavIsOpen,
        setMobileNavIsOpen,
        closeMobileNav,
      })}
    </>
  );
};

const SellerNav = ({
  closeMobileNav,
  mobileNavIsOpen,
  setMobileNavIsOpen,
}: ChildNavProps) => {
  const routes = useRoutes('seller');

  const ordersRoute = routes.orders();
  const logoutRoute = routes.logout();

  return (
    <>
      {renderNavContent({
        routes: {
          defaultRoute: ordersRoute,
          adminRoute: generateAdminRoute('b'),
          ordersRoute,
          logoutRoute,
        },
        mobileNavIsOpen,
        setMobileNavIsOpen,
        closeMobileNav,
      })}
    </>
  );
};

interface RenderNavContentProps {
  routes: NavRoutes;
  mobileNavIsOpen: boolean;
  setMobileNavIsOpen: Dispatch<SetStateAction<boolean>>;
  closeMobileNav: () => void;
}

export const renderNavContent = ({
  routes: {
    defaultRoute,
    adminRoute,
    ordersRoute,
    reportsRoute,
    settingsRoute,
    suppliersRoute,
    logoutRoute,
  },
  mobileNavIsOpen,
  setMobileNavIsOpen,
  closeMobileNav,
}: RenderNavContentProps) => {
  const {
    url,
    props: { organization, user },
  } = usePage<PageProps>();
  const { t } = useTranslation();
  const { post: logout } = useAction(logoutRoute);

  return (
    <header className="border-b border-gray-100">
      <nav className="bg-main-700">
        <Container>
          <div className="mx-auto">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link href={defaultRoute}>
                    <img
                      className="w-12 h-12"
                      src="/assets/logo.svg"
                      alt="AgStacked"
                    />
                  </Link>
                </div>

                <div className="hidden md:block">
                  <div className="flex items-baseline ml-10 space-x-4">
                    <NavLink
                      href={ordersRoute}
                      isActive={url.startsWith(ordersRoute)}
                    >
                      {t('orders')}
                    </NavLink>
                    {suppliersRoute && (
                      <NavLink
                        href={suppliersRoute}
                        isActive={url.startsWith(suppliersRoute)}
                      >
                        Suppliers
                      </NavLink>
                    )}
                    {reportsRoute && (
                      <NavLink
                        href={reportsRoute}
                        isActive={url.startsWith(reportsRoute)}
                      >
                        Reports
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="flex items-center ml-4 md:ml-6">
                  <div className="relative ml-3">
                    <div className="hidden md:inline-flex items-center gap-2">
                      <NotificationsPane
                        Trigger={
                          <Button
                            variant="ghost"
                            className="hover:bg-transparent !p-0 h-fit"
                            type="button"
                          >
                            <Icon className="w-6 h-6 text-white relative">
                              <Bell/>
                              {user.unread_notifications && user.unread_notifications?.length > 0 && (
                                <div className="bg-white">
                                  <ColorDot
                                    size="sm"
                                    brand="red"
                                    className="absolute bottom-0 right-0"
                                  />
                                </div>
                              )}
                            </Icon>
                          </Button>
                        }
                        notifications={user.notifications ?? []}
                      />

                      <UserMenu
                        logoutRoute={logoutRoute}
                        organization={organization}
                      >
                        <UserMenuAvatar user={user}/>
                      </UserMenu>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex -mr-2 md:hidden">
                <button
                  onClick={() => setMobileNavIsOpen((curr) => !curr)}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-main-200 bg-main-600 hover:bg-main-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-main-600"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="block w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                  <svg
                    className="hidden w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

        </Container>
        {mobileNavIsOpen && (
          <div className="md:hidden" id="mobile-menu">
            <div className="flex flex-col px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                onCloseMobileNav={closeMobileNav}
                href={ordersRoute}
                isActive={url.startsWith(ordersRoute)}
              >
                Quality
              </NavLink>
              {suppliersRoute && (
                <NavLink
                  onCloseMobileNav={closeMobileNav}
                  href={suppliersRoute}
                  isActive={url.startsWith(suppliersRoute)}
                >
                  Suppliers
                </NavLink>
              )}
              {reportsRoute && (
                <NavLink
                  onCloseMobileNav={closeMobileNav}
                  href={reportsRoute}
                  isActive={url.startsWith(reportsRoute)}
                >
                  Reports
                </NavLink>
              )}
            </div>
            <div className="pt-4 pb-3 border-t border-main-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <Avatar imageSrc="" initials={user.name[0]}/>
                </div>
                {user.user_groups && user.user_groups.length > 0 && (
                  <Label className="px-4 py-2">
                    <LabeledField
                      label="Group"
                      renderValue={user.user_groups[0].name}
                    />
                  </Label>
                )}
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {user.name}
                  </div>
                </div>
              </div>

              <div className="flex flex-col px-2 mt-3 space-y-1">
                {user.superadmin && (
                  <>
                    <NavLink
                      onCloseMobileNav={closeMobileNav}
                      href={adminRoute}
                      isActive={url.startsWith(adminRoute)}
                    >
                      Admin Dashboard
                    </NavLink>

                    {organization.plan === 'DEMO' && (
                      <NavLink
                        onCloseMobileNav={closeMobileNav}
                        href={adminRoute}
                        isActive={url.startsWith(adminRoute)}
                      >
                        Generate demo data
                      </NavLink>
                    )}
                  </>
                )}
                {settingsRoute && (
                  <NavLink
                    onCloseMobileNav={closeMobileNav}
                    href={settingsRoute}
                    isActive={url.startsWith(settingsRoute)}
                  >
                    {t('settings')}
                  </NavLink>
                )}

                <button
                  className="block px-3 py-2 text-sm font-medium text-white rounded-md text-start hover:bg-main-500 hover:bg-opacity-75"
                  onClick={() => logout({})}
                >
                  {t('log_out')}
                </button>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

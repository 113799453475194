import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodIssue } from 'zod';

import { ActionbarBlock, ActionbarItem, ActionbarRoot, ActionbarSeparator } from '@/Actionbar';
import { Alert, AlertDescription } from '@/Alert';
import { Button } from '@/Button';
import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { Form } from '@/Form';
import { WithHoverCard } from '@/HoverCard';
import {
  ActionbarIcon,
  AddIcon,
  ButtonIcon,
  DiscardIcon, EditIcon,
  GenericFieldIcon, HelpIcon,
  PlaceholderIcon,
  SaveIcon,
} from '@/Icon';
import { CardTitle } from '@/Text';
import { Field, SaveSpecInput, Spec } from '~/types/types';
import { cn } from '~/utils/cn';

import { formatZodError } from './helpers';
import { makeDefaultField } from './helpers/factories';
import { formatFieldsInputToApi, makeDefaultFieldFormValues } from './helpers/formatters';
import { extractAllFields } from './helpers/helpers';
import { fieldFormSchema, SpecField, SpecFieldsFormValues } from './helpers/schemas';
import { SpecFieldCard } from './SpecFieldCard';

interface SpecFieldsCardProps {
  spec: Spec;
  initialFields: Field[];
  supportedFields: Field[];
  onSave: (input: SaveSpecInput) => Promise<void>;
  onChange?: (fields: Field[]) => void;
}

/**
 * SpecFieldsCard
 *
 * @preconditions
 * - All fields in `initialFields` must be known by the `knownFields` array.
 * @param spec
 * @param initialFields
 * @param knownFields
 * @param onSave
 * @constructor
 */
export const SpecFieldsCard = ({ spec, initialFields, supportedFields, onSave }: SpecFieldsCardProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [validationResult, setValidationResult] = useState<ZodIssue[]>([]);
  const { t } = useTranslation();

  const form = useForm<SpecFieldsFormValues>({
    resolver: zodResolver(fieldFormSchema),
    defaultValues: makeDefaultFieldFormValues(initialFields),
  });

  const { fields: defaultFields, append, remove } = useFieldArray({
    control: form.control,
    name: 'fields',
  });

  const formFields = form.watch('fields') as SpecField[];
  const allFields = extractAllFields(formFields, supportedFields as SpecField[]);

  const addNewField = () => {
    setIsEdit(true);
    append(makeDefaultField());
  };

  const handleSubmit = async () => {
    const values = form.getValues();
    const input = formatFieldsInputToApi(spec, values);
    await onSave(input);
  };

  const handleParseSchema = () => {
    const values = form.getValues();
    const result = fieldFormSchema.safeParse(values);

    if (!result.success) {
      setValidationResult(result.error.issues);
    }
  };

  const hasChanges = JSON.stringify(defaultFields) !== JSON.stringify(initialFields);

  const handleDiscardChanges = useCallback(() => {
    setIsEdit(false);
  }, []);

  const handleHoverCardOpenChange = (open: boolean) => {
    if (open) {
      handleParseSchema();
    }
  };

  return (
    <Form {...form}>
      <Card>
        <CardHeaderContainer className="bg-gray-50">
          <div className="flex justify-between items-center">
            {/* Left */}
            <div>
              <CardTitle>
                {t('fields')}
              </CardTitle>
            </div>

            {/* Right */}
            <div>
              <Button disabled={isEdit as any} size="xs" variant="white" onClick={async () => {
                if (isEdit) {
                  await handleSubmit();
                } else {
                  setIsEdit(true);
                }
              }}>
                <ButtonIcon icon={EditIcon}/>
                {t('edit_fields')}
              </Button>
            </div>
          </div>
        </CardHeaderContainer>
        <CardBody>
          <div className="space-y-2">
            {defaultFields.map((field, index) => (
              <div key={field.id}>
                <SpecFieldCard
                  fieldIndex={index}
                  onRemove={(fieldIndex) => remove(fieldIndex)}
                  knownFields={allFields}
                  spec={spec}
                  isEdit={isEdit}
                />
              </div>
            ))}

            {isEdit && (
              <div>
                <Button onClick={addNewField} size="lg" variant="addGhost"
                  className="w-full text-center flex justify-center">
                  <ButtonIcon icon={AddIcon}/>
                  {t('add_field')}
                </Button>
              </div>
            )}
          </div>

          {defaultFields.length === 0 && (
            <PlaceholderBox
              title={t('no_fields')}
              description={t('no_fields_description')}
              icon={<PlaceholderIcon icon={GenericFieldIcon}/>}
            >
              <Button size="xs" variant="white" onClick={addNewField}>
                <ButtonIcon icon={AddIcon}/>
                {t('add_first_field')}
              </Button>
            </PlaceholderBox>
          )}
        </CardBody>
      </Card>

      <ActionbarRoot show={hasChanges && isEdit}>
        <ActionbarBlock>
          {defaultFields.length}
          {' '}
          fields
        </ActionbarBlock>

        <ActionbarSeparator/>

        <ActionbarBlock>
          <WithHoverCard
            disabled={form.formState.isValid}
            title="Validation results"
            onOpenChange={handleHoverCardOpenChange}
            renderContent={(
              <Alert variant="warning">
                <AlertDescription>
                  {validationResult.map((error, index) => (
                    <div key={index} className="text-sm">
                      {formatZodError(error)}
                    </div>
                  ))}
                </AlertDescription>
              </Alert>
            )}
          >
            <ActionbarIcon
              className={cn('flex !mr-0 !text-red-500', form.formState.isValid ? 'opacity-20 cursor-auto' : 'opacity-100 cursor-pointer')}
              icon={HelpIcon}
            />
          </WithHoverCard>
        </ActionbarBlock>

        <ActionbarSeparator/>

        <ActionbarItem onClick={handleSubmit} disabled={!form.formState.isValid}>
          <ActionbarIcon icon={SaveIcon}/>
          Save spec
        </ActionbarItem>

        <ActionbarSeparator/>

        <ActionbarItem onClick={handleDiscardChanges}>
          <ActionbarIcon icon={DiscardIcon}/>
          Discard changes
        </ActionbarItem>
      </ActionbarRoot>
    </Form>
  );
};

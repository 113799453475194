import { LightBulbIcon } from '@heroicons/react/24/outline';
import {
  AlignHorizontalDistributeCenter,
  AppleIcon,
  ArrowDownAZIcon,
  BadgeAlert,
  BadgeCheck,
  BadgePoundSterling,
  BellRingIcon,
  BuildingIcon,
  CalendarDays,
  Check,
  ChevronsLeftRight,
  ChevronsUpDownIcon,
  CircleHelp,
  CirclePlus,
  ClipboardCopyIcon,
  ClipboardListIcon,
  ClipboardPen,
  ClipboardPlusIcon,
  CloudRain,
  CloudSunRainIcon,
  Contact,
  Container,
  CornerDownRight,
  EarthIcon,
  EarthLock, Eraser,
  ExternalLinkIcon,
  FileQuestion,
  FingerprintIcon,
  GitPullRequestClosed,
  ImagesIcon,
  Languages,
  LayoutDashboardIcon,
  Library,
  ListFilterIcon,
  LockIcon,
  LogOut,
  MessageCircle,
  Pencil,
  PercentIcon,
  Puzzle,
  Receipt,
  RulerIcon, Save,
  Search,
  Settings2,
  ShieldAlertIcon,
  ShieldQuestionIcon,
  SquareCheck,
  SquarePercent,
  Thermometer,
  TractorIcon, Trash,
  TruckIcon,
  User,
  Users,
  WarehouseIcon,
  XIcon,
} from 'lucide-react';
import React, { ReactNode } from 'react';
import { w } from 'windstitch';

import { cn } from '~/utils/cn';

/**
 * Utilize this ButtonIcon as a way to embed Icons inside a button. Formats the icons in a nice way
 *
 * @precondition To be used in buttons
 * @example
 * <Button
 *         disabled={props.issue.status === 'escalated' || props.loading as any}
 *         variant="white" size="xs"
 *       >
 *         <ButtonIcon icon={EscalateIcon} className="stroke-red-500"/>
 *         Escalate
 *       </Button>
 * @param icon
 * @param className
 * @constructor
 */
export const ButtonIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3.5 mr-1.5 stroke-gray-400', className)} as={icon}/>;
};

export const MiniLabelIcon = ({ icon, className }: { icon: any, className?: string }) => {

  return (
    <div className="!w-3">
      <Icon as={icon} className={cn('!w-3 !max-h-3 !h-auto stroke-red-700', className)}/>
      {/*<icon className="w-3 max-h-3 stroke-red-700"/>*/}
      {/*<TransportTemperatureIcon className="w-3 max-h-3 stroke-red-700"/>*/}
    </div>
  );
  // return <Icon className={cn('max-w-3 mr-1.5 text-gray-400', className)} as={icon}/>;
};

export const SimpleIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3 text-gray-400', className)} as={icon}/>;
};

export const ActionbarIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3 mr-2 text-white', className)} as={icon}/>;
};

export const DropdownIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3.5 mr-3 text-gray-400', className)} as={icon}/>;
};
export const IconButtonIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3.5 text-gray-400', className)} as={icon}/>;
};

export const DropdownHeaderIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3 max-h-3 mr-1.5 text-gray-300', className)} as={icon}/>;
};

export const PlaceholderIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-5 text-gray-400', className)} as={icon}/>;
};

export const SimpleCardHeaderIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3 mr-1.5 text-gray-400', className)} as={icon}/>;
};

export const SidebarIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return (
    <Icon
      className={cn('text-gray-500 max-w-5 h-5 mr-3 opacity-90', className)}
      as={icon}
    />
  );
};

/**
 * Utilize this Label as a way to embed Icons inside a label. Formats the icons in a nice way
 *
 * @precondition To be used in buttons
 * @example
 * <Button
 *         disabled={props.issue.status === 'escalated' || props.loading as any}
 *         variant="white" size="xs"
 *       >
 *         <ButtonIcon icon={EscalateIcon} className="stroke-red-500"/>
 *         Escalate
 *       </Button>
 * @param icon
 * @param className
 * @constructor
 */
export const LabelIcon = ({ icon, className }: { icon: any, className?: string }) => {
  return <Icon className={cn('max-w-3.5 mr-1.5 stroke-current', className)} as={icon}/>;
};

export const Icon = w.span(`
  inline-flex items-center font-medium [&>*]:max-w-full [&>*]:h-full
`, {
  variants: {
    default: (enabled: boolean) => (enabled ? 'w-5 h-5 text-slate-400' : ''),
  },
  defaultVariants: {
    default: true,
  },
});

export const QCIcon = ClipboardPlusIcon;

export const DecisionIcon = GitPullRequestClosed;

export const TransitIcon = Container;

export const QAIcon = ClipboardCopyIcon;

export const ThermometerIcon = Thermometer;

export const UmbrellaIcon = CloudRain;

export const ProduceIcon = AppleIcon;

/**
 * Wraps an icon in a box with a border and background color.
 */
export const IconBox = w.div(`
  bg-white rounded shadow-sm ring-1 p-1
`, {
  defaultVariants: {
    theme: 'gray',
  },
  variants: {
    theme: {
      gray: 'text-gray-500 bg-white border-gray-100 ring-gray-200',
    },
  },
});
export const IconedField = ({ icon: IconComponent, children }: { icon: any, children: ReactNode }) => (
  <div className="flex items-center">
    <IconComponent className="flex-shrink-0 w-3 h-3 mr-1 text-gray-400" aria-hidden="true"/>
    <span className="flex">
      {children}
    </span>
  </div>
);

export const TransportTemperatureIcon = ThermometerIcon;
export const LightThresholdIcon = LightBulbIcon;
export const HarvestIcon = CloudSunRainIcon;
export const TransportLocationIcon = TruckIcon;
export const InspectionsIcon = ClipboardPen;
export const AttachmentsIcon = ImagesIcon;
export const MeasurementFieldIcon = RulerIcon;
export const BooleanFieldIcon = SquareCheck;
export const PercentageIcon = PercentIcon;
export const IDIcon = FingerprintIcon;

export const ForecastAccuracyIcon = AlignHorizontalDistributeCenter;

export const QualityCostsIcon = BadgePoundSterling;

export const QualityIcon = BadgeCheck;

export const EscalateIcon = BellRingIcon;

export const MarkAsReadIcon = Check;

export const AcceptIssueIcon = ShieldAlertIcon;

export const OpenIssueIcon = ShieldQuestionIcon;

export const ActivityIcon = Users;

export const SpecFileIcon = ClipboardListIcon;
export const ArrivalSiteIcon = WarehouseIcon;
export const SellerIcon = TractorIcon;
export const RegionIcon = EarthIcon;
export const IssueIcon = BadgeAlert;
export const SearchIcon = Search;
export const CalendarIcon = CalendarDays;
export const CheckIcon = Check;
export const CloseIcon = XIcon;
export const EditIcon = Pencil;
export const UnknownIcon = FileQuestion;
export const FilterIcon = ListFilterIcon;
export const SortIcon = ArrowDownAZIcon;
export const MajorMinorDefectIcon = SquarePercent;
export const BuyerQualityIcon = BadgeCheck;
export const AddIcon = CirclePlus;
export const ApprovedIcon = BadgeCheck;

export const IndentIcon = CornerDownRight;
export const WarningIcon = ShieldAlertIcon;
export const ConstraintIcon = ChevronsLeftRight;
export const DeleteIcon = XIcon;
export const GenericFieldIcon = SpecFileIcon;
export const OrganizationIcon = BuildingIcon;
export const UsersIcon = Users;
export const IntegrationsIcon = Puzzle;
export const UserIcon = User;
export const ContactIcon = Contact;
export const PrivacyIcon = LockIcon;
export const ToSIcon = Receipt;

export const ExternalIcon = ExternalLinkIcon;
export const LogoutIcon = LogOut;
export const LanguageIcon = Languages;
export const AdminIcon = LockIcon;
export const DashboardIcon = Library;
export const SettingsIcon = Settings2;
export const ExpandMenuIcon = ChevronsUpDownIcon;
export const HelpIcon = CircleHelp;
export const FeedbackIcon = MessageCircle;
export const SaveIcon = Save;
export const DiscardIcon = Eraser;
export const QuestionIcon = CircleHelp;
export const TrashIcon = Trash;
export const CommentIcon = MessageCircle;

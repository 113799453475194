import { HandRaisedIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Icon } from '@/Icon';
import { MutedText, Strong } from '@/Text';
import { getIssueBg } from '~/Components/Orders/FormatIssue';
import { IssueActions } from '~/Components/Orders/IssueActions';
import { IssueEvents } from '~/Components/Orders/IssueEvents';
import { OrderIssue } from '~/Components/Orders/OrderIssue';
import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { Issue, IssueStatus } from '~/types/types';
import { cn } from '~/utils/cn';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { QualityLabel } from '../../../pages/Dashboard/QualityLabel';
import { FormatSummarizable } from './FormatSummarizable';

export interface InspectionsIssuesBodyProps {
  issues: Issue[];
}

const InspectionIssueItem = ({ issue }: { issue: Issue }) => {
  const { t } = useTranslation();
  const routes = useRoutes<BuyerRoutes>();
  const { order } = useOrder();
  const { isBuyer } = usePageProps();
  const { user } = usePageProps();

  const { post: resolve, loading } = useAction(routes.resolveIssue(issue.id));

  const handleResolve = async (data: any) => {
    const { status, comment, informSeller } = data;

    let type: string;

    if (status === 'escalated') {
      type = 'mark_as_escalated';
    } else if (status === 'accepted') {
      type = 'mark_as_accepted';
    } else {
      type = 'mark_as_read';
    }

    await resolve({
      type,
      userId: user.id,
      issueId: issue.id,
      buyerId: order.buyer_id,
      comment,
      informSeller,
    });
  };

  return (
    <div key={issue.id} className={cn(
      '-my-2 py-4 px-3 -mx-3 rounded-b',
      getIssueBg(issue.status),
    )}>
      <div>
        <div>
          <QualityLabel issues={[issue]}/>
        </div>
        <div className="mb-2">
          <MutedText>
            <Trans
              i18nKey="measurement_issue_described"
              values={{
                fieldName: t(issue.summarizable?.field.translation_key_ref, {
                  defaultValue: issue.summarizable?.field?.name,
                }),
                target: issue.issueable?.target,
                unit: issue.summarizable?.field?.unit,
              }}
            >
              <Strong className="text-xs"/>
              <FormatSummarizable fieldSummary={issue.summarizable}/>
            </Trans>
          </MutedText>
        </div>
        {isBuyer && (
          <div className="gap-2 flex">
            {issue.status === 'ignored' ? (
              <Button variant="white" size="xs" disabled={true as any}>
                <Icon className="w-4 h-4">
                  <HandRaisedIcon className="w-4 h-4"/>
                </Icon>
                {t('ignored')}
              </Button>
            ) : (
              <IssueActions
                loading={loading}
                onClick={() => handleResolve({ status: 'read', comment: '', informSeller: false })}
                issue={issue} onChange={handleResolve}
              />
            )}
          </div>
        )}
      </div>

      <IssueEvents issue={issue}/>
    </div>
  );
};

const InspectionsIssuesBody = ({ issues }: InspectionsIssuesBodyProps) => {
  const onlyOpenIssues = issues.filter(issue => issue.status !== IssueStatus.Open).length === 0;

  return (
    <div className={cn('divide-y', onlyOpenIssues ? 'divide-red-100' : 'divide-gray-50')}>
      {issues.map((issue) => (
        <InspectionIssueItem issue={issue} key={issue.id}/>
      ))}
    </div>
  );
};

export interface InspectionsIssuesProps {
  issues: Issue[];
  refs: any;
  floatingStyles: any;
}

/**
 *
 */
export const InspectionsIssues = ({
  issues,
  refs,
  floatingStyles,
}: InspectionsIssuesProps) => {
  const { t } = useTranslation();

  const hasHandledAllIssues = issues.filter(issue => issue.status === IssueStatus.Open).length === 0;

  const title = hasHandledAllIssues ? t('all_issues_handled', { count: issues.length }) : t('issues_encountered', { count: issues.length });

  return (
    <OrderIssue
      title={title}
      refs={refs}
      floatingStyles={floatingStyles}
      renderBody={<InspectionsIssuesBody issues={issues}/>}
      issues={issues}
    />
  );
};

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { CubeIcon } from '@heroicons/react/24/outline';
import { router } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { CardBody } from '@/Card';
import { ExpandableCard } from '@/ExpandableCard';
import { Icon } from '@/Icon';
import { Label } from '@/Label';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { Helper, HelperDescription, MutedText, Strong, Text } from '@/Text';
import { FormatCount } from '~/Components/Orders/FormatCount';
import { useInspection } from '~/hooks/useInspection';
import {
  Field,
  FieldConstraint,
  FieldSummary,
  OrderFieldSummary,
  Pallet,
  Spec,
} from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { FieldIcon } from '../Fields/FieldIcon';
import { FormatSummarizable } from './FormatSummarizable';
import { InspectionStatusIcon } from './InspectionStatusIcon';
import { QualityEntriesTable } from './QualityEntriesTable';

interface FieldPreviewProps {
  field: Field;
  fieldSummaries: FieldSummary[];
  constraint?: FieldConstraint;
  isOpen: boolean;
}

export const FieldPreview = ({
  fieldSummaries,
  field,
  constraint,
  isOpen,
}: FieldPreviewProps) => {
  const { t } = useTranslation();

  // TODO: This won't work in Shared folder (since it can only be used in OrderPage), but we'll fix it somehow..
  const { comments } = useOrder();

  const commentsTotal = comments.filter((comment) => {
    return comment.commentable?.field_id === field.id;
  }).length;

  const fieldName = t(field.translation_key_ref as string, {
    defaultValue: field.name,
  });

  return (
    <div className="grid grid-cols-[1fr_3fr]">
      <div className="flex flex-col gap-4 p-4 -my-4 -ml-3 border-r rounded-l bg-gray-50">
        {/* Top */}
        <div className="space-y-1">
          <div className="flex items-center space-x-1">
            <FieldIcon field={field}/>
            <Strong className="sm:hidden">Field: {fieldName}</Strong>
            <Strong className="hidden sm:inline">{fieldName}</Strong>
          </div>
          
          {constraint && (
            <>
              <Helper className="sm:hidden">{t('spec_tolerance')}</Helper>
              <div className="relative">
                <ConstraintsLabel compact fractionToPercentage constraint={constraint} field={field}/>
              </div>
            </>
          )}

          {!constraint && (
            <Label>
              {t('no_spec')}
            </Label>
          )}
          {commentsTotal > 0 && (
            <Label theme="blue">
              {t('n_comments', { count: commentsTotal })}
            </Label>
          )}
        </div>

        {/* bottom  */}
        <div className="w-full mt-1 flex-grow-1">
          <Button variant="outline" size="xs">
            {isOpen ? (
              <ChevronUpIcon className="w-5 h-5"/>
            ) : (
              <ChevronDownIcon className="w-5 h-5"/>
            )}
            {t('details')}
          </Button>
        </div>
      </div>
      <div className="px-4 py-2 -my-4 divide-y divide-gray-100">
        {fieldSummaries.map((fieldSummary) => (
          <div
            key={fieldSummary.id}
            className="py-2"
            aria-label={`FieldSummary ${field.name} for ${
              fieldSummary.inspection?.order_step?.type
            } ${
              fieldSummary?.issues && fieldSummary.issues.length > 0
                ? 'with issues'
                : 'without issues'
            }`}
          >
            <div className="flex justify-between w-full">
              {/* On the left side */}
              <div className="flex mt-1 space-x-1">
                <div className="inline-block leading-none">
                  <InspectionStatusIcon
                    hasIssue={!!fieldSummary?.issues && fieldSummary.issues.length > 0}
                  />
                </div>

                <div className="inline-block">
                  <div className="flex items-center gap-2">
                    <Strong className="block leading-none">
                      {fieldSummary.inspection?.organization?.title}
                    </Strong>
                    <MutedText className="leading-none !text-gray-400">
                      {fieldSummary?.produce_variety?.name}
                    </MutedText>
                  </div>
                  <MutedText>
                    {t(`step_alias_short.${fieldSummary.inspection?.order_step?.type}`)}
                  </MutedText>
                </div>
              </div>

              {/* On the right side */}
              <div>
                <Text className="leading-none">
                  <FormatSummarizable fieldSummary={fieldSummary}/>
                </Text>
                <MutedText className="flex justify-end">
                  <FormatCount count={fieldSummary.summarizable.count_samples}
                    unitType={fieldSummary.summarizable.unit_type}/>
                </MutedText>
              </div>
            </div>
          </div>
        ))}

        {fieldSummaries.length === 1 && (
          <div/>
        )}
      </div>
    </div>
  );
};

interface FieldBodyProps {
  orderFieldSummary: OrderFieldSummary;
  activeSpec: Spec | null;
}

const FieldBody = ({ orderFieldSummary, activeSpec }: FieldBodyProps) => {
  const { t } = useTranslation();
  const { getInspection } = useInspection();

  const fieldSummary = orderFieldSummary.fieldSummaries[0];
  const qualityEntries =
    fieldSummary.field?.quality_entries?.map((entry) => {
      return {
        ...entry,
        orderStepType: getInspection(entry.inspection_id)?.order_step?.type,
      };
    }) || [];
  const unitFieldSummaries =
    orderFieldSummary.fieldSummaries[0]?.field?.unit_field_summaries || [];

  // We derive the unit type from the data.
  // ASSUMPTION: All units are of the same type.
  const mainUnitType = unitFieldSummaries?.[0]?.unitable_type ?? null;

  const handleOpenActiveUnit = (unitable: Pallet) => {
    router.reload({
      data: { activeUnitId: unitable.id },
    });
  };

  return (
    <>
      <CardBody className="bg-gray-50" size="sm">
        <div className="space-y-4">
          <div>
            <div className="flex items-center gap-2">
              <Helper>{t('history')}</Helper>
            </div>

            <hr className="mt-2"/>
          </div>

          {!!mainUnitType && (
            <div>
              <div>
                <Helper>{mainUnitType}</Helper>
              </div>
              <HelperDescription>
                These are the <strong>{unitFieldSummaries.length}</strong>{' '}
                pallets which have been checked for this measurement.
              </HelperDescription>
              <div className="px-4 mt-1 border divide-y rounded border-slate-200 divide-slate-200">
                {unitFieldSummaries.map((unitFieldSummary) => (
                  <div
                    key={unitFieldSummary.id}
                    className="flex items-center py-2 text-gray-200"
                  >
                    <Icon className="mr-2">
                      <CubeIcon/>
                    </Icon>
                    <Text>{unitFieldSummary.unitable.public_ref}</Text>

                    <div className="flex ml-auto mr-4 space-x-2">
                      <div>
                        <Label theme="red">
                          <FormatSummarizable fieldSummary={unitFieldSummary}/>
                        </Label>
                      </div>

                      <div>
                        <Label theme="gray">
                          {unitFieldSummary.summarizable.count_samples} cases
                        </Label>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <Button
                        size="sm"
                        variant="white"
                        onClick={() =>
                          handleOpenActiveUnit(unitFieldSummary.unitable)
                        }
                      >
                        Inspect
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-2">
            <QualityEntriesTable
              activeSpec={activeSpec}
              qualityEntries={qualityEntries}
            />
          </div>
        </div>
      </CardBody>
    </>
  );
};

export interface GenericFieldSummaryProps {
  isOpen: boolean;
  orderFieldSummary: OrderFieldSummary;
  activeSpec: App.Models.Spec | null;
}

/**
 * The GenericFieldSummary component is used to display a summary of a field at a glance. Clicking on the preview will
 * open the full summary.
 * @param orderFieldSummary - An OrderFieldSummary contains multiple summaries (ie per inspection) related to one field per order.
 * @param isOpen - Whether the summary is open or not.
 * @param activeSpec - The active spec for the current field/produce.
 */
export const GenericFieldSummary = ({
  orderFieldSummary,
  isOpen,
  activeSpec,
}: GenericFieldSummaryProps) => {
  const field = orderFieldSummary.field;
  const fieldSummaries = orderFieldSummary.fieldSummaries;
  const relevantConstraint = orderFieldSummary.relevantConstraint;

  const handleOpen = () => {
    if (isOpen) {
      router.reload({
        data: { activeFieldId: null },
      });
    } else {
      router.reload({
        data: { activeFieldId: field.id },
      });
    }
  };

  return (
    <ExpandableCard
      size="sm"
      onManualOpen={() => handleOpen()}
      manualOpen={isOpen}
      preview={
        <FieldPreview
          constraint={relevantConstraint}
          field={field}
          fieldSummaries={fieldSummaries}
          isOpen={isOpen}
        />
      }
      body={
        <FieldBody
          activeSpec={activeSpec}
          orderFieldSummary={orderFieldSummary}
        />
      }
    />
  );
};

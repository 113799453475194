/* eslint-disable react/prop-types */
import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';

import { DebugJSONModal } from '@/JSON';
import { Nav } from '@/Nav/Nav.js';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { useAppTitle } from '~/hooks/useAppTitle.js';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking.js';
import { OrganizationStatus } from '~/types/types';

import { GeneratingModal } from './GeneratingModal.tsx';
import { OnboardingModal } from './OnboardingModal';

interface DashboardLayoutProps {
  children: ReactNode;
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const posthog = usePostHog();
  const props = usePageProps();
  const {
    user,
    organization,
    show_onboard_modal,
    app: { path },
  } = props;

  useAppTitle();
  usePageTracking(path);
  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 [&>*]:w-full">
      <ServerErrorProvider>
        <DebugJSONModal json={props}/>
        <Nav/>
        <FAB/>
        <OnboardingModal
          user={user}
          organization={organization}
          show={show_onboard_modal ?? false}
        />

        {organization.status === OrganizationStatus.GENERATING && (
          <GeneratingModal/>
        )}

        {organization.status === OrganizationStatus.GENERATING ? (
          <ErrorBoundary
            onError={() => {
              console.log('ignore');
            }}
            fallback={<div>Generating new data</div>}
          >
            {children}
          </ErrorBoundary>
        ) : (
          <>{children}</>
        )}
      </ServerErrorProvider>
    </div>
  );
};

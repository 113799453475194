import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardSubtitle, CardTitle } from '@/Text';
import { usePageProps } from '~/hooks/usePageProps';
import { fuseTimeSeries } from '~/utils/charts';

import { QualityTimeSeries } from './Components/QualityTimeSeries';
import { QualityIssuesPageProps } from './helpers';
import { QualityIssuesLayout } from './QualityIssuesLayout';

const QualityIssuesPage = () => {
  const { data } = usePageProps<QualityIssuesPageProps>();
  const { t } = useTranslation();

  const formattedTimeSeries = fuseTimeSeries(data.timeSeries.timeSeries ?? []);

  return (
    <div className="space-y-4">
      <div>
        <CardTitle>
          {t('reports.quality_over_time_series_title')}
        </CardTitle>
        <CardSubtitle>
          {t('reports.quality_over_time_series_description')}
        </CardSubtitle>
      </div>

      <QualityTimeSeries
        deepDive={data.timeSeries.deep_dive}
        suppliers={data.suppliers ?? []}
        timeSeries={formattedTimeSeries}
        categories={data.timeSeries.categories}
      />
    </div>
  );
};

QualityIssuesPage.layout = (page: React.ReactNode) => <QualityIssuesLayout>{page}</QualityIssuesLayout>;

export default QualityIssuesPage;

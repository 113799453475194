import { Tooltipped } from "@/Tooltip";
import { t } from "i18next";
import { Button } from "@/Button";
import { Icon } from "@/Icon";
import { Notification } from "~/types/types";
import { EnvelopeOpenIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

interface MarkNotificationIconButtonProps {
  notification: Notification;
  isLoading: boolean;
  onMarkAsRead: (notificationId: string) => Promise<void>;
  onMarkAsUnread: (notificationId: string) => Promise<void>;
}

export const MarkNotificationIconButton = ({
  notification,
  isLoading,
  onMarkAsRead,
  onMarkAsUnread,
}: MarkNotificationIconButtonProps) => {
  const handleMarkedAsRead = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    await onMarkAsRead(notification.id);
  };

  const handleMarkedAsUnread = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    await onMarkAsUnread(notification.id);
  };

  return (
    <>
      {notification.read_at ? (
        <Tooltipped duration={100} label={t("mark_as_unread")}>
          <Button
            disabled={isLoading}
            variant="white"
            onClick={handleMarkedAsUnread}
            className="flex justify-center items-center !ring-0 !shadow-none rounded-sm !p-1"
          >
            <Icon className="w-4 h-4">
              <EnvelopeIcon />
            </Icon>
          </Button>
        </Tooltipped>
      ) : (
        <Tooltipped duration={100} label={t("mark_as_read")}>
          <Button
            disabled={isLoading}
            variant="white"
            onClick={handleMarkedAsRead}
            className="flex justify-center items-center !ring-0 !shadow-none rounded-sm !p-1"
          >
            <Icon className="w-4 h-4">
              <EnvelopeOpenIcon />
            </Icon>
          </Button>
        </Tooltipped>
      )}
    </>
  );
};

interface AvatarProps {
  imageSrc: string;
  initials: string;
  size?: string;
  alt?: string;
}

export const Avatar = ({ initials }: AvatarProps) => (
  <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white border border-gray-200">
    <span className="text-sm font-medium leading-none text-gray-500">
      {initials}
    </span>
  </span>
);

export default Avatar;

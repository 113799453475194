/* eslint-disable react/display-name */
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import classnames from 'classnames';
import React, { Ref } from 'react';

interface SelectProps {
  options: { value: string; label: string, disabled?: boolean }[];
  placeholder?: string;
  ariaLabel?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  value?: string;
}

// TODO: Extract only necessities out of SelectPrimitive
const Select = ({ options, ariaLabel, placeholder, onChange, value, defaultValue }: SelectProps) => {
  return (
    <SelectPrimitive.Root defaultValue={defaultValue} onValueChange={onChange} value={value}>
      <SelectPrimitive.Trigger
        className="shadow-sm h-full inline-flex items-center justify-center border border-gray-200 rounded-md px-[15px] leading-none gap-[5px] bg-white text-gray-500  data-[placeholder]:text-gray-500 outline-none"
        aria-label={ariaLabel}
      >
        <SelectPrimitive.Value placeholder={placeholder} />
        <SelectPrimitive.Icon>
          <ChevronDownIcon />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Content
        sideOffset={5}
        position="popper"
        className="overflow-hidden w-full max-h-[150px] bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] z-30"
      >
        <SelectPrimitive.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-gray-700 cursor-default">
          <ChevronUpIcon />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport className="p-[5px]">
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </SelectItem>
          ))}
        </SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-gray-700 cursor-default">
          <ChevronDownIcon />
        </SelectPrimitive.ScrollDownButton>
      </SelectPrimitive.Content>
    </SelectPrimitive.Root>
  );
};

interface SelectItemProps {
  children: React.ReactNode;
  className?: string;
  value: string;
  disabled?: boolean;
}

const SelectItem = React.forwardRef(
  ({ children, className, value, disabled }: SelectItemProps, forwardedRef: Ref<HTMLDivElement> | undefined) => {
    return (
      <SelectPrimitive.Item
        className={classnames(
          'leading-none text-gray-500 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-main-700 data-[highlighted]:text-white',
          className
        )}
        disabled={disabled}
        value={value}
        textValue={''}
        ref={forwardedRef}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
        <SelectPrimitive.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  }
);

export default Select;

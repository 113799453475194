import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Modal, ModalTrigger, ModalContent, ModalBody, ModalFooter } from "./Modal";
import { LocalOnly } from "./LocalOnly";

export interface JSONProps {
  json: any;
}

/**
 * View JSON as a preformatted block
 */
export const DebugJSON = ({ json }: JSONProps) => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <pre className="text-xs">
        {JSON.stringify(json, null, 2)}
      </pre>
    </div>
  );
};

/**
 * Render a JSON object in a modal. Useful for debugging. Only available in local environments.
 */
export const DebugJSONModal = ({ json }: JSONProps) => (
  <LocalOnly>
    <Modal>
    <ModalTrigger asChild>
      {/* Floating action button in bottom left */}
      <div className="fixed !w-12 z-100 bottom-4 left-4">
        <button
          type="button"
          className="flex items-center justify-center w-12 h-12 text-white bg-gray-900 rounded-full focus:outline-none"
        >
          <MagnifyingGlassIcon className="h-7 w-7" />
        </button>
      </div>
    </ModalTrigger>
    <ModalContent className="max-w-7xl">
      <ModalBody>
        <DebugJSON json={json} />
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </ModalContent>
    </Modal>
  </LocalOnly>
);

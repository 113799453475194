import { CheckIcon } from '@heroicons/react/24/outline';

import { CommandGroup, CommandItem } from '@/Command';
import { Text } from '@/Text';
import { cn } from '~/utils/cn';

interface SelectAllItemProps {
  onSelect: (value: string) => void;
  isAllSelected: boolean;
  onSetIsAllSelected: (value: boolean) => void;
}

export const SelectAllCommandItem = ({
  isAllSelected,
  onSelect,
  onSetIsAllSelected,
}: SelectAllItemProps) => {
  return (
    <CommandGroup className="bg-command-accent">
      <CommandItem
        key={'all'}
        value={'all'}
        onSelect={() => {
          onSelect('all');
          onSetIsAllSelected(!isAllSelected);
        }}
      >
        <>
          <div
            className={cn(
              'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
              isAllSelected
                ? 'bg-primary text-primary-foreground'
                : 'opacity-50 [&_svg]:invisible'
            )}
          >
            <CheckIcon className={cn('h-4 w-4')} />
          </div>
          <Text>Select all</Text>
        </>
      </CommandItem>
    </CommandGroup>
  );
};

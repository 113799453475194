/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TriangleIcon } from 'lucide-react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/Button';
import { Card, CardBody, CardHeaderContainer, CardSection, CardSectionTitle } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import {
  AddIcon,
  ButtonIcon,
  CloseIcon,
  ConstraintIcon, DeleteIcon, LabelIcon,
  MiniLabelIcon,
  PlaceholderIcon, ProduceIcon,
  SimpleCardHeaderIcon,
  SimpleIcon,
} from '@/Icon';
import { Input, InputGroup } from '@/Input';
import { Label, MiniLabel } from '@/Label';
import { LabeledField } from '@/LabeledField';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { EditConstraintsCard } from '@/Labels/EditConstraintsCard';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { SideLabeledField } from '@/SideLabeledField';
import Switch from '@/Switch';
import { Helper, MutedText, Strong } from '@/Text';
import { Swappable } from '~/Components/General/Swappable';
import { FormatSpecField } from '~/Components/Spec/FormatSpec';
import { Field, Spec } from '~/types/types';
import { delimit } from '~/utils/delimitList';
import { formatPicker, getGroups } from '~/utils/formatPicker';

import { FormatStringList } from '../../../Orders/FormatStringList';
import { makeDefaultConstraint, makeDefaultField } from './helpers/factories';
import { formatFieldModelToForm } from './helpers/formatters';
import { SpecFieldsFormValues } from './helpers/schemas';

interface SpecFieldCardProps {
  spec: Spec;
  knownFields: Field[];
  isEdit: boolean;
  fieldIndex: number;
  onRemove: (fieldIndex: number) => void;
}

export const SpecFieldCard = ({
  spec,
  fieldIndex,
  knownFields,
  isEdit: isEditProp = false,
  onRemove,
}: SpecFieldCardProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<SpecFieldsFormValues>();

  const isEdit = isEditProp;

  const {
    fields: constraints,
    append: appendConstraint,
    remove,
  } = useFieldArray<SpecFieldsFormValues, `fields.${number}.constraints`>({
    control,
    name: `fields.${fieldIndex}.constraints` as `fields.${number}.constraints`,
  });

  const field = useWatch({ control, name: `fields.${fieldIndex}` });

  const fieldsWithCustomFields = knownFields.map((knownField) => ({
    ...knownField,
    subLabel: delimit([knownField.unit, knownField.defect_type, knownField.type]),
    metaLabel: knownField.assigned ? 'Assigned' : undefined,
  }));

  const supportedFieldOptions = formatPicker(fieldsWithCustomFields, 'name', 'name', 'category');
  const groups = getGroups(fieldsWithCustomFields, 'category');

  const addConstraint = () => {
    appendConstraint(makeDefaultConstraint(field));
  };

  /**
   * Whenever we change the field type, we also refresh the constraints of the field.
   *
   * Why do we need to refresh the constraints?
   * - Because the constraints' range type are linked to the field type. If any existing constraints exist, we need to update them.
   */
  const handleChangeFieldType = () => {
    setValue(`fields.${fieldIndex}.constraints`, []);
  };

  /**
   * If a field name is set, check if it's a known field.
   * - If so, we set the base_type, category, description, and metaLabel.
   * - If not, then we set the base_type to 'unknown' and the metaLabel to 'Custom'.
   * @param name
   */
  const handleFieldName = (name: string | null) => {
    const supportedField = fieldsWithCustomFields.find(field => field.name === name);

    if (supportedField) {
      setValue(`fields.${fieldIndex}`, formatFieldModelToForm(supportedField));
      setValue(`fields.${fieldIndex}.constraints`, []);
    } else {
      setValue(`fields.${fieldIndex}`, {
        ...makeDefaultField(),
        ...field,
        name: name ?? '',
        base_type: 'unknown',
        metaLabel: 'Custom',
        constraints: [],
      });
    }
  };

  return (
    <div aria-label={`field-card for ${field.name}`} className="grid grid-cols-[1fr_3fr] rounded">
      <div className="px-4 py-4 border border-gray-100 rounded-l bg-gray-50">
        <div className="gap-2">
          <Swappable
            isEdit={isEdit}
            displayComponent={
              <Strong id={`fieldId::${field.id}`}>
                <FormatSpecField
                  field={field}
                />
              </Strong>
            }
            editComponent={
              <Controller
                name={`fields.${fieldIndex}.name`}
                control={control}
                render={({ field }) => (
                  <GenericPicker
                    value={field.value}
                    onChange={(value) => {
                      handleFieldName(value);
                    }}
                    onCreate={(newValue) => {
                      // Handle the creation of a new field
                      handleFieldName(newValue);
                    }}
                    groups={groups}
                    isMulti={false}
                    placeholder="Select field type"
                    title="Field Type"
                    emptyMessage="No field type selected"
                    showClear={false}
                    options={supportedFieldOptions}
                  />
                )}
              />
            }
          />
          <div className="mt-2">
            <Swappable
              isEdit={isEdit}
              displayComponent={(
                <>
                  {field.defect_type && (
                    <MiniLabel>
                      <MiniLabelIcon icon={TriangleIcon}/>
                      {(t(field.defect_type) as string)}
                    </MiniLabel>
                  )}
                </>
              )}
              editComponent={
                <div>
                  <Button
                    size="xs"
                    variant="white"
                    onClick={() => onRemove(fieldIndex)}
                  >
                    <ButtonIcon icon={DeleteIcon}/>
                    Remove field
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      </div>

      {(
        <div className="px-4 py-2 border border-gray-100">
          {isEdit && (
            <div>
              <div className="-mx-4 -mt-2">
                <CardSection>
                  <CardSectionTitle>
                    {t('settings')}
                  </CardSectionTitle>
                </CardSection>
              </div>
              <div className="pt-4 pb-8">
                <div className="space-y-8">
                  <SideLabeledField
                    label="Unit"
                    columns="wide"
                    uniqueLabel={`unit for ${field.name}`}
                    subLabel="Define the unit of measurement for this field (e.g. kg, cm, etc.)"
                    renderValue={(
                      <div>
                        <Controller
                          name={`fields.${fieldIndex}.unit`}
                          control={control}
                          render={({ field: controlledField }) => (
                            <InputGroup>
                              {/* @ts-ignore */}
                              <Input
                                {...controlledField}
                                placeholder={field.type === 'percentage' ? '%' : 'kg'}
                                type="text"
                                disabled={field.type === 'percentage'}
                                className="w-20 bg-white"
                                id={`unit for ${field.name}`}
                              />
                            </InputGroup>
                          )}
                        />
                      </div>
                    )}
                  />

                  <SideLabeledField
                    label="Field Type"
                    columns="wide"
                    subLabel="Defined the type of data this field will store. Measurements are used for measurable tests (such as weight), while percentages are used for count-based tests (such as bruising)."
                    renderValue={(
                      <div>
                        <Controller
                          name={`fields.${fieldIndex}.type`}
                          control={control}
                          render={({ field }) => (
                            <GenericPicker
                              {...field}
                              onChange={(value) => {
                                field.onChange(value);
                                handleChangeFieldType();
                              }}
                              isMulti={false}
                              placeholder="Select field type"
                              title="Field data type"
                              emptyMessage="No field type selected"
                              showClear={false}
                              options={[
                                {
                                  label: 'Measurement',
                                  subLabel: 'Values that can be measured (e.g. weight, length)',
                                  value: 'measurement',
                                },
                                {
                                  label: 'Percentage',
                                  subLabel: 'Values that can be counted (e.g. bruising, blemishes)',
                                  value: 'percentage',
                                },
                              ]}
                            />
                          )}
                        />
                      </div>
                    )}
                  />

                  <SideLabeledField
                    label="Severity"
                    subLabel="Define the type of defect that will be recorded for this field. This is used to categorize defects and determine their severity."
                    columns="wide"
                    renderValue={(
                      <div>
                        <Controller
                          name={`fields.${fieldIndex}.defect_type`}
                          control={control}
                          render={({ field }) => (
                            <GenericPicker
                              {...field}
                              isMulti={false}
                              placeholder="Select defect type"
                              title="Defect Type"
                              emptyMessage="No defect type selected"
                              showClear={false}
                              options={[
                                {
                                  label: 'Minor',
                                  subLabel: 'A minor defect that does not significantly affect the quality of the produce',
                                  value: 'minor',
                                },
                                {
                                  label: 'Major',
                                  subLabel: 'A major defect that significantly affects the quality of the produce',
                                  value: 'major',
                                },
                                {
                                  label: 'Nil',
                                  subLabel: 'Zero tolerance for this field: any constraint violation will result in a failed inspection',
                                  value: 'nil',
                                },
                              ]}
                            />
                          )}
                        />
                      </div>
                    )}
                  />

                  <SideLabeledField
                    label="Required"
                    columns="wide"
                    subLabel="Ensure this field is required during inspection. This will help ensure data quality and consistency."
                    uniqueLabel={`required for ${field.name}`}
                    renderValue={(
                      <div className="flex justify-end">
                        <Controller
                          name={`fields.${fieldIndex}.required`}
                          control={control}
                          render={({ field: controlledField }) => (
                            <Switch
                              id={`required for ${field.name}`}
                              checked={controlledField.value as boolean}
                              onCheckedChange={controlledField.onChange}
                            />
                          )}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          )}

          <div>
            <div className="-mx-4">
              <CardSection>
                <Helper>
                  Constraints
                </Helper>
              </CardSection>
            </div>
          </div>

          {isEdit && constraints.length === 0 && (
            <div className="py-4">
              <PlaceholderBox
                title="No Constraints Set"
                description="Add constraints to define acceptable ranges or values for this field. Constraints help ensure data quality and consistency."
                icon={<PlaceholderIcon icon={ConstraintIcon}/>}
              >
                <Button
                  size="xs"
                  variant="primary"
                  onClick={addConstraint}
                >
                  <ButtonIcon icon={AddIcon}/>
                  Add a constraint
                </Button>
              </PlaceholderBox>
            </div>
          )}

          {!isEdit && constraints.length === 0 && (
            <div className="py-4">
              <PlaceholderBox
                title="Unconstrained field"
                description="This field has no specifications for any produce set. Add constraints to define acceptable ranges or values for this field."
                icon={<PlaceholderIcon icon={ConstraintIcon}/>}
              />
            </div>
          )}

          {constraints.length > 0 && (
            <div>
              <div>
                {constraints.map((constraint, constraintIndex) => (
                  <div aria-label="constraint container" key={constraint.id} className="py-2">
                    <Card>
                      <CardHeaderContainer size="sm">
                        <div className="flex items-center justify-between gap-2">
                          <div>
                            <SimpleCardHeaderIcon icon={ConstraintIcon}/>
                            <MutedText>
                              Field Constraint {constraintIndex + 1}
                            </MutedText>
                          </div>

                          {isEdit && (
                            <div>
                              <span>
                                <IconButton size="sm" label="Remove constraint" onClick={() => remove(constraintIndex)}>
                                  <SimpleIcon icon={CloseIcon}/>
                                </IconButton>
                              </span>
                            </div>
                          )}
                        </div>
                      </CardHeaderContainer>
                      <CardBody size="sm">
                        <div className="flex items-center justify-between"
                          style={{ display: isEdit ? 'block' : 'flex' }}>
                          <div>
                            <Swappable
                              isEdit={isEdit}
                              displayComponent={(
                                <ConstraintsLabel
                                  fractionToPercentage={false}
                                  constraint={constraint as any}
                                  field={field as any}
                                />
                              )}
                              editComponent={(
                                <div>
                                  <SideLabeledField
                                    columns="wide"
                                    label="Allowed ranges"
                                    subLabel="Define the acceptable ranges for this field"
                                    renderValue={(
                                      <div>
                                        <Controller
                                          name={`fields.${fieldIndex}.constraints.${constraintIndex}`}
                                          control={control}
                                          render={({ field: constraintField }) => (
                                            <EditConstraintsCard
                                              constraint={constraintField.value as any}
                                              field={field as any}
                                              onUpdate={constraintField.onChange}
                                            />
                                          )}
                                        />
                                      </div>
                                    )}/>
                                </div>
                              )}
                            />
                          </div>

                          <div className="flex items-center">
                            <Swappable
                              isEdit={isEdit}
                              displayComponent={(
                                <Label>
                                  <LabelIcon icon={ProduceIcon}/>
                                  {constraint.produce_varieties && constraint.produce_varieties.length === 0
                                    ? 'Applied to all produce'
                                    : (
                                      <FormatStringList strings={constraint.produce_varieties as string[]}/>
                                    )
                                  }
                                </Label>
                              )}
                              editComponent={(
                                <LabeledField
                                  // columns="wide"
                                  label="Applied to"
                                  subLabel="Apply a scope (such as produce varieties) to this constraint."
                                  renderValue={(
                                    <div className="mt-1">
                                      <Controller
                                        name={`fields.${fieldIndex}.constraints.${constraintIndex}.produce_varieties`}
                                        control={control}
                                        render={({ field }) => (
                                          <GenericPicker
                                            value={field.value as string[]}
                                            onChange={field.onChange}
                                            isMulti={true}
                                            placeholder="Select produce varieties"
                                            title="All produce varieties"
                                            emptyMessage="No produce varieties selected"
                                            showClear={true}
                                            options={formatPicker(spec.linked_varieties ?? [], 'public_name', 'public_name')}
                                          />
                                        )}
                                      />
                                    </div>
                                  )}/>
                              )}
                            />
                          </div>

                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </div>

              {isEdit && (
                <Button
                  size="xs"
                  variant="add"
                  onClick={addConstraint}
                >
                  <ButtonIcon icon={AddIcon}/>
                  Add another constraint
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

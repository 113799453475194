/* eslint-disable @typescript-eslint/no-explicit-any */
import { Errors } from '@inertiajs/inertia';
import { router } from '@inertiajs/react';
import { parse } from 'qs';
import { useCallback, useState } from 'react';
import Zod, { z } from 'zod';

import { usePageProps } from './usePageProps';

interface UseActionOptionsProps {
  onSuccess?: (data: any) => void;
  onFinish?: (data: any) => void;
  onError?: (errors?: Errors) => void;
  withParams?: boolean;
  storeLocalStorage?: boolean;
  preserveState?: boolean;
  preserveScroll?: boolean;
  only?: string[];
}

type SchemaLocalStorageOptions = {
  type: 'schema';
  schema: z.Schema;
};

type AllLocalStorageOptions = {
  type: 'all';
};

type LocalStorageOptions = SchemaLocalStorageOptions | AllLocalStorageOptions;

const zodKeys = (schema: Zod.ZodType): string[] => {
  // Adjusted: Signature now uses Zod.ZodType to eliminate null& undefined check
  //  if schema is nullable or optional
  if (schema instanceof Zod.ZodNullable || schema instanceof Zod.ZodOptional) {
    return zodKeys(schema.unwrap());
  }
  // check if schema is an array
  if (schema instanceof Zod.ZodArray) {
    return zodKeys(schema.element);
  }
  // check if schema is an object
  if (schema instanceof Zod.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries<Zod.ZodType>(schema.shape); // Adjusted: Uses Zod.ZodType as generic to remove instanceof check. Since .shape returns ZodRawShape which has Zod.ZodType as type for each key.
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested = zodKeys(value).map((subKey) => `${key}.${subKey}`);
      // return nested keys
      return nested.length ? nested : key;
    });
  }
  // return empty array
  return [];
};

export const useAction = <D = any>(
  url = '',
  {
    onSuccess,
    onError,
    withParams = true,
    preserveState = true,
    preserveScroll = true,
    only = [],
  }: UseActionOptionsProps = {}
) => {
  const {
    app: { path },
  } = usePageProps();
  const [loading, setLoading] = useState(false);

  const post = async (data: Partial<D>) => {
    return router.post(url, data as any, {
      onStart: () => {
        setLoading(true);
      },
      onFinish: () => {
        // onFinish && onFinish(data);
        setLoading(false);
      },
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        setLoading(false);
      },
      onError: (error) => {
        onError && onError(error);
        setLoading(false);
      },
      preserveState,
      preserveScroll,
      only,
    });
  };

  const onlyKeys = only.join(',');

  const resetCurrentPage = useCallback(() => {
    const realOnly = onlyKeys ? onlyKeys.split(',') : [];

    return router.visit('', {
      preserveScroll: true,
      preserveState: true,
      only: realOnly,
      onStart: () => {
        setLoading(true);
      },
      onFinish: () => {
        // onFinish && onFinish(data);
        setLoading(false);
      },
      onSuccess: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }, [onlyKeys]);

  const handleLocalStorage = (path: string, paramsData: object) => {
    const splitPath = path.split('/');
    const page = splitPath[splitPath.length - 1];
    const filteredParams = Object.fromEntries(
      Object.entries(paramsData).filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return !!value;
      })
    );

    if (Object.keys(filteredParams).length === 0) {
      localStorage.removeItem(page);
    } else {
      localStorage.setItem(page, JSON.stringify(filteredParams));
    }
  };

  /**
   * Reloads the current page with new data.
   *
   * If `withParams` is true, it will also ensure that current search params stay in the URL.
   *
   * Note: This is a workaround for a bug in Inertia.js.
   * Link: https://github.com/inertiajs/inertia/issues/65#issuecomment-1682155744
   * The router. reload function is bugged (it only appends to arrays), so we need to `.get` the original path with new data.
   */
  const reload = (
    data: Partial<D>,
    localStorageOptions?: LocalStorageOptions,
    withParamsOverride?: boolean
  ) => {
    let paramsData = data;

    if (withParams) {
      if (
        typeof withParamsOverride === 'undefined' ||
        withParamsOverride?.toString() === 'true'
      ) {
        const params = parse(window.location.search, {
          ignoreQueryPrefix: true,
        });
        paramsData = {
          ...params,
          ...paramsData,
        };

        if (localStorageOptions) {
          // Added schema check to only save params that are in the schema
          // This makes our overviews return to the first page when reloading
          if (localStorageOptions.type === 'schema') {
            const { schema } = localStorageOptions;
            const zKeys = zodKeys(schema);
            paramsData = Object.fromEntries(
              Object.entries(paramsData).filter(([key]) => {
                return zKeys.includes(key);
              })
            ) as any;
          }
          handleLocalStorage(path, paramsData);
        }
      }
    }

    return router.get(
      `/${path}`,
      {
        ...paramsData as any,
      },
      {
        preserveScroll: true,
        onStart: () => {
          setLoading(true);
        },
        onFinish: () => {
          setLoading(false);
        },
        preserveState,
        only,
      },
    );
  };

  return {
    post,
    reload,
    loading,
    resetCurrentPage,
  };
};

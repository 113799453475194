import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardHeader } from '@/Card';
import { Icon, InspectionsIcon } from '@/Icon';
import { CardSubtitle, CardTitle, Helper, Text } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import {
  Order,
  OrderFieldSummary as OrderFieldSummaryType,
  OrderProduce,
  OrderStatus,
  OrderStepType,
  Spec,
} from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { SpecLabel } from '../Spec/SpecLabel';
import { AddQualityInputModal } from './AddQualityInputModal';
import { OrderFieldSummary } from './FieldSummaries/OrderFieldSummary';
import { InspectionsIssues } from './InspectionsIssues';

interface InspectionsCardProps {
  order: Order;
  orderProduce: OrderProduce;
  orderFieldSummaries: OrderFieldSummaryType[];
  filteredStepTypes?: OrderStepType[];
  activeOrderFieldSummary?: OrderFieldSummaryType;
  activeSpec?: Spec | null;
  isSeller?: boolean;
}

interface AddQualityInputButtonProps {
  order: Order;
  activeSpec: Spec;
  disabledCTA: boolean;
}

const AddQualityInputButton = ({
  activeSpec,
  order,
  disabledCTA,
}: AddQualityInputButtonProps) => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { reload, loading } = useAction('', {
    withParams: true,
    preserveState: true,
  });

  const isGenerating = order.status === OrderStatus.GENERATING;

  return (
    <div className="flex items-center gap-2">
      <AddQualityInputModal spec={activeSpec}>
        <Button
          // @ts-ignore
          disabled={disabledCTA || isGenerating}
          variant="white"
          size="sm"
        >
          {order.status === OrderStatus.GENERATING
            ? t('order_generating')
            : // TODO: Unclear what the flow is
            disabledCTA
              ? t('waiting_for_supplier_input')
              : t('add_manual_input')}
        </Button>
      </AddQualityInputModal>
      {order.status === OrderStatus.GENERATING && (
        <>
          <Icon className="cursor-pointer" onClick={() => reload({})}>
            <ArrowPathIcon className={`${loading && 'animate-spin'}`}/>
          </Icon>
          {order.updated_at && (
            <Text className="text-xs">
              {t('order_last_updated')}{' '}
              {format(new Date(order.updated_at), DateFormat.HumanDateTime)}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

/**
 * The InspectionCard summarizes all the inspections for a given order.
 */
export const InspectionsCard = ({
  order,
  orderProduce,
  orderFieldSummaries = [],
  activeOrderFieldSummary,
  filteredStepTypes = [],
  isSeller = false,
}: InspectionsCardProps) => {
  const { t } = useTranslation();
  const { reload } = useAction('', {
    withParams: true,
    preserveState: true,
  });

  // TODO: This is a bit of a hack, but it works for now. (Should not be in a Shared component)
  const { specColorMap, findSpec, inspectionIssues } = useOrder();

  const activeSpec = findSpec(orderProduce?.active_spec?.id);

  const hasSpecs = !!specColorMap && Object.keys(specColorMap).length > 0;
  const allSpecs = Object.entries(specColorMap);

  /**
   * Filter the orderFieldSummaries by the filteredStepTypes.
   */
  const orderFieldSummariesFiltered: OrderFieldSummaryType[] =
    orderFieldSummaries
      .map((orderFieldSummary) => {
        if (filteredStepTypes.length === 0) {
          return orderFieldSummary;
        }

        let fieldSummaries = orderFieldSummary.fieldSummaries;

        fieldSummaries = fieldSummaries.filter((fieldSummary) => {
          if (!fieldSummary.inspection?.order_step?.type) {
            return false;
          }

          return filteredStepTypes.includes(
            fieldSummary.inspection.order_step.type as OrderStepType
          );
        });

        return {
          ...orderFieldSummary,
          fieldSummaries,
        };
      })
      .filter((orderFieldSummary) => {
        return orderFieldSummary.fieldSummaries.length > 0;
      }) || [];

  const openActiveSpec = (specId: string) => reload({ activeSpecId: specId });

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <div id="inspection" ref={refs.setReference}>
      <Card>
        <CardHeader
          renderIcon={<InspectionsIcon className="w-6 h-6"/>}
          title={t('quality_inspection')}
          description={t('quality_inspection_description')}
        >
          {activeSpec && orderFieldSummariesFiltered.length > 0 && (
            <div className="flex justify-between mt-4">
              <AddQualityInputButton
                disabledCTA={false}
                order={order}
                activeSpec={activeSpec}
              />

              {hasSpecs && (
                <div>
                  <div>
                    <Helper>{t('spec')}</Helper>
                  </div>
                  <div className="flex space-x-2">
                    {allSpecs.map(([specId, specColor], index) => (
                      <>
                        <SpecLabel
                          onOpen={openActiveSpec}
                          key={index}
                          spec={findSpec(specId) as Spec}
                          theme={specColor}
                        />
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {inspectionIssues.length > 0 && (
            <div className="mt-4">
              <InspectionsIssues
                issues={inspectionIssues}
                refs={refs}
                floatingStyles={floatingStyles}
              />
            </div>
          )}
        </CardHeader>
        {orderFieldSummariesFiltered.length === 0 && (
          <CardBody className="!py-12">
            <div className="space-y-2">
              <div className="flex justify-center">
                <div className="text-center">
                  <CardTitle>{t('placeholders.no_fields')}</CardTitle>
                  <CardSubtitle>
                    {t('placeholders.no_fields_description')}
                  </CardSubtitle>
                </div>
              </div>
              <div className="flex justify-center">
                {activeSpec && (
                  <AddQualityInputButton
                    disabledCTA={false}
                    order={order}
                    activeSpec={activeSpec}
                  />
                )}
              </div>
            </div>
          </CardBody>
        )}
        {orderFieldSummariesFiltered.length > 0 && (
          <>
            <div
              className="px-4 py-2 hidden sm:grid gap-3 sm:grid-cols-[1fr_250px_1fr_20px] md:grid-cols-[1fr_2fr_1fr_20px] bg-gray-50">
              <div>
                <Helper>{t('field')}</Helper>
              </div>
              <div className="hidden sm:block">
                <Helper>{t('inspections')}</Helper>
              </div>
            </div>
            <div className="px-2 py-2 space-y-2">
              {orderFieldSummariesFiltered.map((orderFieldSummary, index) => (
                <div
                  key={orderFieldSummary.field.id}
                  data-testid={`fields.${index}`}
                  role="row"
                  aria-label={`FieldSummary for ${orderFieldSummary.field.name}`}
                  id={`field::${orderFieldSummary.field.id}`}
                >
                  <OrderFieldSummary
                    isOpen={
                      activeOrderFieldSummary
                        ? activeOrderFieldSummary.field.id ===
                        orderFieldSummary.field.id
                        : false
                    }
                    qcDisabled={false}
                    orderFieldSummary={orderFieldSummary}
                    activeSpec={activeSpec}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

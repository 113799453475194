import { ReactNode } from 'react';

import { PageDescription, PageTitle } from '@/Text';
import { PageProps, PaginatedItems, User, UserGroup } from '~/types/types';

import { usePageProps } from '../../../../Shared/hooks/usePageProps';
import { UsersTable } from '../Organization/Components/UsersTable';
import { SettingsHeader } from '../SettingsHeader';
import SettingsLayout from '../SettingsLayout';

interface UsersSettingsProps extends PageProps {
  users: PaginatedItems<User>;
  groups: UserGroup[];
}

const UsersSettingsPage = () => {
  const { users, organization, groups } = usePageProps<UsersSettingsProps>();

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <PageTitle>
            Users
          </PageTitle>
          <PageDescription>
            Manage your users
          </PageDescription>
        </SettingsHeader>

        <hr />

        <UsersTable readonly organization={organization} availableGroups={groups} usersConnection={users} />
      </div>
    </div>
  );
};
UsersSettingsPage.layout = (page: ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default UsersSettingsPage;

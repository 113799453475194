import { Link } from '@inertiajs/react';

import { Brand, NumericIndicator } from '@/NumericIndicator';
import { cn } from '~/utils/cn';

export interface Tab {
  name: string;
  href: string;
  current: boolean;
  count?: number;
}

export const Tabs = ({ children, className, inCardHeader, ...props }: any) => (
  <div
    className={cn(
      inCardHeader ? 'hidden pt-4 -mb-4 sm:block' : 'hidden sm:block',
      className
    )}
    {...props}
  >
    <div className={cn(!inCardHeader && 'border-gray-200 border-b')}>
      <nav className="flex -mb-px space-x-8 overflow-x-auto" aria-label="Tabs">
        {children}
      </nav>
    </div>
  </div>
);
Tabs.displayName = 'Tabs';

interface TabbyProps {
  tab: {
    name: string;
    href: string;
    current: boolean;
    count?: number;
  };
  onClick?: () => void;
  theme?: 'red' | 'blue' | 'green' | 'yellow';
  className?: string;
  as?: 'a' | 'link' | 'button';
}

export const Tabby = ({ tab, theme = 'red', className, as = 'link', ...props }: TabbyProps) => {
  const commonProps = {
    className: cn(
      tab.current
        ? 'border-indigo-500 text-indigo-600'
        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
      'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
      className
    ),
    'ariaCurrent': tab.current ? 'page' : undefined,
    ...props,
  };

  const content = (
    <>
      {tab.name}
      {tab.count ? (
        <div className="ml-2">
          <NumericIndicator brand={theme as Brand} size="sm">
            {tab.count}
          </NumericIndicator>
        </div>
      ) : null}
    </>
  );

  if (as === 'a') {
    return (
      <a href={tab.href} {...commonProps}>
        {content}
      </a>
    );
  }

  if (as === 'button') {
    return (
      <button {...commonProps}>
        {content}
      </button>
    );
  }

  // Default to <Link />
  return (
    <Link href={tab.href} {...commonProps}>
      {content}
    </Link>
  );
};

Tabby.displayName = 'Tabby';

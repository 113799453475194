import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Link } from '@inertiajs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './Container';

interface LayoutHeaderProps {
  children: ReactNode;
  isSticky?: boolean;
}

/**
 * Used to render the header of a page (the white part basically).
 */
export const LayoutHeader = ({ children, isSticky }: LayoutHeaderProps) => (
  <div
    className={`z-10 py-6 bg-white shadow-sm ${
      isSticky ? 'sticky top-0 z-10 backdrop-blur-sm bg-white/70' : ''
    }`}
    id="sticky-header"
  >
    <Container>{children}</Container>
  </div>
);

/**
 * Used to render the body of a page (the gray part basically).
 */
export const LayoutBody = ({ children }: { children: ReactNode }) => (
  <div className="py-6 pb-16">
    {/* pb-16 is so that the FAB doesn't block footer buttons of last component */}
    <Container>{children}</Container>
  </div>
);

type BreadcrumbLinkType = 'back' | 'link';

type Breadcrumb<K extends BreadcrumbLinkType> = {
  type: K;
};

type BreadcrumbType = BackBreadcrumb | LinkBreadcrumb;

type BackBreadcrumb = Breadcrumb<'back'>;

type LinkBreadcrumb = Breadcrumb<'link'> & {
  name: string;
  href: string;
};

export const Breadcrumbs = ({ backLink }: { backLink: BreadcrumbType }) => {
  const { t } = useTranslation();
  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            {backLink.type === 'link' ? (
              <Link
                href={backLink.href}
                className="flex items-center text-xs font-normal text-gray-500 hover:text-gray-700"
              >
                <ChevronLeftIcon className="inline-block w-3 h-3 mr-2"/>
                <span>
                  {t('back_to')} {backLink.name}
                </span>
              </Link>
            ) : (
              <Link
                href="#"
                onClick={() => window.history.back()}
                className="flex items-center text-xs font-normal text-gray-500 hover:text-gray-700"
              >
                <ChevronLeftIcon className="inline-block w-3 h-3 mr-2"/>
                <span>Go back</span>
              </Link>
            )}
          </div>
        </li>
      </ol>
    </nav>
  );
};

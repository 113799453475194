import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import * as DropdownMenu from '@/DropdownMenu';
import { Icon } from '@/Icon';
import { Helper } from '@/Text';
import { useAction } from '~/hooks/useAction';

interface SortButtonProps {
  children: ReactNode;
  sortKey: string;
  currentSortKey?: string;
  currentSortAscending?: boolean;
}

export const SortButton = ({
  children,
  sortKey,
  currentSortKey,
  currentSortAscending,
}: SortButtonProps) => {
  const { t } = useTranslation();
  const { reload } = useAction('', { withParams: true });
  const isCurrentSortKey = currentSortKey && sortKey === currentSortKey;

  return (
    <DropdownMenu.DropdownRoot>
      <DropdownMenu.DropdownTrigger asChild>
        <Button variant="ghost" className="!px-0">
          <div className="flex items-center space-x-2">
            <Helper>{children}</Helper>

            <Icon className="flex items-center">
              {isCurrentSortKey && !currentSortAscending && (
                <ChevronDownIcon className="w-4 h-4 text-gray-800"/>
              )}
              {isCurrentSortKey && currentSortAscending && (
                <ChevronUpIcon className="w-4 h-4 text-gray-800"/>
              )}

              {!isCurrentSortKey && (
                <ChevronUpDownIcon className="w-4 h-4 opacity-50"/>
              )}
            </Icon>
          </div>
        </Button>
      </DropdownMenu.DropdownTrigger>
      <DropdownMenu.DropdownContent className="min-w-[170px]">
        <DropdownMenu.DropdownLabel>{t('sort_by')}</DropdownMenu.DropdownLabel>
        <DropdownMenu.DropdownSeparator/>
        <DropdownMenu.DropdownItem
          onSelect={() =>
            reload({
              sort_by: sortKey,
              sort_asc: 1,
            })
          }
        >
          <div className="flex items-center">
            {isCurrentSortKey && currentSortAscending && <CheckIcon/>}
          </div>
          {t('ascending')}
        </DropdownMenu.DropdownItem>
        <DropdownMenu.DropdownItem
          onSelect={() =>
            reload({
              sort_by: sortKey,
              sort_asc: 0,
            })
          }
        >
          <div className="flex items-center">
            {isCurrentSortKey && !currentSortAscending && <CheckIcon/>}
          </div>
          {t('descending')}
        </DropdownMenu.DropdownItem>
      </DropdownMenu.DropdownContent>
    </DropdownMenu.DropdownRoot>
  );
};

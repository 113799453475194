import { ColorDot } from "@/ColorDot";
import { useDate } from "~/hooks/useDate";
import { NewOrdersFoundNotification } from "~/types/types";
import { Text } from "@/Text";
import { router } from "@inertiajs/react";
import { FloatingIconToolbar } from "@/FloatingIconToolbar";
import { MarkNotificationIconButton } from "./MarkNotificationIconButton";
import { Trans, useTranslation } from "react-i18next";

interface NewOrdersFoundNotificationProps {
  notification: NewOrdersFoundNotification;
  onClose: () => void;
  onMarkAsRead: (notificationId: string) => Promise<void>;
  onMarkAsUnread: (notificationId: string) => Promise<void>;
  isLoading: boolean;
}

export const NewOrdersFoundNotificationEntry = ({
  notification,
  onClose,
  isLoading,
  onMarkAsRead,
  onMarkAsUnread,
}: NewOrdersFoundNotificationProps) => {
  const { t } = useTranslation();
  const { differenceToNow } = useDate();

  const handleNavigate = () => {
    void onMarkAsRead(notification.id);
    onClose?.();
    router.get(notification.data.redirect_to);
  };

  return (
    <div className="flex flex-col gap-2 border-slate-200">
      <div className="flex items-center gap-3">
        <div
          onClick={handleNavigate}
          className="flex gap-2 mx-2 px-2 py-3 hover:cursor-pointer hover:bg-slate-50 border rounded-sm items-center w-full group relative"
        >
          <div
            className={`flex flex-col ${
              Boolean(notification.read_at) && "opacity-50"
            }`}
          >
            <div className="text-sm flex items-center text-token-text font-medium">
              <span className="inline mr-1 self-start mt-1.5">
                {!notification.read_at && <ColorDot brand="indigo" size="sm" />}
              </span>
              <span className="inline text-gray-700">
                <Trans
                  i18nKey="n_new_orders"
                  count={notification.data.order_count}
                >
                  <strong className="text-gray-700" />
                  <span className="inline text-sm text-gray-700 font-light" />
                </Trans>
                <span className="ml-1 text-xs text-token-muted flex-shrink-0">
                  {differenceToNow(new Date(notification.created_at as string))}
                </span>
              </span>
            </div>
            <div className="flex gap-2 items-center mt-1"></div>
          </div>
          <FloatingIconToolbar>
            <MarkNotificationIconButton
              isLoading={isLoading}
              notification={notification}
              onMarkAsRead={onMarkAsRead}
              onMarkAsUnread={onMarkAsUnread}
            />
          </FloatingIconToolbar>
        </div>
      </div>
    </div>
  );
};

import { ChatBubbleOvalLeftIcon } from '@heroicons/react/16/solid';
import { useTranslation } from 'react-i18next';

import { AcceptIssueIcon, EscalateIcon, Icon, MarkAsReadIcon } from '@/Icon';
import { MutedText } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { Issue } from '~/types/types';

interface IssueEventsProps {
  issue: Issue;
}

export const IssueEvents = ({ issue }: IssueEventsProps) => {
  const { parseOrFormat } = useDate();
  const { t } = useTranslation();
  return (
    <>
      {issue.events && issue.events.length > 0 && (
        <div className="bg-white rounded p-2 mt-2 divide-gray-200 divide-y">
          {issue.events.map(issueEvent => (
            <div key={issueEvent.id}>
              <div className="flex items-center gap-1 py-2">
                {issueEvent.type === 'mark_as_escalated' && (
                  <Icon>
                    <EscalateIcon className="w-4 h-4 fill-red-300"/>
                  </Icon>
                )}

                {issueEvent.type === 'mark_as_accepted' && (
                  <Icon>
                    <AcceptIssueIcon className="w-4 h-4 fill-orange-300"/>
                  </Icon>
                )}

                {issueEvent.type === 'mark_as_read' && (
                  <Icon>
                    <MarkAsReadIcon className="w-4 h-4 fill-green-300"/>
                  </Icon>
                )}

                <MutedText className="!text-gray-400">
                  {t(`issue_actioned.${issueEvent.type}`, { name: issueEvent.user?.name })}
                </MutedText>
              </div>

              {issueEvent.comment ? (
                <div className="p-2 bg-gray-50">
                  <MutedText className="!text-gray-400 flex items-center">
                    <ChatBubbleOvalLeftIcon className="w-4 h-4 mr-1"/>
                    {t('comment')}
                  </MutedText>

                  <div className="py-2">
                    <MutedText>
                      {issueEvent.comment?.text}
                    </MutedText>
                  </div>
                </div>
              ) : null}

              <div className="flex justify-end">
                <MutedText>
                  {parseOrFormat(issueEvent.created_at, DateFormat.HumanDateTime)}
                </MutedText>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

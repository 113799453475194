export const getIssueBg = (status: string) => {
  switch (status) {
  case 'read':
    return 'bg-green-50';
  case 'escalated':
    return 'bg-red-50';
  case 'accepted':
    return 'bg-orange-50';
  case 'ignored':
    return 'bg-gray-50';
  default:
    return 'bg-red-50';
  }
};

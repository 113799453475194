import 'react-photo-view/dist/react-photo-view.css';

import { Link } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@/Container';
import {
  ContactIcon, ExternalIcon,
  IntegrationsIcon,
  OrganizationIcon,
  PrivacyIcon,
  ProduceIcon,
  SidebarIcon, SimpleIcon, ToSIcon,
  UserIcon,
  UsersIcon,
} from '@/Icon';
import { Breadcrumbs } from '@/Layout';
import { UserMenu, UserSelectable } from '@/Nav/UserMenu';
import { MutedText, TextLabel } from '@/Text';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';

interface DashboardLayoutProps {
  children: ReactNode;
}

interface ExternalLinkProps {
  link: Link;
}

interface Link {
  name: string;
  link: string;
  icon?: any;
  disabled?: boolean;
  external?: boolean;
}

function ExternalLink({ link }: ExternalLinkProps) {
  const { url } = usePageProps();

  return (
    <span className="block">
      <a href={link.link} target="_blank" rel="noreferrer" className={linkVariants({ status: getLinkStatus(url, link) })}>
        <span className="span flex justify-between w-full">
          <span className="flex items-center">
            {link.icon && (
              <SidebarIcon icon={link.icon}/>
            )}
            {link.name}
          </span>

          <span>
            <SimpleIcon icon={ExternalIcon}/>
          </span>
        </span>
      </a>
    </span>
  );
}

const SidebarLink = ({ link }: { link: Link }) => {
  const { url } = usePageProps();

  return (
    <Link
      href={!('disabled' in link) ? link.link : ''}
      disabled={'disabled' in link}
      className={linkVariants({ status: getLinkStatus(url, link) })}
    >
      {link.icon && (
        <SidebarIcon icon={link.icon}/>
      )}
      {link.name}
    </Link>
  );
};

export const isActive = (currentUrl: string, link: Link) => {
  return currentUrl.includes(link.link);
};

const getLinkStatus = (currentUrl: string, link: any) => {
  if ('disabled' in link && link.disabled) {
    return 'disabled';
  }

  if (isActive(currentUrl, link)) {
    return 'active';
  }

  return 'default';
};

const linkVariants = cva(
  'flex items-center hover:bg-gray-100 px-2 py-2 rounded-sm !font-normal !text-sm',
  {
    variants: {
      status: {
        active: 'text-blue-600 *:text-inherit border-r-2 rounded-r-none border-blue-500',
        default: 'text-gray-600',
        disabled: 'cursor-not-allowed !text-gray-200 *:text-gray-200',
      },
    },
    defaultVariants: {
      status: 'default',
    },
  }
);

export const SettingsLayout = ({ children }: DashboardLayoutProps) => {
  const { user, organization } = usePageProps();
  const { t } = useTranslation();
  const routes = useRoutes();

  const adminLinks: Link[] = [
    { name: 'About', link: `/b/${organization.id}/settings/organization/about`, icon: OrganizationIcon },
    { name: 'Users', link: `/b/${organization.id}/settings/organization/users`, icon: UsersIcon },
    { name: 'Produce', link: `/b/${organization.id}/settings/organization/produce`, icon: ProduceIcon },
    {
      name: 'Integrations',
      link: `/b/${organization.id}/settings/organization/integrations`,
      disabled: true,
      icon: IntegrationsIcon,
    },
  ];

  const userLinks: Link[] = [
    { name: 'About', link: `/b/${organization.id}/settings/about`, icon: UserIcon },
  ];

  const externalLinks: Link[] = [
    { name: 'Contact', link: 'https://www.agstacked.com/contact', icon: ContactIcon },
    { name: 'Privacy', link: 'https://www.agstacked.com/privacy-policy', icon: PrivacyIcon },
    { name: 'ToS', link: 'https://www.agstacked.com/terms-of-service', icon: ToSIcon },
  ];

  return (
    <div className="bg-gray-50 min-h-screen">
      <Container size="xl">
        <nav className="fixed border-r bg-gray-50 lg:w-64 shrink-0 py-12 top-0 bottom-0 flex flex-col justify-between">
          <div className="space-y-12">
            <div>
              <Breadcrumbs backLink={{ type: 'link', name: t('overview'), href: `/b/${organization.id}` }}/>
            </div>
            <div>
              <TextLabel className="!text-lg">
                Settings
              </TextLabel>
            </div>

            <div className="space-y-1">
              <MutedText className="font-normal !text-gray-400">
                Organization
              </MutedText>
              <div className="space-y-2">
                {adminLinks.map((link) => (
                  <span key={link.name} className="block">
                    <SidebarLink link={link}/>
                  </span>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <MutedText className="font-normal !text-gray-400">
                User
              </MutedText>
              <div className="space-y-1">
                {userLinks.map((link) => (
                  <span key={link.name} className="block">
                    <SidebarLink link={link}/>
                  </span>
                ))}
              </div>
            </div>
            <div className="space-y-2">
              <MutedText className="font-normal !text-gray-400">
                External
              </MutedText>
              <div className="space-y-1">
                {externalLinks.map((link) => (
                  <ExternalLink key={link.name} link={link}/>
                ))}
              </div>
            </div>
          </div>

          <div className="pr-4">
            <UserMenu
              organization={organization}
              logoutRoute={routes.logout()}
            >
              <UserSelectable user={user}/>
            </UserMenu>
          </div>
        </nav>
        <div className="lg:pl-64 bg-white">
          <div id="settings-container"
            className="flex-1 px-2 py-4 md:px-12 md:py-12 overflow-auto bg-white shadow-sm">
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SettingsLayout;

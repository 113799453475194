import { BooleanFieldIcon, Icon, MeasurementFieldIcon, PercentageIcon } from '@/Icon';
import { Field, FieldType } from '~/types/types';

export interface FieldIconProps {
  field: Field;
}

/**
 * FieldIcon
 */
export const FieldIcon = ({ field }: FieldIconProps) => {
  switch (field.type as FieldType) {
  case 'measurement': {
    return (
      <Icon className="flex-shrink-0 items-center w-4 h-4 mr-1">
        <MeasurementFieldIcon className="w-full h-full"/>
      </Icon>
    );
  }
  case 'percentage': {
    return (
      <Icon className="flex-shrink-0 w-4 h-4 mr-1">
        <PercentageIcon className="w-full h-full"/>
      </Icon>
    );
  }
  default: {
    return (
      <Icon className="flex-shrink-0 w-4 h-4 mr-1">
        <BooleanFieldIcon className="w-full h-full"/>
      </Icon>
    );
  }
  }
};

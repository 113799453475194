import { cva } from 'class-variance-authority';

import { Label } from '@/Label';
import { useFormat } from '~/hooks/useFormat';
import { Field, FieldConstraint } from '~/types/types';

interface ConstraintsLabelProps {
  constraint: FieldConstraint;
  field: Field;
  fractionToPercentage?: boolean;
  compact?: boolean;
}

const ragVariants = cva(
  'h-2 w-2 rounded-full',
  {
    variants: {
      score: {
        red: 'bg-red-400',
        green: 'bg-green-400',
        amber: 'bg-orange-400',
      },
      defaultVariants: {
        variant: 'green',
      },
    },
  }
);

const RAGLabel = ({ constraint, compact }: ConstraintsLabelProps) => {
  const { formatPercentage } = useFormat();

  const decimals = compact ? 0 : 2;

  const formatValue = (value: number | null) => {
    if (value === null) return '∞';
    return formatPercentage(value, decimals);
  };

  const sortedRanges = constraint.ranges?.sort((a, b) => (a.min ?? 0) - (b.min ?? 0)) ?? [];

  return (
    <div className="flex flex-wrap gap-1" aria-label={'rag range'}>
      {sortedRanges.map((range) => (
        <div key={range.id} aria-label={`rag range for ${range.score}`}>
          <Label key={range.id} className="whitespace-nowrap" size="xs">
            <div className="flex items-center gap-1 mr-1 text-xxs">
              <div className={ragVariants({ score: range.score as any })}/>
              {formatValue(range.min)} - {formatValue(range.max)}
            </div>
          </Label>
        </div>
      ))}
    </div>
  );
};

const RangeLabel = ({ constraint, field }: ConstraintsLabelProps) => {
  const formatValue = (value: number | null) => {
    if (value === null) return '∞';

    return `${value} ${field.unit}`;
  };

  const range = constraint.ranges?.[0];
  if (!range) return null;

  const min = formatValue(range.min);
  const max = formatValue(range.max);

  if (range.min === null && range.max === null) {
    return <span>Any value</span>;
  } else if (range.min === null) {
    return <span>≤ {max}</span>;
  } else if (range.max === null) {
    return <span>≥ {min}</span>;
  } else {
    return <span>{min} - {max}</span>;
  }
};

/**
 * ConstraintsLabel
 *
 * A label used for displaying constraints on a field.
 *
 * @abstraction L3: Used to render all variants of a Label
 *
 * @precondition - The constraint has been pre-processed (e.g. fractions have been converted to percentages).
 * @param constraint
 * @param field
 * @param fractionToPercentage
 * @param compact
 * @constructor
 */
export const ConstraintsLabel = ({
  constraint,
  field,
  fractionToPercentage = false,
  compact = false,
}: ConstraintsLabelProps) => {
  if (constraint.type === 'rag') {
    return <RAGLabel constraint={constraint} field={field} fractionToPercentage={fractionToPercentage}
      compact={compact}/>;
  }

  if (constraint.type === 'range' && constraint.ranges && constraint.ranges.length > 0) {
    return (
      <Label aria-label={'general range'}>
        <RangeLabel constraint={constraint} field={field}/>
      </Label>
    );
  }

  return null;
};

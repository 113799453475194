import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import { SectionTitle } from '@/Text';
import { OrderDataStream, OrderDataStreamType, WeatherDataPayload } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { MapDataStream } from '../MapDataStream';
import { calculateGenericIssuesCount } from '../utils';

export interface HarvestSectionProps {
  dataStreams: OrderDataStream<WeatherDataPayload>[];
}

export const HarvestSection = ({ dataStreams }: HarvestSectionProps) => {
  const { t } = useTranslation();

  const relevantDataStreams = dataStreams.filter((dataStream) => {
    return dataStream.type === OrderDataStreamType.WEATHER;
  });

  const issuesCount = useMemo(() => calculateGenericIssuesCount(relevantDataStreams), [relevantDataStreams]);

  if (relevantDataStreams.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between mt-6 mb-2">
        <SectionTitle>
          {t('harvest')}
        </SectionTitle>
      </div>

      <WithAwaitingFallback>
        <div className="space-y-4">
          {relevantDataStreams.map((dataStream, index) => (
            <MapDataStream key={index} dataStream={dataStream}/>
          ))}
        </div>
      </WithAwaitingFallback>
    </div>
  );
};

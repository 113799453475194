import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../Shared/UI/Button';
import { Card } from '../../../Shared/UI/Card';
import { Container } from '../../../Shared/UI/Container';
import { Input } from '../../../Shared/UI/Input';
import Select from '../../../Shared/UI/Select';
import { H1 } from '../../../Shared/UI/Text';
import { useAction } from '../../../Shared/hooks/useAction';
import { DashboardLayout } from '../DashboardLayout';
import { DataField, DataFieldType, FormTemplate, Organization, PageProps } from '../../../Shared/types/types';

interface AddTemplateOrderProps extends PageProps {
  organization: Organization;
  formTemplate?: FormTemplate;
  isEdit?: boolean;
}

interface AddFormTemplateProps {
  name: string;
  description?: string;
  dataFields: DataField[];
}

const AddFormTemplatePage = ({ organization, formTemplate, isEdit }: AddTemplateOrderProps) => {
  const { t } = useTranslation();
  const { register, control, handleSubmit, formState: { defaultValues, isValid } } = useForm<AddFormTemplateProps>({
    defaultValues: {
      name: formTemplate?.name,
      description: formTemplate?.description,
      dataFields: formTemplate?.data_fields || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'dataFields', // unique name for your Field Array
  });

  const [addFormTemplate] = useAction(`/b/${organization.id}/form_template/add`);
  const [editFormTemplate] = useAction(`/b/${organization.id}/form_template/${formTemplate?.id}/edit`);

  const onSubmit = (data: typeof defaultValues) => {
    return isEdit ? editFormTemplate(data) : addFormTemplate(data);
  };

  return (
    <Container className="py-4">
      <H1 className='p-4 sm:p-0'>
        {isEdit ? t('edit') : t('Add')} {t('form_template')}
      </H1>
      <Card className="px-4 py-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          <div>
            <label className="font-medium">
              {t('Name')}
            </label>

            <div>
              <Input
                type="text"
                className="max-w-xs"
                {...register('name')}
              />
            </div>
          </div>
          <div>
            <label className="font-medium">
              {t('description')}
            </label>
            <div>
              <Input
                type="text"
                className="max-w-xs"
                {...register('description')}
              />
            </div>
          </div>
          <div>
            <label className="inline-block mr-2 font-medium">
              {t('data_fields')}
            </label>
            <Button
              type="button"
              variant="primary"
              size="sm"
              onClick={() => append({ id: null, label: '', sublabel: '', type: DataFieldType.TEXT })}
            >
              {t('add_data_field')}
            </Button>
          </div>

          {fields.map((field, index) => (
            <div key={field.id} className='relative p-4 border rounded border-token-muted'>
              <button onClick={() => remove(index)} className='absolute top-1 right-1'>X</button>
              <input type="hidden" {...register(`dataFields.${index}.id`)} />
              <div className='grid gap-3 sm:grid-cols-3'>

                <div className='flex flex-col gap-2'>
                  <label className="font-medium">
                    {t('label')}
                  </label>
                  <Input
                    type='text'
                    {...register(`dataFields.${index}.label`)} // register each input within the array`)}
                  />
                </div>
                <div className='flex flex-col gap-2'>
                  <label className="font-medium">
                    {t('sublabel')}
                  </label>
                  <Input
                    type='text'
                    {...register(`dataFields.${index}.sublabel`)} // register each input within the array`)}
                  />
                </div>

                <div className='flex flex-col gap-2 w-fit'>
                  <label className="font-medium">
                    {t('type')}
                  </label>
                  <Controller
                    name={`dataFields.${index}.type`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        options={[
                          { label: 'Text', value: 'text' },
                          { label: 'Number', value: 'number' },
                          { label: 'Date', value: 'date' },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className='flex justify-end'>
            <button
              disabled={!isValid}
              className="px-4 py-2 font-medium text-white duration-150 bg-indigo-600 rounded-lg disabled:bg-gray-300 hover:bg-indigo-500 active:bg-indigo-600"
            >
              {t('save')} {t('form template')}
            </button>
          </div>
        </form>
      </Card>
    </Container>
  );
};

AddFormTemplatePage.layout = (page: React.ReactNode) => <DashboardLayout>{page}</DashboardLayout>;

export default AddFormTemplatePage;

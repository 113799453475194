/**
 * We define two types of formatters
 * - Model => Form schemas
 * - Form => API Inputs
 */
import { Field, Spec } from '~/types/types';

import { flushID } from '../helpers';
import { SpecField, SpecFieldsFormValues, SpecVarietiesFormInputs } from './schemas';
import DefectType = App.Domain.Quality.DefectType;
import BaseSpecInput = App.Domain.Specs.BaseSpecInput;
import ProduceSpecInput = App.Domain.Specs.ProduceSpecInput;
import SpecInput = App.Domain.Specs.SpecInput;
import SpecFieldInput = App.Domain.Specs.SpecFieldInput;
import SpecFieldConstraintInput = App.Domain.Specs.SpecFieldConstraintInput;
import FieldCategory = App.Domain.Fields.SupportedFields.FieldCategory;
import ConstraintType = App.Domain.Specs.ConstraintType;

export const makeDefaultFieldFormValues = (fields: Field[]): SpecFieldsFormValues => {
  return {
    fields: fields.map((field) => formatFieldModelToForm(field)),
  };
};

const formatRangeValue = (value: number | null, isPercentage: boolean): number | null => {
  if (value === null) {
    return null;
  }
  return isPercentage ? value / 100 : value;
};

/**
 * =================================
 * FORMATTER: Model => Form
 * =================================
 */

/**
 * Maps the {Field} model to the {SpecField} form schema.
 *
 * Formatter: Model => Form
 * Scope: fields.
 * Used in the SpecFields form.
 * @param field
 */
export const formatFieldModelToForm = (field: Field): SpecField => {
  return {
    id: field.id,
    name: field.name,
    defect_type: field.defect_type as DefectType,
    required: field.required,
    hidden: field.hidden,
    type: field.type as any,
    unit: field.unit,
    base_type: field.base_type ?? undefined,
    constraints: field.constraints?.map(constraint => ({
      id: constraint.id,
      type: constraint.type as any,
      produce_varieties: constraint.produce_varieties?.map(variety => variety.name) ?? [],
      ranges: constraint.ranges?.map(range => ({
        id: range.id,
        score: range.score as any,
        min: field.type === 'percentage'
          ? range.min ? range.min * 100 : 0
          : range.min,
        max: field.type === 'percentage'
          ? range.max ? range.max * 100 : 100
          : range.max,
      })) ?? [],
    })) ?? [],
  };
};

export const formatVarietiesModelToForm = (spec: Spec): SpecVarietiesFormInputs => {
  return {
    varieties: spec.linked_varieties?.map(variety => ({
      id: variety.id,
      public_name: variety.name,
    })) ?? [],
  };
};

/**
 * Maps the {SpecField} form schemas to the SpecInput API inputs.
 * Formatter: Form => API
 * - Utilizes the formatter for varieties to ensure that the varieties are not touched.
 *
 * Scope: fields.
 * Used to save the form for spec-fields to the API (and convert the form values to the correct format).
 * @param spec
 * @param input
 */
export const formatFieldsInputToApi = (spec: Spec, input: SpecFieldsFormValues): SpecInput => {
  const baseInput: BaseSpecInput = {
    name: spec.name,
    produceName: spec.produce?.name ?? null,
    produceType: spec.produce_type as any ?? null,
    produceId: spec.produce?.id ?? null,
  };

  const varietyInputs: ProduceSpecInput[] = spec.linked_varieties?.map(variety => ({
    id: variety.id ?? null,
    produceVarietyName: variety.name,
    crudPolicy: 'save',
  })) ?? [];

  const fieldInputs = input.fields.map(formatFieldInputToApi);

  return {
    baseInput,
    fieldsInput: {
      specFieldInputs: fieldInputs,
    },
    varietyInputs,
  };
};

/**
 * Maps the {Variety} form schemas to the SpecInput API inputs.
 *
 * Formatter: Form => API
 * - Utilizes the formatter for varieties to ensure that the varieties are not touched.
 *
 * Scope: varieties.
 * Used to save the form for spec-varieties to the API (and convert the form values to the correct format).
 * @param spec
 * @param input
 */
export const formatVarietyInputsToApi = (spec: Spec, input: SpecVarietiesFormInputs): SpecInput => {
  const baseInput: BaseSpecInput = {
    name: spec.name,
    produceName: spec.produce?.name ?? null,
    produceType: spec.produce_type as any ?? null,
    produceId: spec.produce?.id ?? null,
  };

  const varietyAPInputs: ProduceSpecInput[] = input.varieties.map(variety => ({
    id: variety.id ?? null,
    produceVarietyName: variety.public_name,
    crudPolicy: 'save',
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fieldInputs = spec.linked_fields?.map(formatFieldModelToApi) ?? [];

  // We map the spec fields to form-inputs => to api-inputs to ensure that the api-inputs are in the correct format
  return {
    baseInput,
    varietyInputs: varietyAPInputs,
    fieldsInput: {
      specFieldInputs: fieldInputs,
    },
  };
};

export const formatFieldModelToApi = (field: Field): SpecFieldInput => {
  return {
    id: flushID(field.id),
    name: field.name,
    defectType: field.defect_type as DefectType,
    required: field.required ?? false,
    hidden: field.hidden ?? false,
    type: field.type as any,
    baseType: field.base_type as any,
    category: field.category as FieldCategory,
    unit: field.unit ?? null,
    crudPolicy: 'save',
    constraintInputs: field?.constraints?.map(constraint => {
      const constraintInput: SpecFieldConstraintInput = {
        id: constraint.id,
        type: constraint.type as ConstraintType,
        produceVarieties: constraint?.produce_varieties?.map(variety => ({
          id: flushID(variety.id),
          produceVarietyName: variety.name,
          crudPolicy: 'save',
        })) ?? [],
        rangeInputs: constraint?.ranges?.map(range => ({
          id: flushID(range.id),
          min: field.type === 'percentage'
            ? range.min ? range.min / 100 : 0
            : range.min ?? 0,
          max: field.type === 'percentage'
            ? range.max ? range.max / 100 : 100
            : range.max ?? 100,
          scoreType: range.score as any,
          includeMin: true,
          includeMax: true,
        })) ?? [],
      };

      return constraintInput;
    }) ?? [],
  };
};

/**
 * Maps the {SpecField} form schemas to the SpecFieldInput API inputs.
 * Formatter: Form => API
 * Scope: fields.
 * @param fieldInput
 */
export const formatFieldInputToApi = (fieldInput: SpecField): SpecFieldInput => {
  return {
    id: flushID(fieldInput.id),
    name: fieldInput.name,
    defectType: fieldInput.defect_type as DefectType,
    required: fieldInput.required ?? false,
    hidden: fieldInput.hidden ?? false,
    type: fieldInput.type as any,
    baseType: fieldInput.base_type as any,
    category: 'quality',
    unit: fieldInput.unit ?? null,
    crudPolicy: 'save',
    constraintInputs: fieldInput.constraints.map(constraintFormInput => {
      const constraintInput: SpecFieldConstraintInput = {
        id: flushID(constraintFormInput.id),
        type: constraintFormInput.type,
        produceVarieties: constraintFormInput?.produce_varieties?.map(variety => ({
          id: null,
          produceVarietyName: variety as string,
          crudPolicy: 'save',
        })) ?? [],
        rangeInputs: constraintFormInput.ranges.map(rangeFormInput => ({
          id: flushID(rangeFormInput.id),
          min: formatRangeValue(rangeFormInput.min, fieldInput.type === 'percentage'),
          max: formatRangeValue(rangeFormInput.max, fieldInput.type === 'percentage'),
          scoreType: rangeFormInput.score as any,
          includeMin: true,
          includeMax: true,
        })),
      };

      return constraintInput;
    }),
  };
};

/**
 * =================================
 * Convert unknown fields to a format such that the frontend can understand it.
 * =================================
 */


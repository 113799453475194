import { PageProps as BasePageProps } from '@inertiajs/core';

import { DatePeriod } from '~/types/global-types';
import OrderStateType = App.Domain.Order.OrderStateType;
import SpecInput = App.Domain.Specs.SpecInput;
import ConstraintType = App.Domain.Specs.ConstraintType;

export type QualityEntry = App.Models.QualityEntry;
export type ModelIssue = App.Models.Issue;
export type Event = App.Models.Event;
export type SellerSummary = App.Models.SellerSummary;
export type OrderIssueSummary = App.Models.OrderIssueSummary;
export type Comment = App.Models.Comment;

export type UserGroup = App.Models.UserGroup;
export type Produce = App.Models.Produce & { meta_type?: string };
export type ProduceVariety = App.Models.ProduceVariety & { public_name: string };
export type Attachment = App.Models.Attachment;
export type OrderProduce = App.Models.OrderProduce & { active_spec?: Spec };
export type ModelFieldConstraint = App.Models.FieldConstraint;
export type QCQualityInput = App.Domain.QualityInputs.QCQualityInput;
export type IssueType = App.Domain.Issues.IssueType;
export type ForecastAccuracySummary = App.Domain.Reports.ForecastAccuracy.ForecastAccuracySummary;
export type FieldSummary = App.Models.FieldSummary;
export type UnitFieldSummary = App.Models.UnitFieldSummary;
export type PercentageFieldSummary = App.Models.PercentageFieldSummary;
export type MeasurementFieldSummary = App.Models.MeasurementFieldSummary;
export type BooleanFieldSummary = App.Models.BooleanFieldSummary;
export type Range = App.Models.Range;
export type Field = App.Models.Field & { type: FieldType, required: boolean, assigned: boolean };
export type Spec = App.Models.Spec;
export type Pallet = App.Models.Pallet;

export type FieldConstraint = ModelFieldConstraint & { type: ConstraintType; produce_varieties?: ProduceVariety[] };

export type OrderStatusType = App.Domain.Order.OrderStatusType;

export type NotificationType =
  | 'NewIssuesUpdate'
  | 'NewAdminDailyRollup'
  | 'NewOrdersFound';

export type Notification = {
  id: string;
  type: NotificationType;
  created_at: string | null;
  updated_at: string | null;
  data: any;
  read_at: string | null;
};

export type ClaimGroupType = 'seller' | 'produce' | 'reason';

export type IssueEvent = {
  id: string;
  issue_id: string;
  type: string;
  data: any;
  created_at: string;
  updated_at: string;
  comment?: Comment;
  user?: User;
}

export type Issue = ModelIssue;

export interface ClaimGroup {
  type: ClaimGroupType;
  name: string;
  id: string;
  countClaims: number;
  total: number;
  totalIssues: number;
  subGroups?: ClaimGroup[];
  orders?: Order[];
}

export interface ClaimPeriodSummary {
  startDate: string;
  endDate: string;
  countClaims: number;
  total: number;
  totalIssues: number;
  topGroups: ClaimGroup[];
}

export interface NewIssuesUpdateNotification extends Notification {
  type: 'NewIssuesUpdate';
  data: {
    org_id: string;
    redirect_to: string;
    from_date: string;
    to_date: string;
    order_count: number;
    total_issues_count: number;
    spec_discrepancy_issue_count: number;
    transport_delay_issue_count: number;
    transport_temp_threshold_issue_count: number;
  };
}

export interface NewOrdersFoundNotification extends Notification {
  type: 'NewOrdersFound';
  data: {
    org_id: string;
    redirect_to: string;
    order_count: number;
  };
}

export interface NewAdminDailyRollupNotification extends Notification {
  type: 'NewAdminDailyRollup';
  data: {
    org_id: string;
    redirect_to: string;
    from_date: string;
    to_date: string;
    order_count: number;
    total_issues_count: number;
    spec_discrepancy_issue_count: number;
    transport_delay_issue_count: number;
    transport_temp_threshold_issue_count: number;
  };
}

export interface User extends App.Models.User {
  unread_notifications?: Notification[];
  notifications?: Notification[];
}

export interface Organization extends App.Models.Organization {
  sell_order_count: number;
  accuracy?: number;
  color?: string;
  latestSellerSummary?: SellerSummary | null;
  forecastAccuracySummary?: {
    accuracy?: number;
    color: string;
  };
}

export interface Order extends App.Models.Order {
  requiresAttention?: boolean;
  has_buyer_intake?: boolean;
  latestEvent?: Event;
  activeDataStreams?: App.Models.OrderDataStream[];
  total?: number;
}

export type OrderStep = App.Models.OrderStep;
export type Integration = App.Models.Integration;
export type Inspection = App.Models.Inspection;

export interface OrderFieldSummary {
  field: Field;
  fieldSummaries: FieldSummary[];
  relevantConstraint?: FieldConstraint;
}

export interface GenericDataStream {
  status: 'pending' | 'active' | 'inactive';
  expected_at: string;
}

export interface OrderDataStream<T> extends App.Models.OrderDataStream {
  data: T;
}

export enum OrderDataStreamType {
  TRANSPORT_TEMPERATURE = 'TRANSPORT_TEMPERATURE',
  LOCATION = 'LOCATION',
  WEATHER = 'WEATHER',
}

export type CommentableType =
  | 'Order'
  | 'FieldSummary'
  | 'Attachment'
  | 'OrderDataStream'
  | 'QualityEntry';

export interface AttachmentComment extends Comment {
  commentable?: Attachment;
}

export interface InspectionComment extends Comment {
  commentable?: FieldSummary;
}

export interface OrderDataStreamComment extends Comment {
  commentable?: OrderDataStream<any>;
}

export type AppMode = 'buyer' | 'seller';

export interface PageProps extends BasePageProps {
  errors: Record<string, any>;
  organization: Organization;
  user: User;
  isBuyer: boolean;
  order?: Order;
  show_onboard_modal?: boolean;
  app: {
    appMode: AppMode;
    path: string;
  };
}

export interface PickerItem {
  icon: JSX.Element;
  labelKey: string;
  shortcut: string[];
}

export const sellerAnalyticsTypes = [
  'Quality Issue Frequency',
  'Quality costs',
  'Forecast Accuracy',
] as const;
export type SellerAnalyticsType = (typeof sellerAnalyticsTypes)[number];

export type SectionKey =
  | 'order'
  | 'inspection'
  | 'attachments'
  | 'temperature'
  | 'transportation';

export const defaultSteps = [
  'qc',
  'qc_review',
  'in_transit',
  'qa_intake',
  'finalized',
] as const;

export type OrderStepType = (typeof defaultSteps)[number];

export type FieldType = 'measurement' | 'boolean' | 'percentage';

export enum DataFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  CHOICE = 'choice',
}

export interface Counter {
  counter_value: number;
  delta: number;
  delta_indicator: 'green' | 'orange' | 'red';
}

export interface DataField {
  id?: number | null;
  label: string;
  sublabel?: string;
  type: DataFieldType;
}

export interface FormTemplate {
  id: string;
  name: string;
  description?: string;
  created_at: Date;
  updated_at: Date;
  organinzation_id: number;
  data_fields: DataField[];
}

export enum ProduceCategory {
  FRUIT = 'fruit',
  VEGETABLE = 'vegetable',
}

export interface IntegrationDetails {
  id: string;
  type: string;
  status: 'active' | 'inactive';
  last_synced_at: string;
}

export interface DataInputNumberField {
  id: string;
  data_input_id: number;
  value: number;
}

export interface DataInputTextField {
  id: string;
  data_input_id: number;
  value: string;
}

export interface DataInput {
  id: string;
  data_field_id: number;
  data_field: DataField;
  form_data_capture_id: number;
  data_input_number_field?: DataInputNumberField;
  data_input_text_field?: DataInputTextField;
  comments: Comment[];
}

export interface FormDataCapture {
  created_at: Date;
  updated_at: Date;
  id: string;
  form_template_id: number;
  quality_data_capture_id: number;
  data_inputs: DataInput[];
}

export interface ImageDataCapture {
  created_at: Date;
  updated_at: Date;
  id: string;
  quality_data_capture_id: number;
  image_path: string;
}

export interface QualityDataCapture {
  created_at: Date;
  updated_at: Date;
  id: string;
  order_id: number;
  type: 'form_data_capture' | 'image_data_capture';
  form_data_capture?: FormDataCapture;
  image_data_captures?: ImageDataCapture[];
}

export enum Summarizable {
  BooleanFieldSummary = 'BooleanFieldSummary',
  PercentageFieldSummary = 'PercentageFieldSummary',
  MeasurementFieldSummary = 'MeasurementFieldSummary',
}

export interface PaginatedItems<T> {
  data: T[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  prev_page_url: string;
  next_page_url: string;
  per_page: number;
  to: number;
  total: number;
}

export type InspectionStatus = 'upload_first' | 'upload_append';

export interface WeatherDataDayPayload {
  date: Date;
  minCelcius?: number;
  maxCelcius?: number;
  avgCelcius?: number;
  /** Rainfall, or precipitation, in millimeters**/
  rainfall?: number;
}

export interface WeatherDataPayload {
  startDate: Date;
  endDate: Date;
  baselines?: {
    celcius?: number;
    rainfall?: number;
  };
  issues: any[];
  granularity: 'day' | 'week' | 'month';
  events: WeatherDataDayPayload[];
}

export interface LocationEvent {
  date?: Date;
  type?: string;
  lat?: number;
  lon?: number;
  label?: string;
  countryCode?: string;
  location?: Location;
  transportType?: string;
  transportID?: string;
  issue?: {
    delayInSec?: number;
  };
  source?: string;
}

export interface LogisticsRouteIssue {
  type: string;
  duration: number;
  label?: string;
  date: Date;
}

export interface Location {
  lat: number;
  lon: number;
  label?: string;
  countryCode?: string;
}

export interface TemperatureIssue {
  type: string;
  duration: number;
  label?: string;
  date: Date;
}

export interface LocationDataPayload {
  meta: {
    origin?: Location;
    destination?: Location;
    eta?: Date;
  };
  summary: {
    totalDelayInSec?: number;
    issues?: LogisticsRouteIssue[];
  };
  events: LocationEvent[];
}

export interface TemperatureEvent {
  date: Date;
  value: number;
  isAnomaly?: boolean;
  metric?: 'celcius';
}

export interface TemperaturePayload {
  meta: {
    startDate: Date;
    endDate: Date;
    source: string;
  };
  summary: {
    minValue?: number;
    count?: number;
    issueCount?: number;
    avgValue?: number;
    maxValue?: number;
    issues?: TemperatureIssue[];
  };
  baselines: {
    low: number;
    high: number;
    general: number;
  };
  eventGroups: {
    type: string;
    metric: string | null;
    summary: {
      min: number;
      max: number;
      avg: number;
      count: number;
      issues: TemperatureIssue[];
    };
    events: TemperatureEvent[];
  }[];
}

export type DataStreamGroup = 'HARVEST' | 'TRANSPORT' | 'INSPECTION';

export enum OrganizationStatus {
  READY = 'READY',
  GENERATING = 'GENERATING',
}

export enum OrderStatus {
  READY = 'READY',
  GENERATING = 'GENERATING',
}

type SpecTestResultType = 'PASS' | 'FAIL' | 'IGNORE';

interface SpecTestResult {
  field: Field | null;
  fieldSummary: FieldSummary | null;
  status: SpecTestResultType;
  comment: string | null;
}

export interface VerifySpecFileOutput {
  specFile: any;
  results: SpecTestResult[];
  errors: Record<string, string[]>;
}

export enum IssueStatus {
  Resolved = 'resolved',
  Ignored = 'ignored',
  Mixed = 'mixed',
  Open = 'open',
  Read = 'read',
  Accepted = 'accepted',
  Escalated = 'escalated',
}

export interface Statistic {

}

export interface SavedReport extends App.Models.SavedReport {
  date_range_type: DatePeriod;
}

export type SaveSpecInput = SpecInput;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader } from '@/Card';
import { IconBox, IconedField, IDIcon, TransportLocationIcon } from '@/Icon';
import { MutedText, Strong, Text } from '@/Text';
import { DateLabel } from '~/Components/Dates/DateLabel';
import { OrderPreviewCard } from '~/Components/Orders/OrderPreviewCard';
import { DateFormat } from '~/hooks/useDate';
import { Issue, Order } from '~/types/types';

export const IssueCard = ({ issue }: { issue: Issue }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>
        {/* Header part */}
        <div className="">
          <div className="flex items-center space-x-2 justify-between">
            <div className="flex flex-grow">
              <div className="mr-2">
                <IconBox>
                  <TransportLocationIcon className="w-6 h-6"/>
                </IconBox>
              </div>
              <div>
                <div>
                  <Strong className="leading-none flex">
                    {t(issue.type)}
                  </Strong>
                </div>
                <div>
                  {issue.type === 'transport_temp_threshold' && (
                    <MutedText>
                      We have detected {issue.issueable.data.anomalyCount} points which fell out of the temperature
                      threshold of {issue.issueable.data.lowerThreshold}°C
                      and {issue.issueable.data.upperThreshold}°C
                    </MutedText>
                  )}
                </div>

                <div className="flex space-x-2 mt-3">
                  <IconedField icon={IDIcon}>
                    <MutedText>
                      {issue.id}
                    </MutedText>
                  </IconedField>
                </div>
              </div>
            </div>

            <div>
              <DateLabel from={issue.created_at} format={DateFormat.HumanDateTime}/>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {issue.orders?.length > 0 ? (
          <div className="flex space-x-2 items-center">
            {issue.orders?.map((order: Order) => (
              <OrderPreviewCard order={order} key={order.id}/>
            ))}
          </div>
        ) : (
          <div>
            <Text>
              {t('no_order_associated')}
            </Text>
            <MutedText>
              {t('no_order_associated_description')}
            </MutedText>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

import { Link } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { w } from 'windstitch';

import { cn } from '~/utils/cn';

import { IconBox } from './Icon';
import { CardSubtitle, CardTitle, Helper } from './Text';

interface CardProps {
  children: ReactNode;
  hasHover?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const Card = ({ children, hasHover, className, isLoading }: CardProps) => {
  return (
    <div
      className={
        cn(
          'bg-white ring-1 ring-gray-900/5 h-full lg:h-revert rounded shadow-sm transition-all',
          className,
          hasHover ? 'hover:shadow-md cursor-pointer' : '',
          isLoading ? 'animate-pulse' : ''
        )}
    >
      {children}
    </div>
  );
};

interface NavCardProps {
  children: ReactNode;
  href: string;
  className?: string;
}

export const NavCard = ({ children, href, className }: NavCardProps) => {
  return (
    <Card className={`relative ${className}`} hasHover>
      <Link href={href} className="absolute inset-0"/>
      {children}
    </Card>
  );
};

export const CardBody = w.div('', {
  variants: {
    size: {
      sm: 'px-3 py-4',
      md: 'px-3 py-4 sm:py-4 sm:px-8',
      lg: 'py-6 px-12',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const CardHeaderContainer = w.div(
  'bg-gray-white rounded-t border-b border-gray-100',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export const CardContent = w.div(
  '',
  {
    variants: {
      size: {
        sm: 'px-4',
        md: 'px-3 sm:px-8',
        lg: 'px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

interface CardHeaderProps {
  children: ReactNode;
  renderIcon?: ReactNode;
  title?: string | null;
  description?: string | null;
}

export const CardHeader = ({
  renderIcon,
  children,
  title,
  description,
}: CardHeaderProps) => {
  return (
    <CardHeaderContainer className="py-4">
      <div className="flex gap-x-3">
        {renderIcon && (
          <div className="hidden sm:revert">
            <IconBox>{renderIcon}</IconBox>
          </div>
        )}

        <div className="flex-grow">
          {title && (
            <CardTitle className="flex items-center mb-1 leading-none">
              {title}
            </CardTitle>
          )}
          {description && (
            <div>
              <CardSubtitle>{description}</CardSubtitle>
            </div>
          )}
          {children}
        </div>
      </div>
    </CardHeaderContainer>
  );
};

// Has inline box shadow from above
export const CardFooter = w.div(
  'bg-gray-50 rounded-b border-t border-gray-200 text-gray-900',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

interface PromptCardProps {
  variant: 'gray' | 'emerald' | 'red' | 'blue' | 'yellow';
  renderIcon?: ReactNode;
  children: ReactNode;
}

const promptCardBodyVariants = cva('sm:!py-8 rounded bg-gradient-to-b border', {
  variants: {
    variant: {
      gray: 'from-gray-50 to-slate-50 border-slate-100',
      emerald: 'from-emerald-50 to-slate-50 border-slate-100',
      red: 'from-red-50 to-slate-50 border-slate-100',
      blue: 'from-blue-50 to-slate-50 border-slate-100',
      yellow: 'from-yellow-50 to-slate-50 border-slate-100',
    },
  },
  defaultVariants: {
    variant: 'emerald',
  },
});

export const promptCardIconVariants = cva(
  'inline-block p-1 mt-1 border-gray-100 rounded shadow-sm ring-1 ',
  {
    variants: {
      variant: {
        gray: 'from-gray-50 to-slate-50 ring-gray-200 !border-red-100',
        emerald:
          'text-emerald-500 bg-emerald-50 ring-emerald-200 border-gray-100',
        red: 'text-red-500 bg-red-50 ring-red-200 border-gray-100',
        blue: 'text-blue-500 bg-blue-50 ring-blue-200 border-gray-100',
        yellow: 'text-yellow-500 bg-yellow-50 ring-yellow-200 border-gray-100',
      },
    },
    defaultVariants: {
      variant: 'emerald',
    },
  }
);

/**
 * A card that is used to prompt the user to take action.
 * It has a colored border and a gradient background.
 *
 * @param variant - The color of the border and background.
 * @param renderIcon - An icon to render on the left side of the card.
 * @param children - The content of the card.
 *
 * @example
 * <PromptCard variant="emerald" renderIcon={<Icon />} >
 *   <CardTitle>Card Title</CardTitle>
 *   <CardSubtitle>Card Subtitle</CardSubtitle>
 *   <p>Card Content</p>
 * </PromptCard>
 */
export const PromptCard = ({
  variant,
  renderIcon,
  children,
}: PromptCardProps) => {
  return (
    <Card className="p-3 bg-white">
      <CardBody className={promptCardBodyVariants({ variant })}>
        <div className="flex gap-4">
          <div className="hidden sm:revert">
            {!!renderIcon && (
              <div
                className={promptCardIconVariants({
                  variant,
                })}
              >
                {renderIcon}
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export const CardSection = w.div('py-1 bg-gray-50 px-4');
export const CardSectionTitle = Helper;

import { Pencil2Icon } from '@radix-ui/react-icons';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';

import { Button } from '@/Button';
import { Icon, IconedField, QualityIcon } from '@/Icon';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { MutedText, PageTitle } from '@/Text';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';

import { DashboardLayout } from '../../DashboardLayout';
import { EditReportModal } from '../ReportsPage/EditReportModal';
import { QualityIssuesPageProps } from './helpers';

export const QualityIssuesLayout = ({ children }: { children: ReactNode }) => {
  const { user, report, options } = usePageProps<QualityIssuesPageProps>();

  const { reports } = useRoutes();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const ownsReport = user.id === report.user_id;

  return (
    <DashboardLayout>
      <LayoutHeader isSticky>
        <div className="flex items-center justify-between md:flex-row">
          {/* Left side */}
          <div>
            <Breadcrumbs backLink={{ type: 'link', name: 'Reports', href: reports() }}/>
            <div>
              <div>
                <PageTitle>
                  {report.name}
                </PageTitle>
              </div>

              <div className="flex gap-2 mt-2">
                <div>
                  <IconedField icon={QualityIcon}>
                    <MutedText>
                      {t('quality_report')}
                    </MutedText>
                  </IconedField>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            {ownsReport && (
              <EditReportModal
                isOpen={isEditModalOpen}
                setIsOpen={(res) => setIsEditModalOpen(res.isOpen)}
                report={report}
                regions={[]}
                sellers={options.reportSellers}
              >
                <Button variant="gray">
                  <Icon className="mr-1">
                    <Pencil2Icon className="w-4 h-4"/>
                  </Icon>
                  {t('edit_report')}
                </Button>
              </EditReportModal>
            )}
          </div>
        </div>
      </LayoutHeader>
      <LayoutBody>
        {children}
      </LayoutBody>
    </DashboardLayout>
  );
};

import { PencilIcon } from '@heroicons/react/20/solid';
import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import { ReactNode, useState } from 'react';
import { w } from 'windstitch';

import { IconButton } from './Button';

export const Input = w.input(
  `
  flex h-9 w-full border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:text-token-muted disabled:opacity-50,

  `,
  {
    defaultProps: {
      type: 'checkbox',
    },
    defaultVariants: {
      status: 'enabled',
      rounded: 'full',
      error: 'false',
    },
    variants: {
      status: {
        enabled: '',
        error: 'border-red-500',
        disabled: '!bg-gray-50 !text-gray-400 cursor-not-allowed',
      },
      error: {
        false: '',
        true: 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500',
      },
      rounded: {
        full: 'rounded-md',
        none: 'rounded-none',
        left: 'rounded-l-md',
        right: 'rounded-r-md',
      },
    },
  }
);

export const InputGroup = w.div(
  `
    flex items-center
  `
);

export const LeftAddon = w.div(
  `
    rounded-l-md bg-gray-50 border border-input px-3 py-1 h-9 border-r-0 text-gray-500
  `
);

export const CenterAddon = w.div(
  `
    border border-input px-1 py-1 h-9 text-gray-500 whitespace flex items-center justify-center
  `
);

export const RightAddon = w.div(
  `
    rounded-r-md bg-gray-50 border border-input px-3 py-1 h-9 border-l-0 text-gray-500 whitespace-nowrap
  `
);

interface WithInputProps {
  children: ReactNode;
  defaultValue?: string | null;
  onChange: (value: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

/**
 * Wraps a text with an input, and allows editing the text.
 *
 * Examples:
 * - Good for editable headers, for example.
 */
export const WithInput = ({
  disabled,
  children,
  defaultValue,
  isLoading,
  onChange,
}: WithInputProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  if (isEditMode || isLoading) {
    return (
      <form
        className="flex items-center space-x-2"
        onSubmit={(e: any) => {
          e.preventDefault();

          const value = e.target?.[0].value as string;
          if (value) {
            onChange(value);
            setIsEditMode(false);
          }

          return false;
        }}
      >
        <Input
          status={isLoading ? 'disabled' : 'enabled'}
          disabled={isLoading}
          type="text"
          className="flex-1 sm:flex-auto sm:!min-w-[350px]"
          defaultValue={defaultValue || ''}
        />

        <IconButton
          type="submit"
          label="Save"
          onClick={() => setIsEditMode(true)}
        >
          <CheckIcon className="w-5 h-5 text-gray-400"/>
        </IconButton>

        <IconButton
          type="button"
          label="Cancel"
          onClick={() => setIsEditMode(false)}
        >
          <Cross1Icon className="w-5 h-5 text-gray-400"/>
        </IconButton>
      </form>
    );
  }

  return (
    <div className="flex space-x-2">
      <div>{children}</div>
      {!disabled && (
        <IconButton label="Edit" onClick={() => setIsEditMode(true)}>
          <PencilIcon className="w-5 h-5 text-gray-400"/>
        </IconButton>
      )}
    </div>
  );
};

import { FunnelIcon } from '@heroicons/react/24/outline';
import { Pencil2Icon } from '@radix-ui/react-icons';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';

import { Button } from '@/Button';
import { Icon, IconedField, QualityCostsIcon, QualityIcon } from '@/Icon';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { NumericIndicator } from '@/NumericIndicator';
import { MultiFilterBar } from '@/Pickers/MultiFilterBar';
import { PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from '@/Popover';
import { MutedText, PageTitle } from '@/Text';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { formatPicker } from '~/utils/formatPicker';

import { DashboardLayout } from '../../DashboardLayout';
import { ReportProduceFilter } from '../ReportProduceFilter';
import { ReportSellersFilter } from '../ReportSellersFilter';
import { EditReportModal } from '../ReportsPage/EditReportModal';
import { QualityCostsReportPageProps } from './QualityCostsReportPage';

export const QualityCostsReportLayout = ({ children }: { children: ReactNode }) => {
  const { user, report, options, parameters } = usePageProps<QualityCostsReportPageProps>();
  const { reports } = useRoutes();

  /**
   * States
   */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  /**
   * Derived states
   */
  const sellers = options?.sellers ?? [];
  const filteredSellers = (parameters.sellers || []).length > 0 ? parameters.sellers : [...sellers.map(seller => seller.id)];

  const sellersFiltersCount = parameters.sellers?.length ?? 0;
  const producesFiltersCount = parameters.produces?.length ?? 0;
  const activeFiltersCount = sellersFiltersCount + producesFiltersCount;

  const ownsReport = user.id === report.user_id;

  return (
    <DashboardLayout>
      <LayoutHeader isSticky>
        <div className="flex items-center justify-between md:flex-row">
          {/* Left side */}
          <div>
            <Breadcrumbs backLink={{ type: 'link', name: 'Reports', href: reports() }}/>
            <div>
              <div>
                <PageTitle>
                  {report.name}
                </PageTitle>
              </div>

              <div className="flex gap-2 mt-2">
                <div>
                  <IconedField icon={QualityCostsIcon}>
                    <MutedText>
                      {t('reports.type_QUALITY_COSTS')}
                    </MutedText>
                  </IconedField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutHeader>
      <LayoutBody>
        {children}
      </LayoutBody>
    </DashboardLayout>
  );
};

// noinspection SpellCheckingInspection

import {
  format as dateFormat,
  formatDistance,
  isValid,
  parse as libParse,
  parseISO,
} from 'date-fns';
import {
  format as tzFormat,
  utcToZonedTime,
} from 'date-fns-tz';
import { formatDateRange } from 'little-date';

import { logError } from '~/utils/logger';

import { useError } from './useError';

export enum DateFormat {
  /** Example: 02-12-2021 */
  DayFormat = 'dd-MM-yyyy',

  /** Example: Monday, June 16th */
  HumanGlobalWeekDayFormat = 'EEEE, MMMM do',

  /** Example: Aug 16 July 2023, 20:00 */
  HumanDateTime = 'MMM do yyyy, HH:mm',

  /** Example: Aug 16 July, 20:00 */
  HumanDateTimeNoYear = 'MMM do, HH:mm',

  /** Example: 2021-06-01 */
  DateOnly = 'yyyy-MM-dd',

  /** Example: 2021-06-01T12:00:00.000Z */
  ISO = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX',

  /** Example: Monday 16 of July, 20:00 */
  HumanMonthDateTime = 'EEEE do \'of\' LLLL, \'at\' HH:mm',

  /** Example: Aug */
  Month = 'LLL',

  /** Example: 16 Aug */
  HumanMonthDay = 'do MMM',

  /** Example: Q1 2021 */
  QuarterYear = '\'Q\'q yyyy',

  /** Example: 13:37 */
  Time = 'HH:mm',
}

const formatUTC = (date: Date, format: DateFormat = DateFormat.DayFormat) => {
  try {
    return formatInTimeZone(date, format, 'UTC');
  } catch (e) {
    return '';
  }
};

export const isString = (date: any): date is string => typeof date === 'string';
export const isDate = (date: any): date is Date => date instanceof Date;

const parse = (
  datestring: string | null,
  format: DateFormat = DateFormat.ISO
) => {
  if (!datestring) {
    return new Date(Date.now());
  }

  if (format === DateFormat.ISO) {
    return parseISO(datestring);
  }

  try {
    return libParse(datestring, format, new Date());
  } catch (error) {
    logError(
      error,
      `Error parsing date string: ${datestring} with format: ${format}`
    );
    return new Date(Date.now());
  }
};

const formatDate = (date: Date | string, format: DateFormat = DateFormat.DayFormat) => {
  if (typeof date === 'string') {
    return dateFormat(parse(date, DateFormat.ISO), format);
  }

  try {
    return dateFormat(date, format);
  } catch (e) {
    return '';
  }
};

const formatInTimeZone = (date: Date, format: DateFormat, tz: string) =>
  tzFormat(utcToZonedTime(date, tz), format, { timeZone: tz });

export const useDate = () => {
  const { log } = useError();

  return {
    getFormat: (granularity: string) => {
      switch (granularity) {
      case 'month':
        return DateFormat.Month;
      case 'year':
        return DateFormat.HumanMonthDay;
      case 'quarter':
        return DateFormat.QuarterYear;
      default:
        return DateFormat.HumanMonthDay;
      }
    },
    isValid: (date: Date) => isValid(date),
    parseOrFormat: (
      date: Date | string | null,
      format: DateFormat = DateFormat.DayFormat
    ) => {
      if (!date) {
        return '';
      }

      if (typeof date === 'string') {
        return formatDate(parse(date, DateFormat.ISO), format);
      }

      try {
        return formatDate(date, format);
      } catch (e) {
        return '';
      }
    },
    parse: (dateString: string | null | undefined, format: DateFormat = DateFormat.ISO, nullIfFalse = false) => {
      if (!dateString) {
        return nullIfFalse ? null : new Date(Date.now());
      }

      if (format === DateFormat.ISO) {
        return parseISO(dateString);
      }

      try {
        return parse(dateString, format);
      } catch (error) {
        log(
          error,
          `Error parsing date string: ${dateString} with format: ${format}`
        );
        return nullIfFalse ? null : new Date(Date.now());
      }
    },
    parseRangeString: (date: string, format: DateFormat) => {
      const [start, end] = date.split(' - ');
      return [parse(start, format), parse(end, format)];
    },
    formatDateRange: (fromDate: Date, toDate: Date) => {
      return formatDateRange(fromDate, toDate);
    },
    format: formatDate,
    formatUTC,
    differenceToNow: (date: Date) =>
      formatDistance(date, new Date(), { addSuffix: true }),
  };
};

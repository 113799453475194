import React from 'react';

import { Button } from '@/Button';
import { AcceptIssueIcon, ButtonIcon, EscalateIcon, Icon, MarkAsReadIcon } from '@/Icon';
import { ResolveIssueModal } from '~/Components/Issues/ResolveIssueModal';
import { Issue } from '~/types/types';

export function IssueActions(props: {
  loading: boolean,
  onClick: () => Promise<void>,
  issue: Issue,
  onChange: (data: any) => Promise<void>
}) {
  return <div className="flex items-center gap-2 flex-wrap">
    <Button
      disabled={props.issue.status === 'read' || props.loading as any}
      variant="white" size="xs"
      onClick={props.onClick}
      className="whitespace-nowrap"
    >
      <ButtonIcon icon={MarkAsReadIcon} className="stroke-emerald-500"/>
      Mark as read
    </Button>
    <ResolveIssueModal
      issue={props.issue}
      onChange={props.onChange}
      initialIssueState="accepted"
    >
      <Button
        disabled={props.issue.status === 'accepted' || props.loading as any}
        variant="white" size="xs"
      >
        <ButtonIcon icon={AcceptIssueIcon} className="stroke-orange-500"/>
        Accept
      </Button>
    </ResolveIssueModal>

    <ResolveIssueModal
      issue={props.issue}
      onChange={props.onChange}
      initialIssueState="escalated"
    >
      <Button
        disabled={props.issue.status === 'escalated' || props.loading as any}
        variant="white" size="xs"
      >
        <ButtonIcon icon={EscalateIcon} className="stroke-red-500"/>
        Escalate
      </Button>
    </ResolveIssueModal>
  </div>;
}

import { Link, router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Button } from '@/Button';
import { Card, CardContent, CardFooter, CardHeaderContainer, PromptCard } from '@/Card';
import { Container } from '@/Container';
import { PlaceholderBox } from '@/Fallback';
import { ProduceIcon } from '@/Icon';
import { LayoutHeader } from '@/Layout';
import { SearchBar } from '@/Searchbar';
import { SelectOptions } from '@/SelectOptions';
import { Spinner } from '@/Spinner';
import { TableFooter } from '@/Table';
import { SortButton } from '@/Table/SortButton';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import {
  CardSubtitle,
  CardTitle,
  Helper,
  MutedText,
  PageDescription,
  PageTitle,
  Text,
} from '@/Text';
import { RelevantTransportLabel } from '~/Components/Datastreams/RelevantTransportLabel';
import { CountryFlag } from '~/Components/Locations/CountryFlag';
import { CountryName } from '~/Components/Locations/CountryName';
import { OrderImage, OrderTitle } from '~/Components/Orders/OrderPreviewCard';
import { OrderStatusLabel } from '~/Components/Orders/OrderStatusLabel';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { useQueryParamsFromStorage } from '~/hooks/useQueryParamsFromStorage';
import { SellerRoutes, useRoutes } from '~/hooks/useRoutes';
import { Order, Organization, PageProps } from '~/types/types';
import { cn } from '~/utils/cn';

import { OrdersPageProps } from '../../Dashboard/Orders/OrdersPage';
import { QualityLabel } from '../../Dashboard/QualityLabel';
import { SellerLayout } from '../Components/SellerLayout';

interface PaginatedOrders {
  current_page: number;
  data: Order[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface SellerOrdersPageProps extends PageProps {
  orders: PaginatedOrders;
  parameters: {
    search?: string;
    organizationId?: string;
    produceId?: string;
    supplier_ids?: string[];
  };
  filterOptions: {
    suppliers: Organization[];
  };
}

const schema = z
  .object({
    search: z.string().optional(),
    stepType: z.string().optional(),
    produceId: z.string().optional(),
    supplier_ids: z.array(z.string()).optional(),
    orderStatus: z.string().optional(),
    region: z.string().optional(),
  })
  .strict();

type OrdersQueryParams = z.infer<typeof schema>;

const SellerOrdersPage = ({ organization, orders }: SellerOrdersPageProps) => {
  const { t } = useTranslation();
  const { isLoading, storageParams } =
    useQueryParamsFromStorage<OrdersQueryParams>('orders');

  return (
    <>
      <LayoutHeader>
        <div className="flex items-center justify-between">
          <div>
            <PageTitle>{t('orders')}</PageTitle>
            <PageDescription>{t('view_orders')}</PageDescription>
          </div>
        </div>
      </LayoutHeader>
      <Container className="w-full py-4">
        {isLoading ? (
          <div className="flex h-[400px] items-center justify-center">
            <Spinner className="w-12 h-12 animate-spin"/>
          </div>
        ) : (
          <OrderOverview
            storageParams={storageParams}
            organizationId={organization.id}
            paginatedOrders={orders}
          />
        )}
      </Container>
    </>
  );
};

interface OrderOverviewProps {
  paginatedOrders: PaginatedOrders;
  organizationId: string;
  storageParams: OrdersQueryParams;
}

const OrderOverview = ({
  paginatedOrders,
  storageParams,
}: OrderOverviewProps) => {
  const { reload, loading } = useAction('', {
    withParams: true,
    preserveState: true,
    storeLocalStorage: false,
  });
  const routes = useRoutes<SellerRoutes>();
  const { meta, organization, parameters } = usePageProps<OrdersPageProps>();
  const columns = '2fr 2fr 2fr 1fr';

  const { t } = useTranslation();

  const { parseOrFormat } = useDate();

  const hasOrdersRequiringAttention = meta.requiresInputCount > 0;

  const onStatusFilterChange = (orderStatus?: string) => {
    reload(
      {
        orderStatus: orderStatus,
      }
    );
  };

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  return (
    <div>
      <div>
        {hasOrdersRequiringAttention && (
          <div>
            <PromptCard variant={'blue'} renderIcon={<ProduceIcon className={'fill-blue-500'}/>}>
              <CardTitle>{t('qa_input_prompt')}</CardTitle>
              <CardSubtitle>
                <div>
                  {t('qa_input_required_description', { count: meta.requiresInputCount })}
                </div>
                <div className="mt-4">
                  <Button
                    size="sm"
                    onClick={() => onStatusFilterChange('input_required')}
                    // @ts-ignore
                    disabled={parameters.orderStatus === 'input_required'}
                  >
                    {t('filter_orders')}
                  </Button>
                </div>
              </CardSubtitle>
            </PromptCard>
          </div>
        )}
      </div>
      <div className="mt-4">
        <SearchBar
          value={parameters.search}
          placeholder={t('seller_orders_page_search') as string}
          loading={loading}
          onChange={(search) => reload({ search })}
        />
      </div>
      <div className="flex justify-end mt-4 mb-4 space-x-2">
        <div
          className="flex flex-col w-full space-y-2 md:flex-row md:w-revert md:items-center md:space-y-revert md:space-x-2">
          {Object.keys(storageParams || {}).length > 0 && (
            <div className="h-full">
              <Button
                className="w-full h-full"
                onClick={() => {
                  localStorage.removeItem('orders');
                  reload({}, undefined, false);
                }}
              >
                {t('clear_filters')}
              </Button>
            </div>
          )}
        </div>

        <div>
          <SelectOptions
            titleKey="change_orders_status"
            value={parameters.singleStatusType as any ?? 'all'}
            onChange={onStatusFilterChange}
            options={[
              { value: 'all', title_key: 'all', description_key: 'all_description' },
              { value: 'has_open_issues', title_key: 'issues_only', description_key: 'issues_only_description' },
              { value: 'input_required', title_key: 'input_required', description_key: 'input_required_description' },
            ]}
          />
        </div>
      </div>
      <Card>
        {paginatedOrders.data.length === 0 && (
          <PlaceholderBox
            icon={<ProduceIcon className="w-12 h-12 fill-gray-300"/>}
            title={t('placeholders.no_orders')} description={t('placeholders.no_orders_buyers_description')}
            loading={loading}
          />
        )}

        {paginatedOrders.data.length > 0 && (
          <div className={cn(loading && 'opacity-60')}>
            <ReponsiveTableContainer>
              <CardHeaderContainer className="!py-2">
                <div
                  className="grid items-center gap-8"
                  style={{ gridTemplateColumns: columns }}
                >
                  <div>
                    <Helper>{t('order')}</Helper>
                  </div>
                  <div>
                    <Helper>{t('status')}</Helper>
                  </div>
                  <div>
                    <Helper>{t('buyer')}</Helper>
                  </div>

                  <div className="ml-auto">
                    <SortButton
                      sortKey="updated_at"
                      currentSortKey="updated_at"
                    >
                      {t('last_update')}
                    </SortButton>
                  </div>
                </div>
              </CardHeaderContainer>
              <CardContent>
                <div className="divide-y">
                  {paginatedOrders.data.map((order) => (
                    <div
                      key={order.id}
                      data-testid="order-row"
                      role="row"
                      className="grid items-center gap-8 px-8 py-4 -mx-8 transition duration-150 ease-in-out cursor-pointer hover:bg-gray-50 hover:shadow-sm"
                      onClick={() => router.get(routes.order(order.id))}
                      style={{ gridTemplateColumns: columns }}
                    >
                      <div className="flex">
                        <div className="flex align-top">
                          <div className="block">
                            <OrderImage order={order} size="md"/>
                          </div>
                          <div>
                            <OrderTitle order={order}/>
                            <MutedText className="!text-xxs">
                              <Link
                                href={`/s/${organization.id}/orders/${order.id}`}
                              >
                                {order.public_ref}
                              </Link>
                            </MutedText>
                          </div>
                        </div>
                      </div>
                      <div>
                        {order.statuses?.map((status: any) => (
                          <div key={status} className="space-x-2">
                            <OrderStatusLabel status={status}/>
                          </div>
                        ))}
                        {order.issues && order.issues.length > 0 && (
                          <div>
                            <QualityLabel issues={order.issues} label={'issues'}/>
                          </div>
                        )}
                        {order.activeDataStreams?.map((stream) => (
                          <div key={stream.id}>
                            {stream.type === 'LOCATION' && (
                              <RelevantTransportLabel dataStream={stream}/>
                            )}
                          </div>
                        ))}
                      </div>
                      <div>
                        <MutedText>{order.buyer?.title}</MutedText>
                        <div>
                          {order.buyer?.country && (
                            <span className="flex items-center">
                              <span className="mr-2">
                                <CountryFlag
                                  countryCode={order.buyer.country}
                                />
                              </span>

                              <MutedText>
                                <CountryName
                                  countryCode={order.buyer.country.toUpperCase()}
                                />
                              </MutedText>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <div>
                          {order.latestEvent && (
                            <Text>
                              {t(`events.${order.latestEvent?.type}`)}
                            </Text>
                          )}
                        </div>
                        <div>
                          <Helper className="!font-medium !text-gray-500">
                            {parseOrFormat(order.updated_at, DateFormat.HumanDateTimeNoYear)}
                          </Helper>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </ReponsiveTableContainer>
            <CardFooter>
              <TableFooter paginatedItems={paginatedOrders}/>
            </CardFooter>
          </div>
        )}
      </Card>
    </div>
  );
};

SellerOrdersPage.layout = (page: React.ReactNode) => (
  <SellerLayout>{page}</SellerLayout>
);

export default SellerOrdersPage;

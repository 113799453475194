import { makeRandomID } from '~/utils/random';

import { SpecField, SpecFieldConstraint, SpecFieldRange } from './schemas';

export const makeDefaultRanges = (field: SpecField): Array<SpecFieldRange> => {
  const randomId = makeRandomID();

  if (field.type === 'measurement') {
    return [
      {
        id: randomId,
        min: 0,
        max: 1,
      },
    ];
  }

  return [
    {
      id: `${randomId}_1`,
      min: 0.00,
      max: 1.00,
      score: 'green',
    },
    {
      id: `${randomId}_2`,
      min: 1.1,
      max: 10.00,
      score: 'amber',
    },
    {
      id: `${randomId}_3`,
      min: 10.11,
      max: 100,
      score: 'red',
    },
  ];
};

export const makeDefaultConstraint = (field: SpecField): SpecFieldConstraint => {
  const type = field.type === 'measurement' ? 'range' : 'rag';

  return {
    type,
    ranges: makeDefaultRanges(field),
    produce_varieties: [],
  };
};

export const makeDefaultField = (): SpecField => {
  return {
    id: undefined,
    name: 'Weight',
    type: 'measurement',
    defect_type: 'minor',
    unit: 'kg',
    required: false,
    hidden: false,
    base_type: 'weight',
    constraints: [],
  };
};

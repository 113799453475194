/* eslint-disable */
import * as React from 'react';
import {useMemo, useState} from 'react';

import {Button} from '@/Button';
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator} from '@/Command';
import {AddIcon, CheckIcon, CloseIcon, Icon} from '@/Icon';
import {Label, MiniLabel} from '@/Label';
import {PopoverContent, PopoverRoot, PopoverTrigger} from '@/Popover';
import {Separator} from '@/Separator';
import {Spinner} from '@/Spinner';
import {cn} from '~/utils/cn';

import {PickerChevron, PickerIcon} from './PickerIcon';
import {MutedText, Strong} from "@/Text";
import {searchPickerItems} from "~/utils/formatPicker";

/**
 * Represents the base structure for an option in the picker.
 */
export type OptionBaseType = {
  label: string;
  value: string;
  subLabel?: string;
  group?: string;
  count?: number;
  disabled?: boolean;
  metaLabel?: string;
  metaLabelBrand?: string;
};

/**
 * Represents a group in the picker.
 */
export type GroupType = {
  label: string;
  icon?: React.ComponentType;
};

export type Groups = Record<string, GroupType>;

/**
 * Shared props for both single and multi-select pickers.
 */
interface SharedPickerProps<T extends OptionBaseType> {
  title: string;
  options: T[];
  groups?: Record<string, GroupType>;
  emptyMessage: string;
  placeholder: string;
  autoFocus?: boolean;
  loading?: boolean;
  showClear?: boolean;
  disabled?: boolean;
  icon?: React.ComponentType;
  clearMessage?: string;
  onCreate?: (value: string) => void;
}

/**
 * Props specific to single-select picker.
 */
interface SinglePickerProps<T extends OptionBaseType> extends SharedPickerProps<T> {
  value?: string | null;
  selected?: string | null;
  onChange?: (value: string | null) => void;
  isMulti: false;
}

/**
 * Props specific to multi-select picker.
 */
interface MultiPickerProps<T extends OptionBaseType> extends SharedPickerProps<T> {
  value?: string[];
  selected?: string[];
  onChange?: (value: string[] | null) => void;
  isMulti: true;
}

/**
 * Union type for all possible picker props.
 */
type GenericPickerProps<T extends OptionBaseType> = SinglePickerProps<T> | MultiPickerProps<T>;

/**
 * Renders the body of a single-select picker.
 */
const SinglePickerBody: React.FC<{
  selectedOption: OptionBaseType | null;
  icon?: React.ComponentType;
  title: string;
}> = ({selectedOption, icon: Icon, title}) => (
  <>
    {selectedOption ? (
      <>{selectedOption.label}</>
    ) : (
      <span className="inline-flex items-center text-token-strong">
        {Icon && <PickerIcon as={Icon}/>}
        {title}
      </span>
    )}
  </>
);

/**
 * Renders the body of a multi-select picker.
 */
const MultiPickerBody: React.FC<{
  selectedOptions: OptionBaseType[];
  icon?: React.ComponentType;
  title: string;
}> = ({selectedOptions, icon: Icon, title}) => (
  <>
    <span className="inline-flex items-center text-token-strong">
      {Icon && <PickerIcon as={Icon}/>}
      {title}
    </span>
    {selectedOptions.length > 0 && (
      <>
        <Separator orientation="vertical" className="mx-2"/>
        <Label className="px-1 font-normal rounded-sm lg:hidden">
          {selectedOptions.length}
        </Label>
        <div className="hidden space-x-1 lg:flex">
          {selectedOptions.length > 2 ? (
            <Label className="px-1 font-normal rounded-sm">
              {selectedOptions.length} selected
            </Label>
          ) : (
            selectedOptions.map((option) => (
              <Label
                key={option.value}
                className="px-1 font-normal rounded-sm"
              >
                {option.label}
              </Label>
            ))
          )}
        </div>
      </>
    )}
  </>
);
/**
 * A generic picker component that supports both single and multi-select functionality.
 */
export const GenericPicker = <T extends OptionBaseType>(
  {
    value,
    title,
    options = [],
    emptyMessage,
    placeholder,
    groups = {},
    autoFocus,
    loading,
    showClear = true,
    disabled,
    icon,
    onCreate,
    clearMessage,
    ...props
  }: GenericPickerProps<T>
) => {
  const [open, setOpen] = useState(false);

  const relevantValue = value || props.selected;
  const [inputValue, setInputValue] = useState('');

  /**
   * Memoized array of selected options.
   */
  const selectedOptions = useMemo(() => {
    if (props.isMulti) {
      const selectedValues = Array.isArray(value) ? value : relevantValue || [];
      return options.filter((option) => selectedValues.includes(option.value));
    }
    return [];
  }, [props.isMulti, options, value, relevantValue]);

  const handleCreate = () => {
    if (onCreate && inputValue) {
      onCreate(inputValue);
      setInputValue('');
      setOpen(false);
    }
  };

  /**
   * The currently selected option for single-select mode.
   */
  const selectedOption = props.isMulti
    ? null
    : options.find((option) => option.value === (value || relevantValue));

  /**
   * Clears the current selection.
   */
  const clearPicker = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    props.onChange?.(props.isMulti ? null : null);
  };

  /**
   * Handles the selection of an option.
   */
  const onSelect = (optionId: string) => {
    const option = options.find(opt => opt.value === optionId);
    if (option?.disabled) return;

    if (props.isMulti) {
      const currentSelection = selectedOptions.map(option => option.value);
      const newSelection = currentSelection.includes(optionId)
        ? currentSelection.filter(id => id !== optionId)
        : [...currentSelection, optionId];
      props.onChange?.(newSelection);
    } else {
      props.onChange?.(optionId);
      setOpen(false);
    }
  };

  /**
   * Renders the command items for the picker.
   */
  const renderCommandItems = (optionsToRender: T[]) => (
    <>
      {optionsToRender.map((option) => (
        <CommandItem
          key={option.value}
          value={option.value}
          onSelect={() => onSelect(option.value)}
          disabled={option.disabled}
          className={cn(option.disabled && "opacity-50 cursor-not-allowed", 'cursor-pointer')}
        >
          {props.isMulti ? (
            <MultiSelectItem option={option} selectedOptions={selectedOptions}/>
          ) : (
            <SingleSelectItem option={option} selectedOption={selectedOption}/>
          )}
        </CommandItem>
      ))}
      {onCreate && inputValue && !optionsToRender.some(option => option.value === inputValue) && (
        <CommandItem onSelect={handleCreate}>
          <div className="flex items-center">
            <AddIcon className="mr-2 h-4 w-4"/>
            Create "{inputValue}"
          </div>
        </CommandItem>
      )}
    </>
  );

  return (
    <PopoverRoot open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          role="combobox"
          variant="white"
          autoFocus={autoFocus}
          aria-expanded={open}
          aria-label={title}
          disabled={loading || disabled as any}
          className="flex items-center justify-between w-full h-full"
        >
          {props.isMulti ? (
            <MultiPickerBody selectedOptions={selectedOptions} title={title} icon={icon}/>
          ) : (
            <SinglePickerBody selectedOption={selectedOption as OptionBaseType} icon={icon} title={title}/>
          )}

          <div className='flex items-center'>
            {loading ? (
              <div className="ml-2">
                <Spinner/>
              </div>
            ) : (
              <PickerChevron/>
            )}
            {((props.isMulti && selectedOptions.length > 0) || selectedOption) && showClear && (
              <Icon aria-label='clear-button' className='ml-2' onClick={clearPicker}>
                <CloseIcon className="w-4 h-4 text-gray-300"/>
              </Icon>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command
          filter={(value, searchVal) => searchPickerItems(value, options, searchVal, ['value', 'subLabel', 'label', 'group'])}
        >
          <CommandInput className="h-7 my-2" placeholder={placeholder} value={inputValue}
                        onValueChange={setInputValue}/>
          <CommandEmpty>
            {onCreate ? (
              <Button onClick={handleCreate} variant="primary">
                <AddIcon className="mr-2 h-4 w-4"/>
                Create "{inputValue}"
              </Button>
            ) : (
              <>{emptyMessage}</>
            )}
          </CommandEmpty>
          <CommandList>
            {Object.keys(groups).length > 0 ? (
              Object.keys(groups).map((groupKey) => (
                <CommandGroup key={groupKey} heading={groups[groupKey].label}>
                  {renderCommandItems(options.filter(option => option.group === groupKey))}
                </CommandGroup>
              ))
            ) : (
              <CommandGroup>
                {renderCommandItems(options)}
              </CommandGroup>
            )}
            {props.isMulti && selectedOptions.length > 0 && (
              <>
                <CommandSeparator/>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => props.onChange?.([])
                    }
                    className="justify-center text-center"
                  >
                    {clearMessage || 'Clear selection'}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
};

/**
 * Renders a multi-select item in the picker.
 */
const MultiSelectItem: React.FC<{ option: OptionBaseType, selectedOptions: OptionBaseType[] }> = ({
                                                                                                    option,
                                                                                                    selectedOptions
                                                                                                  }) => (
  <>
    <div
      className={cn(
        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
        selectedOptions.some((selectedOption) => selectedOption.value === option.value)
          ? 'bg-primary text-primary-foreground'
          : 'opacity-50 [&_svg]:invisible'
      )}
    >
      <CheckIcon className={cn('h-4 w-4')}/>
    </div>
    {option.label}
    {option.count !== undefined && (
      <span className="ml-2 text-xs text-gray-500">({option.count})</span>
    )}
  </>
);

/**
 * Renders a single-select item in the picker.
 */
const SingleSelectItem: React.FC<{
  option: OptionBaseType,
  selectedOption: OptionBaseType | null | undefined
}> = ({option, selectedOption}) => (
  <div className="flex justify-between gap-2 w-full">
    <div className="flex items-center">
      <CheckIcon
        className={cn(
          'mr-2 h-4 w-4',
          selectedOption?.value === option.value ? 'opacity-100' : 'opacity-0'
        )}
      />

      {option.subLabel ? (
        <div>
          <div>
            <Strong>
              {option.label}
            </Strong>
          </div>
          <div>
            <MutedText>
              {option.subLabel}
            </MutedText>
          </div>
        </div>
      ) : (
        <div>
          {option.label}
        </div>
      )}

      {option.count !== undefined && (
        <span className="ml-2 text-xs text-gray-500">({option.count})</span>
      )}
    </div>

    <div>
      {option.metaLabel && (
        <MiniLabel>
          {option.metaLabel}
        </MiniLabel>
      )}
    </div>
  </div>
);

/**
 * Renders a check icon for selected items.
 */
export const PickerCheck: React.FC<{ isSelected: boolean }> = ({isSelected}) => (
  <>
    {isSelected ? (
      <span>
        <CheckIcon className={cn('h-4 w-4 stroke-gray-500')}/>
      </span>
    ) : (
      <span className="w-4 h-4"/>
    )}
  </>
);

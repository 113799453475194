import * as z from 'zod';

export const varietiesFormSchema = z.object({
  varieties: z.array(z.object({
    id: z.string().nullish(),
    public_name: z.string().min(1, 'Variety name is required'),
  })),
});
export type SpecVarietiesFormInputs = z.infer<typeof varietiesFormSchema>;

export const fieldFormSchema = z.object({
  fields: z.array(
    z.object({
      id: z.string().nullish(),
      name: z.string().min(1, 'Field name is required'),
      type: z.enum(['boolean', 'percentage', 'measurement']),
      defect_type: z.enum(['unknown', 'minor', 'major', 'nil']).nullish(),
      unit: z.string().nullish(),
      required: z.boolean().default(false).nullish(),
      hidden: z.boolean().default(false).nullish(),
      customLabel: z.string().nullish(),
      metaLabel: z.string().nullish(),
      base_type: z.string().nullish(),
      constraints: z.array(
        z.object({
          id: z.string().nullish(),
          type: z.enum(['range', 'rag']),
          ranges: z.array(
            z.object({
              id: z.string().nullish(),
              min: z.any().nullish(),
              max: z.any().nullish(),
              score: z.enum(['red', 'amber', 'green']).nullish(),
            }).refine(data => data.min !== null || data.max !== null, {
              message: 'At least one of min or max must be set',
            }).refine(data => data.min === null || data.max === null || data.min <= data.max, {
              message: 'Min must be less than or equal to max',
            })
          ),
          produce_varieties: z.array(
            z.string().nullish()
          ).default([]).nullish(),
        })
      ).optional().default([]),
    })
  ),
});
export type SpecFieldsFormValues = z.infer<typeof fieldFormSchema>;
export type SpecField = SpecFieldsFormValues['fields'][0];
export type SpecFieldConstraint = SpecField['constraints'][0];
export type SpecFieldRange = SpecFieldConstraint['ranges'][0];

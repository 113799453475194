/* eslint-disable react/prop-types */
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { WarningIcon } from '@/Icon';
import { cn } from '~/utils/cn';

const alertVariants = cva(
  'relative w-full rounded-lg border px-6 py-4 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        destructive:
          'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
        warning: 'bg-yellow-50 border-none text-yellow-800 dark:text-yellow-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const alertIconVariants = cva(
  'w-5 h-5 text-current *:w-5 *:h-5',
  {
    variants: {
      variant: {
        default: 'text-slate-400',
        destructive: 'text-destructive',
        warning: 'text-yellow-500 dark:text-yellow-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, children, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <div className={alertIconVariants({ variant })}>
          {variant === 'warning' && (
            <WarningIcon/>
          )}
        </div>
      </div>
      <div className="ml-3">
        {children}
      </div>
    </div>
  </div>
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('mb-2 font-medium tracking-tight', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed font-light', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertDescription, AlertTitle };

export const WarningBadge = () => (
  <div className="text-yellow-600">
    <WarningIcon/>
  </div>
);

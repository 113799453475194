import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import * as DropdownMenu from '@/DropdownMenu';
import { Icon } from '@/Icon';
import { Pane, PaneContent, PaneHeader, PaneTitle, PaneTrigger } from '@/Pane';
import { useAction } from '~/hooks/useAction';
import { useRoutes } from '~/hooks/useRoutes';
import {
  NewIssuesUpdateNotification,
  NewOrdersFoundNotification,
  Notification,
} from '~/types/types';

import { NewIssuesUpdateNotificationEntry } from './notifications/NewIssuesUpdateNotificationEntry';
import { NewOrdersFoundNotificationEntry } from './notifications/NewOrdersFoundNotificationEntry';

interface NotificationsPaneProps {
  Trigger: ReactNode;
  notifications: Notification[];
}

export const NotificationsPane = ({
  Trigger,
  notifications,
}: NotificationsPaneProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const {
    markAsRead: markAsReadRoute,
    markAsUnread: markAsUnreadRoute,
    deleteNotifications: deleteNotificationsRoute,
  } = useRoutes();

  const { post: markAsRead, loading: markAsReadLoading } = useAction(
    markAsReadRoute()
  );
  const { post: markAsUnread, loading: markAsUnreadLoading } = useAction(
    markAsUnreadRoute()
  );

  const { post: deleteNotifications } = useAction(deleteNotificationsRoute());

  const handleAllMarkedAsRead = async () => {
    void markAsRead({
      notificationIds: notifications.map((notification) => notification.id),
    });
  };

  const handleAllMarkedAsUnread = async () => {
    void markAsUnread({
      notificationIds: notifications.map((notification) => notification.id),
    });
  };

  const handleDeleteAll = async () => {
    void deleteNotifications({});
  };

  const handleDeleteAllUnread = async () => {
    void deleteNotifications({
      onlyRead: true,
    });
  };

  const isLoading = markAsReadLoading || markAsUnreadLoading;

  return (
    <Pane open={isOpen} onOpenChange={setIsOpen}>
      <PaneTrigger asChild>{Trigger}</PaneTrigger>
      <PaneContent className="flex flex-col gap-0">
        <PaneHeader className="flex flex-row items-center space-y-0">
          <PaneTitle className="px-3 py-4">{t('notifications')}</PaneTitle>
          <DropdownMenu.DropdownRoot>
            <DropdownMenu.DropdownTrigger>
              <Button
                variant="white"
                className="flex justify-center items-center !ring-0 !shadow-none rounded-sm !p-1"
              >
                <Icon className="w-4 h-4 text-token-text">
                  <EllipsisHorizontalIcon/>
                </Icon>
              </Button>
            </DropdownMenu.DropdownTrigger>
            <DropdownMenu.DropdownContent>
              <DropdownMenu.DropdownItem
                disabled={isLoading}
                onSelect={handleAllMarkedAsRead}
              >
                {t('mark_all_as_read')}
              </DropdownMenu.DropdownItem>
              <DropdownMenu.DropdownItem
                disabled={isLoading}
                onSelect={handleAllMarkedAsUnread}
              >
                {t('mark_all_as_unread')}
              </DropdownMenu.DropdownItem>
              <DropdownMenu.DropdownItem
                disabled={isLoading}
                onSelect={handleDeleteAll}
              >
                {t('delete_all_notifications')}
              </DropdownMenu.DropdownItem>
              <DropdownMenu.DropdownItem
                disabled={isLoading}
                onSelect={handleDeleteAllUnread}
              >
                {t('delete_all_unread_notifications')}
              </DropdownMenu.DropdownItem>
            </DropdownMenu.DropdownContent>
          </DropdownMenu.DropdownRoot>

          <hr/>
        </PaneHeader>
        <div className="flex flex-col flex-1 pt-2 space-y-2 overflow-y-auto">
          {notifications.length > 0 ? (
            <div className="flex flex-col gap-2">
              {notifications.map((notification) => {
                return (
                  <Fragment key={notification.id}>
                    {notification.type === 'NewIssuesUpdate' && (
                      <NewIssuesUpdateNotificationEntry
                        notification={
                          notification as NewIssuesUpdateNotification
                        }
                        onMarkAsRead={(notificationId) =>
                          markAsRead({ notificationIds: [notificationId] })
                        }
                        onMarkAsUnread={(notificationId) =>
                          markAsUnread({ notificationIds: [notificationId] })
                        }
                        isLoading={isLoading}
                        onClose={() => setIsOpen(false)}
                      />
                    )}
                    {notification.type === 'NewOrdersFound' && (
                      <NewOrdersFoundNotificationEntry
                        notification={
                          notification as NewOrdersFoundNotification
                        }
                        onMarkAsRead={(notificationId) =>
                          markAsRead({ notificationIds: [notificationId] })
                        }
                        onMarkAsUnread={(notificationId) =>
                          markAsUnread({ notificationIds: [notificationId] })
                        }
                        isLoading={isLoading}
                        onClose={() => setIsOpen(false)}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className="grid h-full text-sm place-items-center text-token-muted">
              {t('empty_states.no_notifications')}
            </div>
          )}
        </div>
      </PaneContent>
    </Pane>
  );
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DocumentIcon } from '@heroicons/react/24/outline';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePageProps } from '~/hooks/usePageProps';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalIcon,
  ModalTitle,
  ModalTrigger,
} from '../../../../Shared/UI/Modal';
import { CreateReportForm } from './CreateReportForm/CreateReportForm';
import { SellerCreateReportForm } from './CreateReportForm/SellerCreateReportForm';

interface CreateReportModalProps {
  children: ReactNode;
  filterOptions: any;
}

/**
 * Creates a saved report.
 *
 * Preconditions:
 * - This is used in the SavedReports page.
 */
const CreateReportModal = ({ children, filterOptions }: CreateReportModalProps) => {
  const {
    app: { appMode },
  } = usePageProps();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent className="max-w-[900px]">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<DocumentIcon className="w-7 h-7"/>}/>
            <div>
              <ModalTitle>{t('create_report_title')}</ModalTitle>
              <ModalDescription>
                {t('create_report_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {appMode === 'seller' && (
            <SellerCreateReportForm onSubmit={() => setIsModalOpen(false)}/>
          )}
          {appMode === 'buyer' && (
            <CreateReportForm
              regions={filterOptions?.regions ?? []}
              sellers={filterOptions.suppliers ?? []}
              onSubmit={() => setIsModalOpen(false)}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateReportModal;

import { ReactNode } from 'react';

import { ApprovedIcon, LabelIcon } from '@/Icon';
import { Label } from '@/Label';
import { Breadcrumbs } from '@/Layout';
import { MutedText, PageTitle } from '@/Text';
import { FormatProduceName } from '~/Components/Produce/FormatProduce';
import { ProduceImage } from '~/Components/Produce/ProduceImage';
import { useAction } from '~/hooks/useAction';
import { BuyerRoutes, useRoutes } from '~/hooks/useRoutes';
import { SpecPageProps } from '~/types/page-props';
import { SaveSpecInput } from '~/types/types';

import { SettingsHeader } from '../../SettingsHeader';
import SettingsLayout from '../../SettingsLayout';
import { SpecFieldsCard } from './SpecFieldsCard';
import { SpecVarietiesCard } from './SpecVarietiesCard';

const SpecPage = ({ spec, varieties, fields, knownFields }: SpecPageProps) => {
  const routes = useRoutes<BuyerRoutes>();

  const { post: updateFields } = useAction<SaveSpecInput>(routes.saveSpec(), {
    onError: (error) => {
      console.error('Error updating fields:', error);
    },
    onSuccess: (data) => {
      console.log('Fields updated:', data);
    },
    preserveState: false,
  });

  const handleSaveSpec = async (input: SaveSpecInput) => {
    await updateFields(input);
  };

  return (
    <div>
      <div className="space-y-6">
        <SettingsHeader>
          <Breadcrumbs backLink={{ type: 'back' }}/>
          <div className="flex items-center gap-4">
            <div>
              <ProduceImage produce={spec.produce} className="w-12 h-12"/>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <PageTitle className="!text-sm">
                  {spec.name}
                </PageTitle>

                <div>
                  <Label size="xs" theme="emerald">
                    <LabelIcon icon={ApprovedIcon} className="stroke-emerald-500"/>
                    Approved
                  </Label>
                </div>
              </div>
              <div>
                <MutedText>
                  <FormatProduceName produce={spec.produce}/>
                </MutedText>
              </div>
            </div>
          </div>
        </SettingsHeader>
      </div>

      {/* Body of the file */}
      <div className="mt-4 space-y-4">
        <SpecVarietiesCard
          spec={spec}
          varieties={varieties}
        />
        <SpecFieldsCard onSave={handleSaveSpec} supportedFields={knownFields} spec={spec} initialFields={fields}/>
      </div>
    </div>
  );
};

SpecPage.layout = (page: ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default SpecPage;

import { useTranslation } from 'react-i18next';

interface FormatProduceNameProps {
  produce: {
    name?: string;
    meta_type?: string;
  };
}

export const FormatProduceName = ({ produce }: FormatProduceNameProps) => {
  const { t } = useTranslation();
  if (produce.meta_type) {
    return t(`produce_types.${produce.meta_type}`);
  }

  return produce.name;
};

interface FormatProduceVarietyProps {
  produceVariety: {
    public_name: string;
  };
}

export const FormatProduceVariety = ({ produceVariety }: FormatProduceVarietyProps) => {
  return produceVariety?.public_name;
};

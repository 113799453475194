import { router } from '@inertiajs/react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PromptCard } from '@/Card';
import { Container } from '@/Container';
import { ProduceIcon } from '@/Icon';
import { CardSubtitle, CardTitle } from '@/Text';
import { ViewSpecModal } from '~/Components/Spec/ViewSpecModal';
import { PageProps, Spec, VerifySpecFileOutput } from '~/types/types';

import { HarvestSection } from '../../Dashboard/Order/Harvest/HarvestSection';
import InspectUnitModal from '../../Dashboard/Order/Inspections/InspectUnitModal';
import { OrderInspectionsSection } from '../../Dashboard/Order/Inspections/OrderInspectionsSection';
import { OrderLogisticsSection } from '../../Dashboard/Order/Logistics/OrderLogisticsSection';
import { OrderDetailsSection } from '../../Dashboard/Order/OrderDetails/OrderDetailsSection';
import { OrderHeader } from '../../Dashboard/Order/OrderHeader';
import { OrderProvider } from '../../Dashboard/Order/OrderProvider';
import { OrderPayload } from '../../Dashboard/Order/types';
import { SellerLayout } from '../Components/SellerLayout';

export interface SellerOrderPageProps extends PageProps {
  data: OrderPayload;
  isAdmin: boolean;
  active: {
    spec: Spec;
  };
  flash: {
    spec: {
      verifyTempSpecResults: VerifySpecFileOutput;
    };
  };
}

const SellerOrderPage = ({ data, active }: SellerOrderPageProps) => {
  const order = data.order;
  const activeUnit = data.active_unit;
  const comments = data.groupedComments;

  const handleCloseActiveUnit = () => {
    router.reload({ data: { activeUnitId: null } });
  };

  const handleCloseActiveSpec = () => {
    router.reload({ data: { activeSpecId: null } });
  };

  const { t } = useTranslation();

  return (
    <OrderProvider orderData={data}>
      {active.spec && (
        <ViewSpecModal
          spec={active.spec}
          isOpen={!!active.spec}
          onClose={handleCloseActiveSpec}
        />
      )}
      {activeUnit && (
        <InspectUnitModal
          unit={activeUnit}
          isOpen={!!activeUnit}
          onClose={handleCloseActiveUnit}
        />
      )}
      <div>
        <OrderHeader
          integration={data.integration}
          dataStreams={data.dataStreams}
          order={order}
          groupedComments={comments}
        />
        <div className="mt-4 pb-4">
          <Container className="space-y-8">
            {data.requiresRecentInput && (
              <div>
                <PromptCard variant={'blue'} renderIcon={<ProduceIcon className={'fill-blue-500'}/>}>
                  <CardTitle>{t('qa_input_prompt_single')}</CardTitle>
                  <CardSubtitle>
                    <div>
                      {t('qa_input_prompt_single_description')}
                    </div>
                  </CardSubtitle>
                </PromptCard>
              </div>
            )}
            <div className="grid gap-12">
              <div className="divide-y divide-gray-200">
                <div className="pb-8 mt-4">
                  <OrderDetailsSection
                    activeStep={data.step}
                    integration={data.integration}
                    order={order}
                    isSeller
                  />
                </div>

                <div className="py-8">
                  <OrderInspectionsSection
                    attachments={data.attachments}
                    orderFieldSummaries={data.orderFieldSummaries}
                    activeSpec={data.active_spec}
                    activeOrderFieldSummary={data.activeOrderFieldSummary}
                    isSeller
                  />
                </div>

                <OrderLogisticsSection dataStreams={data.dataStreams}/>

                <HarvestSection dataStreams={data.dataStreams}/>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </OrderProvider>
  );
};

SellerOrderPage.layout = (page: ReactNode) => (
  <SellerLayout>{page}</SellerLayout>
);

export default SellerOrderPage;
